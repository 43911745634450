<template>
  <div class="page notice">
    <div class="page-inner">
      <div class="page-title">
        <h1 class="main-title primary--text">{{ title }}</h1>
        <p class="sub-title">최신 {{ title }}을 확인해 보세요.</p>
      </div>

      <Search
        propsPlaceholder="자료를 검색해 보세요."
        @changeText="changeSearchVale"
      />
      <SelectCategory
        :bbsModel="bbsModel"
        :targetCategory="targetCategory"
        :targetSubCategory="targetSubCategory"
        @emitChangeCategory="changeCategory"
        @emitChangeSubCategory="changeSubCategory"
      />

      <ArchiveList ref="refList" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Search from "@/components/thermage/common/Search.vue";
import ArchiveList from "./comp/ArchiveList.vue";
import SelectCategory from "./comp/SelectCategory.vue";
const formatService = require("@/utils/format.js");
const modelService = require("@/utils/dataModel.js");

export default {
  components: {
    Search,
    ArchiveList,
    SelectCategory,
  },
  data() {
    return {
      title: "자료실",
      List: [],
      bbsModel: modelService.bbsModel(),
      targetCategory: null,
      targetSubCategory: null,
      itemsCategory: formatService.itemsCategory(),
      itemsSubCategory: formatService.itemsSubCategory(),
      itemsMarketingCategory: formatService.itemsMarketingCategory(),
      itemsMarketingSubCategory: formatService.itemsMarketingSubCategory(),
    };
  },
  watch: {
    $route(to, from) {
      this.checkPath();
    },
  },
  mounted() {
    if (!this.isAuthorized) {
      // 이동 할 페이지 저장
      this.loginCheck(this.$route.fullPath);
      this.$router.push("/login").catch(() => {});
      return;
    }
    this.bbsModel.category = "";
    this.bbsModel.subCategory = "";
    this.checkPath();
  },
  methods: {
    checkPath() {
      this.bbsModel.category = "";
      this.bbsModel.subCategory = "";
      if (this.$route.path === "/archive") {
        this.bbsModel.bbsGbn = "archive";
        this.bbsModel.titleGbn = "의학";
        this.title = "의학 자료실";
        this.targetCategory = this.itemsCategory;
        this.targetSubCategory = this.itemsSubCategory;
      }
      if (this.$route.path === "/archive2") {
        this.bbsModel.bbsGbn = "archive";
        this.bbsModel.titleGbn = "마케팅";
        this.title = "마케팅 자료실";
        this.targetCategory = this.itemsMarketingCategory;
        this.targetSubCategory = this.itemsMarketingSubCategory;
      }
      this.getData();
    },
    changeSearchVale(e) {
      console.log("검색", e);
      this.bbsModel.searchVal = e;
      this.getData();
    },
    changeCategory(e) {
      // console.log("changeCategory", e);
      this.bbsModel.category = e;
      this.bbsModel.subCategory = "";
      this.getData();
    },
    changeSubCategory(e) {
      // console.log("changeSubCategory", e);
      this.bbsModel.subCategory = e;
      this.getData();
    },
    getData() {
      this.List = [];
      this.bbsModel.endpoint = "bbs/" + this.bbsModel.bbsGbn;
      this.getDataQuery(this.bbsModel).then((resP) => {
        if (resP === undefined) return;
        let res = resP.data;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: res.length - index,
            regDateLabel: this.$moment(item.regDate).format("YYYY-MM-DD"),
            updDateLabel: this.$moment(item.updDate).format(
              "YYYY-MM-DD a hh:mm"
            ),
            dpType: this.checkContentType(item.file1, item.vodLink),
          }));
        }
        this.$refs.refList._updateData(res);
      });
    },
    checkContentType(file, vodLink) {
      // 동영상 먼저 확인
      if (vodLink !== null && vodLink !== "") return "VOD";
      if (file === null || file === "") return "IMG";
      // 파일 확장자 확인
      const fileExt = this.$helper.checkFileExt(file);
      let returnVal = "IMG";
      if (fileExt === ".pdf") returnVal = "PDF";
      if (fileExt === ".mp4") returnVal = "MP4";
      if (fileExt === ".ai") returnVal = "AI";
      if (fileExt === ".psd") returnVal = "PSD";
      return returnVal;
    },
    ...mapActions(["loginCheck", "getDataQuery"]),
  },
  computed: {
    ...mapGetters(["isAuthorized"]),
    ...mapState(["me"]),
  },
};
</script>

<style lang="scss">
@media (max-width: 768px) {
  div.page.notice {
    .list-table {
      .v-data-table {
        &.fixed-notice {
          border-bottom: 1px solid $point-color;
          .v-data-table__wrapper > table {
            > tbody > tr > td {
              &:nth-child(1) {
                display: flex;
                width: auto;
                padding-top: 15px;
              }
              &:last-child {
                padding-top: 0;
                padding-bottom: 20px;
                font-weight: bold;
              }
              .badge {
                width: 40px;
                padding: 1px;
                font-size: 12px;
              }
            }
          }
        }
        .v-data-table__wrapper > table {
          > tbody > tr {
            > td {
              padding: 5px 15px;
              min-height: 30px;
              &:nth-child(1) {
                display: none;
              }
              &:nth-child(2) {
                width: auto;
                padding-top: 20px;
              }
              &:nth-child(3) {
                width: auto;
                color: $secondary-color;
                font-size: 14px;
                padding-bottom: 20px;
              }
              &.v-data-table__mobile-row {
                // display: table-cell;
                .v-data-table__mobile-row__cell {
                  text-align: left;
                }
              }
              .v-data-table__mobile-row__header {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
.page.notice {
  background: linear-gradient(#ffffff, #ebe5e1);
  padding-bottom: 100px;
  .list-table {
    .v-data-table {
      background-color: transparent;
      border-radius: 0;
      &.fixed-notice {
        border-bottom: 1px solid $point-color;
      }
      .v-data-table__wrapper > table {
        > tbody {
          // > tr:not(:last-child) {
          //   > td {
          //     border-bottom: thin solid rgba(0, 0, 0, 0.12);
          //   }
          // }
          > tr > td {
            padding: 25px 20px;
            font-size: 1rem;
            &:nth-child(1) {
              width: 100px;
            }
            &:nth-child(3) {
              width: 150px;
            }
            .badge {
              display: inline-block;
              border: 1px solid $point-color;
              padding: 1px 5px;
              text-align: center;
              color: $point-color;
            }
          }
        }

        > thead {
          display: none;
          > tr > th {
            padding: 25px 16px;
            font-size: 1rem;
          }
        }
      }
    }
  }
}
</style>
<template>
  <div class="policy">
    <h4>개인정보 처리방침</h4>
    <br />
    솔타메디칼코리아 유한회사 및 그의 모든 자회사와 기타 계열사(총칭하여 "당사")는 귀하의 개인정보를 존중하고 보호하고자 합니다. 본 개인정보 처리방침("본 방침")은 귀하가 본 웹사이트("본 웹사이트")를
    방문하거나 다른 당사의 웹사이트, 모바일 또는 데스크톱 애플리케이션 및/또는 기타 온라인 서비스(본 웹사이트와 총칭하여 "본 서비스")에 접속하거나 이를 이용할 때 당사가 귀하에 관하여 수집하는 정보의 유형,
    귀하의 정보가 이용 및 공개되는 방법, 귀하의 정보의 이용 및 공개를 통제할 수 있는 방법 및 귀하의 정보가 보호되는 방법에 대해 설명합니다.
    <br />
    본 사이트 또는 본 서비스를 이용하거나 당사에 정보를 제공하기 전에 본 방침을 모두 읽어보시기 바랍니다. 귀하가 본 방침의 일부에 동의하지 않거나 이를 수락하지 않는 경우, 본 사이트 또는 본 서비스를 이용하거나
    당사에 정보를 제공하지 마십시오.
    <br />
    당사에 데이터를 제공하는 개인의 프라이버시권과 당사, 그 계열사 및 당사를 위하여 업무를 수행하는 수탁자의 개인정보 보호에 관한 원칙을 포함하는 EU-미국 데이터 프라이버시 체계(EU-U.S. DPF), 영국
    확장 EU-미국 데이터 프라이버시 체계(EU-U.S. Data Privacy Framework)(EU-U.S. DPF) 및 스위스-미국 데이터 프라이버시 체계(Swiss-U.S. Data Privacy
    Framework)(Swiss-U. DPF)의 준수와 관련한 당사의 약속을 정리한 당사의 개인정보 보호에 관한 글로벌 정책은 <a
      href="https://www.bauschhealth.com/siteassets/pdf/global-policy-protection-of-personal-info-v7.pdf">여기</a>를 통해 확인하실
    수 있습니다.
    <br />
    본 방침은 당사의 Legal Notice("Legal Notice")의 일부를 구성하는바, 위 문서의 내용은 <a href="https://www.bauschhealth.com/terms/">여기</a>를
    클릭하시어 확인하시기 바랍니다. 본 방침 및 Legal notice는 본 사이트 및 본 서비스의 모든 이용자를 법적으로 구속합니다.
    <br />
    문의사항이 있으신 경우, 본 방침의 마지막 "연락처" 섹션에 명시된 방법 중 하나를 이용하여 당사에 연락 주시기 바랍니다.
    <br /><br />
    <h4>당사가 수집하는 정보</h4>
    당사는 귀하의 본 사이트 또는 서비스 이용과 관련하여 귀사의 개인정보를 수집할 수 있습니다. 해당 정보는 당사의 합법적인 사업 목적을 위해 수집되며, 아래 설명드리는 바와 같이 이용될 수 있습니다.
    <br /><br />
    (1) 당사의 적극적 행위에 따른 수집<br />
    당사는 다음과 같은 개인정보를 귀하로부터 적극적으로 수집할 수 있습니다:<br />
    (a) 성명; (b) 이메일 주소, 우편주소, 우편번호, 전화번호 등 연락처 정보; (c) 아이디 및 비밀번호; (d) 성별, 생년월일 등 인구통계학적 정보; (e) 귀하가 13세 이상이고 미국 거주자인지에 관한
    정보; (f) 귀하가 본 사이트 또는 본 서비스를 통해 제공하거나 이와 관련된 이메일 커뮤니케이션, 의견, 제안 또는 기타 정보 및/또는 당사 또는 당사의 제품 또는 서비스와 관련된 정보; (g) 상태, 약물,
    처방 데이터 및/또는 예상 치료법을 포함한 건강 관련 정보; (h) 귀하가 당사 제품과 관련하여 발생할 수 있는 부작용을 보고할 때 제공하는 개인정보; (i) 설문조사에 대한 응답; (j) 귀하의 검색내역;
    (k) 신용카드 또는 은행계좌 정보, 청구/지급 조건, 신용한도 및 신용 상태; (l) 직책 및 직업 관계; (m) 취미 및 관심 분야; (n) 커뮤니케이션 선호도; (o) 제품 선호도; (p) 귀하가 의료
    서비스 제공자인 경우, 귀하의 국가 면허번호 및 전공분야와 같은 정보; (q) 설문지 및 입사지원 정보에 대한 답변; (r) 귀하가 당사의 우편목록 또는 뉴스레터를 구독할 때 제공하는 정보, 또는 (s) 수집
    시점에 귀하에게 설명된 기타 유형의 정보. 입사지원자로부터 수집한 개인정보는
    <a href="https://www.bauschhealth.com/siteassets/pdf/Protecting-Applicant-Privacy.pdf" target="_blank">여기</a>에서 확인되는
    당사의 "입사지원자 개인정보 처리방침"에 의해 보호됩니다.
    <br /><br />
    (2) 자동화된 방식에 따른 정보의 수집<br />
    당사는 귀하가 본 사이트 및 본 서비스를 방문 및 이용할 때 자동으로 특정 정보를 수집할 수 있습니다. 당사는 당사의 합법적인 사업 목적과 귀하의 보다 원활한 사이트 및 서비스 이용을 돕기 위하여 이러한 정보를
    수집합니다. 이러한 정보는 다음을 포함합니다: (a) 귀하의 본 사이트 방문 일시 및 기간; (b) 귀하의 브라우저 유형 및 운영체제; (c) 귀하의 인터넷 프로토콜(IP) 주소 (귀하가 인터넷에 접속할 때마다
    귀하의 컴퓨터에 자동으로 부여되고 때때로 귀하의 일반적인 지리적 영역을 도출하는 데 사용될 수 있는 정보를 의미합니다); (d) 고유 기기 식별자, 하드웨어 모델, 운영체제 및 버전, 모바일 네트워크 정보 등
    기기 정보를 포함하는 기타 고유 식별자 정보; (e) 본 사이트 방문 전후에 방문한 사이트; (f) 귀하가 본 사이트 내에서 클릭하는 페이지 및 링크; (g) 주문 제품 및 그 가격; (h) 클릭한 링크 및 해당
    메시지가 열리거나 전달되었는지 여부 등 이메일 메시지와의 상호작용 및 (i) 페이스북 및 트위터와 같은 플랫폼이 본 사이트와 상호작용하고 귀하의 행동에 대한 피드백을 제공할 수 있도록 해주는 소셜 미디어 추적
    픽셀. 당사는 이러한 유형의 정보를 자동으로 수집하기 위하여 쿠키, 픽셀 태그 및 이와 유사한 기술을 사용할 수 있습니다. 쿠키는 귀하의 컴퓨터 웹 브라우저가 저장하는 작은 정보입니다. 총계 쿠키 및 추적 정보는
    제3자와 공유될 수 있습니다. 귀하는 인터넷 브라우저 설정에서 귀하의 선호도 또는 옵션을 설정하여 귀하의 컴퓨터가 쿠키의 수집을 허용할 것인지 여부 및 방식을 결정할 수 있습니다. 다만, 쿠키를 비활성화하기로
    하는 경우, 본 사이트 또는 본 서비스와 관련된 특정 서비스 또는 기능을 이용하지 못할 수 있습니다. 픽셀 태그는 "웹 비콘(web Beacons)" 또는 "클리어 GIFs(clear GIFs)"라고도 불리는
    매우 작은 이미지 또는 이미지에 내장된 작은 데이터 조각으로 쿠키를 인식할 수 있으며, 페이지가 조회되는 시간과 일자, 픽셀 태그가 배치된 페이지에 대한 설명 및 귀하의 컴퓨터 또는 기기에서 제공하는 유사한
    정보도 포함하여 인식할 수 있습니다.
    <br />
    귀하는 [hyerim.kang@solta.com ]로 이메일을 보내 이러한 선호를 변경할 수 있습니다. 당사는 제3자가 본 사이트 및 본 서비스를 통해 정보를 수집하기 위해 자체 쿠키, 웹 비콘 및 이와 유사한
    기술을 배치하고 읽을 수 있도록 허용할 수 있습니다. 당사는 제3자가 해당 정보를 사용하는 방식을 통제하지 않습니다. 이러한 제3자에는 이러한 기술을 사용하여 트래픽 측정, 연구 및 분석을 지원하는 정보를
    수집하는 서비스 제공업체가 포함됩니다. 예를 들어, 본 사이트는 Google, Inc. ("구글")이 제공하는 웹 분석 서비스인 구글 애널리틱스를 사용하여 이용자가 본 사이트를 이용하는 방법을 분석합니다. 구글
    애널리틱스는 귀하의 사이트 방문으로부터 총계 정보를 수집하기 위하여 쿠키 및 기타 추적 기술을 이용합니다. 구글 애널리틱스의 개인정보 처리와 관한 보다 자세한 사항은
    <a href="https://policies.google.com/privacy?hl=en" target="_blank">구글의 개인정보 처리방침</a>을 참고하시기 바랍니다. 구글 애널리틱스에 데이터가 전송되는
    것을 방지하기 위해
    <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">구글 애널리틱스 옵트아웃 브라우저 애드온</a>을 사용하시기 바랍니다. 당사는 귀하로부터
    수집한 모든 총계 정보를 총계의 형태로 저장합니다. 당사는 이러한 총계 정보로부터 특정 사용자나 개인을 식별할 수는 없습니다. 당사는 이용자의 본 사이트의 재방문 횟수를 집계하고, 본 사이트에 관한 통계 정보를
    축적 및 보고하며, 이용자가 선호하는 특징을 결정하는 것을 포함하되 이에 한정되지 않는 다양한 방식으로 총계 정보를 이용합니다. 귀하의 온라인 활동의 추적을 방지하고자 하는 경우, 귀하의 브라우저의 "추적 금지"
    설정을 변경함으로써 귀하의 브라우저가 귀하가 방문하는 웹사이트에 귀하가 특정 정보를 수집하는 것을 원치 않음을 알리도록 하는 방식을 포함하여 다양한 방식으로 이를 방지할 수 있습니다. 다만, 현재 본 사이트는
    위와 같은 "추적 금지" 지시를 인식하지 않고 있음을 양지하시기 바랍니다. 당사는 향후 "추적 금지" 지시를 인지할 수 있는 것으로 본 사이트를 변경하는 경우, 본 방침을 업데이트하도록 하겠습니다. 당사 사이트의
    쿠키 사용과 관련하여 질문이 있거나 쿠키 사용에 대한 귀하의 동의를 변경하거나 철회하고자 하는 경우, 본 방침의 마지막 "연락처" 섹션에 명시된 방법 중 하나를 이용하여 당사에 연락하시기 바랍니다.
    <br /><br />
    (3) 기타 출처로부터 수집되는 정보<br />
    관련 법률상 허용되는 범위 내에서, 당사는 제3의 서비스 제공자 및/또는 파트너로부터 인구통계학적 정보 또는 사기 적발 정보 등 귀하에 관한 추가 정보를 수집하거나 수령할 수 있습니다. 예를 들어, 당사는 당사의
    사기 방지 및 위험 평가 노력을 위하여 서비스 제공자로부터 신원 조회 결과(필요한 경우 귀하의 동의를 받아 수령합니다) 또는 사기 경고를 받을 수 있습니다. 당사는 또한 다른 웹사이트를 모니터링할 때 귀하에 대한
    공개 개인정보를 수집할 수 있습니다. 예를 들어, 당사 또는 제약산업과 관련하여 공개 플랫폼 상 이루어지는 대화를 모니터링할 수 있습니다.
    <br />
    당사는 다양한 출처로부터 귀하에 대한 정보를 결합할 수 있습니다. 귀하가 제3자 서비스(Google, Facebook 또는 LinkedIn 등)를 링크, 연결하거나 이를 통해 로그인하는 경우, 위 제3자는 귀하의
    등록정보 및 프로필 정보 등의 정보를 당사에 제공할 수 있습니다. 이러한 정보는 제3자 서비스마다 달라질 수 있으며, 위 제3자에 의해 통제되거나, 해당 서비스에서 귀하의 개인정보보호 설정을 통해 귀하가 승인한
    바에 따라 통제됩니다. 당사는 이러한 제3자 서비스에 의한 귀하의 개인정보 처리와 관련하여 통제, 감독 또는 대응하지 않습니다.
    <br /><br />
    <h4>당사의 개인정보 이용 및 공유</h4>
    귀하의 정보는 당사의 합법적인 사업 목적(귀하와의 관계를 발효, 모니터링, 평가 및 개선하기 위한 목적 포함)을 위하여 수집됩니다. 당사는 무엇보다도 당사 사이트 및 서비스를 제공, 이해, 개선 및 개발하고,
    귀하와 소통하고, 귀하의 요청을 이행하기 위하여 귀하에 관하여 수집하는 정보를 이용, 저장 및 처리합니다. 또한 귀하와의 관계를 개선하고 당사의 법적 의무를 준수하기 위한 목적으로도 처리합니다. 이에 따라 당사는
    본 사이트 또는 서비스와 관련하여 수집한 귀하의 개인정보를 다음과 같이 이용 및/또는 공개할 수 있습니다.
    <br />
    (1) <b>귀하의 동의</b>. 귀하가 동의를 한 경우, 당사는 동의를 징구함에 있어 귀하에게 고지한 바에 따라 귀하의 개인정보를 이용 및 공개할 수 있습니다.
    <br />
    (2) <b>당사의 사업 목적</b>. 당사는 다음과 같은 당사의 사업목적으로 귀하의 개인정보를 이용할 수 있습니다: <b>(a) 당사의 계열회사를 통하여 귀하가 요청하는 제품, 서비스 및 정보의 제공; (b)
    이메일, 직접우편 또는 기타 방법을 통해 당사의 제품, 서비스 또는 이벤트를 포함하여 당사에 관한 업데이트, 홍보 또는 뉴스를 전달; (c) 시장조사; (d) 당사의 사업, 본건 서비스 및 기타 당사가 제공하는
    제품 및 서비스의 품질을 개선하고 방문자들이 본건 서비스와 상호작용하는 방식을 보다 잘 이해하기 위하여 본건 서비스를 유지 또는 관리하고 사업 및/또는 보건의료 분석을 수행하거나 기타 내부 목적을 위하여; (f)
    정부 보고서 작성; (g) 채용절차 및 관련 문의 처리; (h) 귀하의 본 사이트 또는 본 서비스 이용 맞춤화 및 개인화, (i) 당사 제품 및 서비스 마케팅; (j) 당사 제품 및 서비스 모니터링 및 검토;
    (k) 당사의 현재 제품 개선 또는 신제품 개발 지원; (l) 본 사이트 및 당사 제품에 대한 사용자들에 관한 인구통계학적 및 지리적 정보 수집; (m) 행정적 또는 입법적 관련 정보 전달; (n) 귀하의 요청에
    대한 응답; (o) 귀하가 당사 제품과 관련하여 발생할 수 있는 부작용을 발생시킬 수 있는 보고에 대응하고, 당사 제품의 안전을 모니터링하며 부작용 사례에 대한 보고를 진행 및 (p) 기타 수집 시점에 귀하에게
    설명된 기타 목적.</b> 위 각 목적에는 텍스트 메시지 발신자가 사전에 수집에 동의한 정보의 처리를 포함되지 않으며, 이러한 정보는 어떠한 경우에도 제3자와 공유되지 않습니다.
    <br />
    (3) <b>수탁자</b>. 당사는 본 사이트, 본 서비스 및 당사의 사업과 관련된 서비스를 제공하기 위하여 다양한 제3자 서비스 제공업체를 이용합니다. 이러한 서비스 제공업체에는 (a) 당사의 전문 자문인,
    감사인 및 사업 파트너, (b) 당사를 위하여 본 사이트를 호스팅하거나, 데이터베이스를 관리하거나, 분석 또는 데이터 분석을 수행하거나, 결제를 처리하거나, 기술 또는 고객 지원을 제공하거나, 커뮤니케이션을
    전송하는 당사의 공급업체, 및 (c) 당사와 홍보, 마케팅, 광고 또는 기타 상업적 관계를 맺고 있는 회사(이행 및/또는 배달 서비스를 수행하는 금융기관 및 회사 포함)가 포함되나 이에 한정되지 않습니다. 이러한
    서비스 제공업체는 미국 내외에 소재할 수 있으며, 각 경우 당사는 귀하의 개인정보가 무단 사용 또는 공개되지 않도록 합리적인 예방조치를 취합니다. 예를 들어, 당사는 해당 서비스 제공자가 귀하의 정보를 기밀로
    유지하고 해당 정보와 관련하여 적절한 보안 조치를 취할 것을 약속하는 서면 계약을 체결할 수 있습니다. 또한, 이러한 서비스 제공자들 에 대한 개인정보의 국외위탁은 귀하와의 계약 체결 및 이행을 위하여 필요한
    경우 또는 귀하의 동의를 얻은 경우에 한하여 이루어지며, 이 귀하의 개인정보에 접근하는 것은 당사를 대신하여 업무를 수행하는데 필요하거나 바람직한 것으로 제한됩니다. 당사의 구체적인 개인정보 위탁현황은 다음과
    같으며, 귀하가 거부하고자 하는 경우 [1544-8330]에 연락을 부탁드립니다.
    <!-- 개인정보 위탁현황 표 삽입 -->
    <br /><br />
    <!-- 개인정보 국외제공현황 표 삽입 -->
    <b>개인정보 위탁현황</b>
    <table>
      <tr>
        <th style="width: 20%">
          <u>수탁자(연락처)</u>
        </th>
        <td>
          <u>지피랩 주식회사(ZP Lab Co., Ltd)</u>
        </td>
      </tr>
      <tr>
        <th>수탁업무</th>
        <td>
          [웹사이트의 구축 및 등록, 고객 DB관리, 사이트 유지보수, 웹디자인 개발, 시스템 운영 및 이벤트 프로그램 개발 및 관리, 웹사이트 이용 및 문의사항에 대한 기록 및 통계 산출]
        </td>
      </tr>
    </table>
    <!-- 개인정보 위탁현황 표 삽입 -->

    <!-- 개인정보 국외제공현황 표 삽입 -->
    <!-- <b>개인정보 국외제공현황</b>
    <table>
            <tr>
              <th style = "width: 20%">
                <u>수탁자(연락처)</u>
              </th>
              <td>
                <u>[010-6326-2851]</u>
              </td>
            </tr>
            <tr>
              <th>소재국가, 이전시기 및 방법</th>
              <td>[한국,4월]
</td>
            </tr>
            <tr>
              <th>이전항목</th>
              <td>[ ]</td>
            </tr>
            <tr>
              <th>
                <u> <b>수탁업무</b></u>
              </th>
              <td>[개발, 운영]</td>
            </tr>
            <tr>
              <th>
                <u> <b>보유이용기간</b></u>
              </th>
              <td>[*]</td>
            </tr>
          </table> -->

    <br />
    (4) 기업의 거래구조 변경에 따른 이전. 당사는 당사가 수집한 정보(개인정보 포함)를 다음과 같은 경우에 제3자에게 제공할 수 있습니다: (a) 해당 정보와 관련된 당사 사업의 전부 또는 일부의 매각,
    양도 또는 기타 소유권 또는 지배권의 이전, (b) 내부 구조조정, 자회사 매매 또는 제3자 합작투자 등 당사와 관련된 기타 사업거래; 및 (c) 당사와 관련된 파산 또는 이와 유사한 사건.
    <br />
    (5) <b>법률 또는 규제기관의 조치 또는 요청 준수</b>. 당사는 개인정보를 포함한 귀하의 정보를 다음과 같은 경우 법원, 법집행기관, 정부당국 또는 승인된 제3자에게 공개할 수 있습니다: (a) 관련
    법률, 규정 또는 지침의 준수를 위한 경우; (b) 법적 절차를 준수하거나 당사를 상대로 제기된 청구에 대응하기 위한 경우; (c) 범죄 조사 또는 혐의가 있거나 의심되는 불법 활동 또는 당사, 귀하 또는 여타
    이용자를 법적 책임에 노출시킬 수 있는 여타 활동과 관련된 요청에 응하기 위한 경우; (d) 당사, 그 직원 또는 대중의 권리, 재산 및/또는 개인 안전을 보호하기 위한 경우; 또는 (e) 규제기관, 정부기관
    또는 기타 단체의 조사 또는 정보 또는 협조 요청에 응하거나 이에 응하기 위한 경우. 적절한 경우,
    당사는 법적 절차 자체, 법원 명령 또는 관련 법률에 의해 이러한 통지를 제공하는 것이 금지되거나, 그러한 통지 제공이 무용하거나, 실효성이 없거나, 개인 또는 집단에 부상 또는 기타 피해를 줄 위험이 있거나,
    당사에 책임이 발생하거나 증가할 위험이 있다고 판단되지 않는 한, 귀하에게 법적 요청에 대해 통지할 수 있습니다.
    <br /><br />
    <!-- 개인정보 제공현황 표 삽입 -->
    <b>개인정보 제공현황</b>
    <table>
      <tr>
        <th style="width: 20%">
          <u>제공받는 자</u>
        </th>
        <td>
          <u><b>[지피랩 주식회사 (ZP Lab Co., Ltd)]</b></u>
        </td>
      </tr>
      <tr>
        <th>제공받는 자의 이용목적</th>
        <td>
          [서비스 제공 및 서비스 품질 향상을 위한 회원관리 <br />
          - 회사 제품의 안내를 포함한 정보 전달 및 취득 활동의 수행 <br />
          - 안내 및 알림 서비스 제공, 이벤트 진행 등의 마케팅 활동 수행 <br />
          - 문의사항 및 웹사이트 이용에 대한 통계 산출]

        </td>
      </tr>
      <tr>
        <th>제공받는 자의 보유 및 이용기간</th>
        <td>
          [회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다.]
        </td>
      </tr>
      <tr>
        <th>
          <u> <b>제공되는 항목</b></u>
        </th>
        <td>[홈페이지 서비스 사용 시 고객이 직접 입력하는 정보들에 한함]</td>
      </tr>
    </table>

    <!-- 개인정보 국외제공현황 표 삽입 -->
    <!-- <b>개인정보 국외제공현황</b>
    <table>
            <tr>
              <th style = "width: 20%">
                <u>제공받는 자(연락처)</u>
              </th>
              <td>
                <u>[hyerim.kang@solta.com]</u>
              </td>
            </tr>
            <tr>
              <th>소재국가, 이전시기 및 방법</th>
              <td>TBD
</td>
            </tr>
            <tr>
              <th>제공항목</th>
              <td>TBD</td>
            </tr>
            <tr>
              <th>
                <u> <b>제공받는 자의 이용목적</b></u>
              </th>
              <td>TBD</td>
            </tr>
            <tr>
              <th>
                <u> <b>제공받는 자의 보유이용기간</b></u>
              </th>
              <td>TBD</td>
            </tr>
          </table> -->

    <br />
    통계 정보. 당사는 규제 준수, 산업 및 시장 분석, 인구통계학적 프로파일링, 마케팅 및 광고 및 기타 사업 목적을 위하여 통계형태로 집계된 정보(개인 사용자를 더 이상 식별하거나 언급하지 않도록 처리된 당사
    이용자에 관한 정보)와 기타 익명화된 정보를 공유할 수 있습니다.
    <br />
    (6) Tell-A-Friend 기능. 귀하가 당사의 제품 또는 서비스에 관한 정보를 친구에게 전송하기 위하여 Tell-A-Friend기능을 이용하는 경우, 당사는 귀하의 성명과 이메일 주소를 귀하의 친구에게
    공개할 수 있습니다.
    <br /><br />
    당사는 귀하가 개인정보를 당사에 제공하는 당시 별도로 명확하게 고지를 하지 않은 이상, 제3자에게 귀하의 개인정보를 판매, 대여 또는 임대하지 않습니다.

    <br /><br />
    <h4>이용자의 권리</h4>
    본 방침의 기타 규정에 명시된 권리 이외에도, 귀하는 아래 명시된 권리의 일부 또는 전부를 보유할 수 있습니다. 귀하는 아래 각 권리를 [1544-8330]에 연락을 하시는 방법으로 행사할 수 있습니다.
    <br />
    (1) <b>콘텐츠의 관리</b>. 귀하는 본 방침 말미의 "연락처" 섹션에 명시된 방법 중 하나를 이용하여 당사에 연락을 취함으로써 당사의 콘텐츠를 구독하거나 구독을 취소할 수 있습니다.
    <br />
    (2) <b>마케팅 동의의 철회</b>. 귀하는 당사가 발송하는 각 이메일에 명시된 수신 거부 절차를 따르거나 본 방침 말미의 "연락처" 섹션에 명시된 방법 중 하나를 이용하여 당사에 연락함으로써 당사로부터
    마케팅 및 홍보 이메일을 수신 거부할 수 있습니다. 단, 귀하가 마케팅 및 홍보 이메일 수신을 거부하더라도, 당사는 귀하가 사이트에 제출한 콘텐츠 또는 여하한 서비스와 관련된 후속 메시지를 포함하여 행정적 또는
    정보 전달 목적으로 귀하에게 계속 이메일을 보낼 수 있습니다. 귀하는 그 외 또한 다음 링크를 클릭하여서도 동의를 철회할 수 있습니다: <a
      href="https://go.bauschhealth.com/Privacy-Page-BH-Unsubscribe.html">클릭</a>.
    <br />
    (3)<b>동의의 철회</b>. 귀하가 당사의 개인정보 처리에 동의한 경우, 귀하는 언제든지 (본 방침의 말미 "연락처" 섹션에 명시된 방법 중 하나를 이용하여) 귀하가 동의 철회를 명시한 서신을 당사에
    발송함으로써 귀하의 동의를 철회할 수 있습니다. 귀하의 동의 철회는 철회 이전에 동의에 기반한 처리 활동의 적법성에 영향을 미치지 않습니다.
    <br />
    (4) <b>열람청구; 사본요청; 전송요구; 정정요구</b>. 관련 법률에 따라 허용되는 경우, 귀하는 당사가 귀하에 대해 보유하고 있는 개인정보와 관련하여 다음 사항을 요청할 수 있습니다: (a) 당사가
    처리하고 있는 개인정보에 대한 열람청구; (b) 당사의 귀하에 대한 구조화되고 통상적이며 기계 판독이 가능한 형식의 개인정보 사본 제공 또는 (기술적으로 가능한 경우) 해당 정보의 다른 서비스 제공자에 대한
    전송요구 및 (c) 귀하에 대한 부정확하거나 불완전한 개인정보에 대한 정정요청.
    <br />
    (5) <b>개인정보의 처리 정지</b>. 관련 법률에 따라 허용되는 경우, 귀하는 당사에 대하여 개인정보 처리 정지를 요구할 수 있습니다.
    <br />
    (6) <b>처리에 대한 이의</b>. 관련 법률에 따라 허용되는 경우, 귀하는 당사가 정당한 이익에 기반하여 개인정보를 처리하는 경우 당사가 특정 목적(프로파일링 포함)으로 귀하의 개인정보를 처리하지 않도록
    당사에 요구할 수 있습니다. 귀하가 이러한 처리에 이의를 제기하는 경우, 당사가 그러한 처리에 대한 정당한 근거를 입증하거나 법적 청구권의 설정, 행사 또는 방어를 위해 그러한 처리가 요구되지 않는 한, 당사는
    더 이상 이러한 목적으로 귀하의 개인정보를 처리하지 않습니다. 귀하의 개인정보가 직접 마케팅 목적으로 처리되는 경우, 귀하는 언제든지 당사에 해당 직접 마케팅 목적으로 귀하의 정보 처리를 중단할 것을 요청할 수
    있습니다.
    <br />
    (7) <b>삭제</b>. 당사가 더 이상 귀하의 정보를 이용하지 않기를 원하시는 경우, 귀하는 당사에 귀하의 개인정보 삭제를 요청할 수 있습니다. 귀하가 개인정보 삭제를 요청하는 경우: (a) 당사는 사기 적발
    및 방지, 안전성 제고 등 당사의 정당한 사업적 이익을 위하여 필요한 경우 귀하의 개인정보 일부를 보유할 수 있으며; (b) 당사는 당사의 법적 의무를 준수하기 위하여 필요한 범위 내에서 귀하의 개인정보를 보유
    및 이용할 수 있고; (c) 귀하의 개인정보의 일부 사본(로그 기록 등)은 당사 데이터베이스에 남아 있을 수 있으나, 개인 식별자는 모두 제거되어 처리됩니다.
    <br />
    (8) <b>민원의 제기</b>. 귀하는 당사와 관련된 정보보호 당국에 당사가 수행한 정보처리 활동에 대해 불만을 제기할 권리가 있습니다.
    <br /><br />
    <h4>이용자의 의무</h4>
    본 방침의 기타 규정에 명시된 의무에 더하여, 귀하는 아래 명시된 의무를 준수하여야 합니다. 당사는 귀하가 이러한 요건을 준수하지 않음으로 인하여 발생할 수 있는 책임이나 기타 문제에 대하여 책임을 지지 않으며
    향후에도 그러할 것입니다.
    <br />
    (1) <b>완전하고 진실하며 정확한 정보</b>. 완전하고 최신의 진실하며 정확한 정보를 제공하는 것은 귀하의 책임입니다. 또한 귀하는 본 방침 말미에 설명된 바와 같이 당사에 연락하여 필요한 경우 언제든지
    귀하의 개인정보를 업데이트하거나 수정할 책임이 있습니다. 귀하가 타인에 관한 개인정보를 제출하는 경우, 귀하는 귀하가 이를 제출하고 당사가 본 방침에 따라 해당 정보를 이용하도록 허용할 권한이 있음을 보증합니다.
    <br />
    (2) <b>서비스 이용 및 접근</b>. 당사가 아닌 귀하 스스로가 본 사이트 및 서비스와 관련된 귀하의 활동, 행동, 사용 및 행위에 대하여 전적인 책임을 부담합니다. 귀하에 의한 정보 제출은 당사가 귀하가
    본 사이트 또는 본 서비스의 여하한 기능 또는 기능에 접근 및/또는 이를 사용하는 것을 허용함을 보장하지 않습니다.
    <br />
    (3) <b>비밀유지</b>; 보고. 귀하는 본 사이트 또는 본 서비스와 관련하여 귀하가 가질 수 있는 계정 사용자명 및/또는 비밀번호의 기밀을 유지하고, 귀하가 승인하였는지 여부를 불문하고 해당 계정 사용자명
    및/또는 비밀번호를 사용하여 본 서비스에 접속하거나 이를 이용할 책임이 있습니다. 귀하는 해당 계정 사용자명 및/또는 비밀번호의 무단 사용 또는 기타 보안 위반이 있는 경우 즉시 당사에 통지하여야 합니다.
    <br /><br />
    <h4>정보보안</h4>
    당사는 귀하가 당사에 제공한 정보를 그 정보의 손실, 오용, 무단 접근, 공개, 변경, 차단 또는 파기로부터 보호하기 위하여 합리적으로 필요하고 법상 요구되는 기술적, 물리적, 행정적 및 절차적 보안 예방조치를
    유지합니다. 단, 그러한 예방조치는 100% 유효하지 않다는 점은 언제나 유의하여 주시기 바라며, 따라서 당사에 정보를 전송함에 있어 귀하 스스로의 주의의무는 다하시기 바랍니다.
    <br />
    당사는 귀하에게 개인정보 제공 또는 확인을 요청하는 이메일을 발송하지 않으며 앞으로도 발송하지 않을 것입니다. 참고로, '피싱'으로 알려진 기법은 소비자로부터 개인 신원정보와 금융계좌 인증정보를 탈취하고자 하는
    기법으로, '피셔'는 '스푸핑한' 이메일을 이용해 수신자를 속여 신용카드번호, 계좌이용자명, 비밀번호, 사회보장번호 등 개인정보를 탈취하기 위한 사이트로 소비자들을 유도합니다. 귀하가 본 사이트가 발송한 것이라고
    주장되거나 본 서비스와 관련된 이메일 커뮤니케이션이라고 주장되는 이메일을 수령하는 경우, 즉시 [hyerim.kang@solta.com]로 전송고지 후 귀하의 컴퓨터에서 삭제하시기 바랍니다.
    <br />
    당사는 서비스, 기능 또는 기능에 접근하거나 이를 시도하는 자의 신원에 대한 의문이 발생하는 경우, 당사 사이트의 서비스, 기능 또는 기능의 사용을 포함하되 이에 한정되지 않는 이용자의 접근 및/또는 이용 특권을
    거부할 권리가 있습니다
    <br /><br />
    <h4>개인정보의 보유 및 파기</h4>
    당사는 일반적으로 당사의 정당한 사업적 이익 또는 법적 의무 준수를 위해 필요한 기간 동안 귀하의 개인정보를 보유합니다. 당사가 더 이상 귀하의 정보를 이용하지 않기를 원하시는 경우, 이를 삭제할 것을 요청할 수
    있습니다.
    <br />
    이에 대한 자세한 내용은 위 "이용자 권리" 항목 7항을 참조하시기 바랍니다.
    <br />
    당사는 회원탈퇴, 서비스 종료, 이용자에게 동의받은 개인정보 보유기간의 도래와 같이 개인정보의 수집 및 이용목적이 달성된 개인정보는 재생이 불가능한 방법으로 파기하고 있습니다. 법령에서 보존의무를 부과한 정보에
    대해서도 해당 기간 경과 후 지체없이 재생이 불가능한 방법으로 파기합니다. 전자적 파일 형태의 경우 복구 및 재생이 되지 않도록 기술적인 방법을 이용하여 안전하게 삭제하며, 출력물 등은 분쇄하거나 소각하는 방식
    등으로 파기합니다.
    <br />
    <ul>
      <li><b>전자상거래 등에서 소비자 보호에 관한 법률</b></li>
      <li style="padding-left: 30px;"><b>계약 또는 청약철회 등에 관한 기록 : 5년 보관</b></li>
      <!-- <li><b>대금결제 및 재화 등의 공급에 관한 기록 : 5년 보관</b></li> -->
      <li style="padding-left: 30px;"><b>소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 보관</b></li>
      <li><b>전자문서 및 전자거래 기본법</b></li>
      <li style="padding-left: 30px;"><b>공인전자주소를 통한 전자문서 유통에 관한 기록 : 10년 보관</b></li>
      <li><b>통신비밀보호법</b></li>
      <li style="padding-left: 30px;"><b>로그인 기록 : 3개월</b></li>
    </ul>
    <br /><br />
    <h4>이용자 생성 콘텐츠</h4>
    귀하는 본 사이트 또는 본 서비스의 특정 영역에 수시로 서면 게시물 및 기타 특정 자료(총칭하여, "사용자 콘텐츠")를 제출할 수 있습니다. 본 사이트에 제출하거나 본 사이트에서 사용하기 위하여 또는 본 서비스와
    관련하여 제출하는 모든 사용자 콘텐츠는 공개 정보가 될 수 있습니다. 귀하는 그러한 사용자 콘텐츠가 개인정보를 포함하여 귀하 또는 타인에 대한 정보를 포함하는 경우 주의를 기울여야 합니다. 당사는 이러한 제출
    결과에 대해 책임을 지지 않으며, 제3자가 본 방침, 법률 또는 귀하의 개인정보 보호 및 안전을 위반하는 방식으로 해당 정보를 사용하는 것을 막을 수 없습니다.
    <br /><br />
    <h4>소셜 네트워킹 서비스</h4>
    귀하는 본 서비스의 계정을 소셜 네트워크 서비스(예: 페이스북, Google+, 또는 LinkedIn 등)의 계정에 연결할 수 있습니다. 이를 통해 귀하는 다른 사이트 또는 서비스에서 귀하의 인증정보를 사용하여
    당사 서비스의 특정 기능에 로그인할 수 있습니다.
    <br /><br />
    <h4>아동의 개인정보</h4>
    당사는 아이들의 프라이버시를 존중합니다.<br />
    당사는 13세 미만 아동의 개인정보를 의도적으로 수집, 이용 또는 공개하지 않습니다. 당사가 해당 개인정보를 수령하였다고 생각하는 경우, 본 방침의 마지막 "연락처" 섹션에 명시된 방법 중 하나를 이용하여 당사에
    연락주시기 바랍니다.
  <br /><br />
  <h4>제3자 사이트에 대한 링크</h4>
  본 사이트 및 본 서비스는 제3자가 운영 및 유지하는 인터넷 상의 다른 웹사이트로의 링크를 제공할 수 있습니다. 이러한 웹사이트들은 당사와 독립적으로 운영되며, 당사의 통제 또는 책임 하에 있지 않습니다. 또한,
  귀하는 당사의 특정 제품 또는 서비스에 대한 쿠폰 수령을 위한 경우를 포함하여 일정한 경우 특정 프로그램, 제품 또는 서비스를 해당 제3자로부터 다운로드 받아야 할 수 있습니다. 이러한 다른 웹사이트에 대한
  링크가 존재하는 것이 당사가 그러한 다른 웹사이트, 그에 게시된 콘텐츠, 그에 포함된 프로그램, 제품 또는 서비스 또는 그와 관련된 개인이나 단체에 대한 여하한 보증을 제공하는 것은 아닙니다. 귀하가 이러한
  제3자 웹사이트를 방문하는 경우, 귀하는 본건 서비스를 더 이상 이용하지 않는 것이며 본건 사이트 또한 종료되고, 당사는 이러한 웹사이트 등 당사가 통제하지 않는 기타 웹사이트와 관련하여 어떠한 책임이나 의무도
  부담하지 않는다는 점은 유의하시기 바랍니다. 해당 제3자 웹사이트의 개인정보처리방침 및 이용약관을 검토하시기 바라며, 귀하는 그러한 웹사이트의 사용에 대한 전적인 책임을 지고 그로부터 발생하는 모든 위험을
  부담합니다.
  <br /><br />
  <h4>본 방침의 변경 통지</h4>
  당사는 귀하에 대한 사전 통지 없이 본 방침을 업데이트함으로써 그 단독 재량으로 언제든지 본 방침을 변경 또는 수정할 권리를 보유합니다. 본 개인정보처리방침의 변경이 있을 경우에는 본 웹사이트 첫 화면의 공지사항
  란 또는 별도의 창이나, E-mail을 통하여 변경내용을 사전에 공지하도록 하겠습니다.
  <br />
  이전 개인정보 처리방침은 아래에서 확인하실 수 있습니다.
  <br />
  <!-- @@jy 이전 개인정보 처리방침 새창열기 링크 -->
  <b><a href="./old_policy" target="_blank">이전 개인정보 처리방침:[링크]</a></b>
  <br /><br />
  <h4>연락처</h4>
  본 방침과 관련하여 질문, 요청 또는 우려사항이 있거나 본 방침에 명시된 권리를 행사하고자 하는 경우, <b>[hyerim.kang@solta.com ]</b>으로 이메일을 보내거나 다음 주소로 서면으로 당사에 연락하실 수
  있습니다.
  <br /><br />
  • 개인정보 보호책임자<br />
  <b>
    • 전화번호: [1544-8330]<br />
    • 이메일: [hyerim.kang@solta.com]
  </b>
</div></template>

<script>
export default {};
</script>

<style lang="scss"></style>

import {
  SET_ACCESS_TOKEN,
  SET_MY_INFO,
  DESTROY_ACCESS_TOKEN,
  DESTROY_MY_INFO,
  SET_STEP_VALUE,
} from "./mutations-types";
import api from "@/api";
import Cookies from "js-cookie";

export default {

  //로그인
  async signin({ commit }, payload) {
    commit(DESTROY_MY_INFO);
    commit(DESTROY_ACCESS_TOKEN);

    // fcmToken같이 보내기
    const { loginId, password, signupPath, userAgent, fcmToken } = payload;
    try {
      const res = await api.post("/authenticate", { loginId, password, signupPath, userAgent, fcmToken });

      console.log('res', res);
      if (res.data.return_code === 101) {
        return alert("회원가입 승인 대기중인 회원입니다.");
      }

      const { token } = res.data;
      commit(SET_ACCESS_TOKEN, token);

      // 로그인 ip/시간 저장
      const accessInfo = {
        user_id: res.data.user_id,
        loginUserName: res.data.username,
        loginUserId: res.data.loginId,
        ip: res.data.loginIp,
        recentLoginDate: res.data.recentLoginDate,
        hospital: res.data.hospital,
      }
      const CookieName = process.env.VUE_APP_CookieName;
      Cookies.set(CookieName, JSON.stringify(accessInfo), { expires: 100000 });
      return res.data;

    } catch (err) {
      if (err.response.status === 401) {
        return err.response.status;
        // return alert("아이디 또는 비밀번호를 확인해 주세요");
      } else {
        return alert("로그인 에러 ");
      }
    }
  },

  //토큰이 있는 경우 내정보 가져오기
  async signinByToken({ commit, dispatch }, token) {
    commit(DESTROY_MY_INFO);
    commit(SET_ACCESS_TOKEN, token);
    try {
      if (token) {
        const res = await api.get("/user/me");
        if (res) {
          commit(SET_MY_INFO, res.data);
        }
      } else {
        this.$router.push({ name: "SignUpPage" });
      }
    } catch (err) {
      // if (err.response.status === 401) {
      //   dispatch("signout");
      // }
      dispatch("signout");
      this.$router.push({ name: "SignUpPage" });
    }
  },

  async getMe({ commit }) {
    try {
      const res = await api.get("/user/me");
      if (res) {
        commit(SET_MY_INFO, res.data);
      }
    } catch (err) {
      return alert(`get me error`);
    }
  },

  //로그아웃
  signout({ commit }) {
    commit(DESTROY_MY_INFO);
    commit(DESTROY_ACCESS_TOKEN);
  },

  stepValue({ commit }, value) {
    commit(SET_STEP_VALUE, value);
  },
}

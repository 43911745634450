<template>
  <div class="downloadBox">
    <!-- file1 -->
    <div
      class="btn-wrap"
      v-if="item && item.file1 !== null && item.file1 !== ''"
    >
      <v-btn icon color="primary" @click="downLoad(item, 'file1')">
        <v-icon>mdi-tray-arrow-down</v-icon>
      </v-btn>
    </div>
    <!-- file2 -->
    <div
      class="btn-wrap"
      v-if="item && item.file2 !== null && item.file2 !== ''"
    >
      <v-btn icon color="primary" @click="downLoad(item, 'file2')">
        <v-icon>mdi-tray-arrow-down</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: [Object, Array],
    },
    fileKey: {
    type: String,
  },
  },
  methods: {
    downLoad(item, fileKey) {
      // 권한체크
      if (!this.$helper.checkPermission(item)) {
        const msg = "권한이 없습니다";
        this.$helper.showTostMessage("top", msg, "info");
        return;
      }
      // console.log("item", item);
      // window.open(item.file1, "_blank");
      window.open(item[fileKey], "_blank");
    },
  },
};
</script>
<style lang="">
</style>
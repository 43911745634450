const moment = require("moment");

exports.commonModel = () => {
    return {
        endpoint: '',
        fromDay: '',
        toDay: '',
        searchGbn: '',
        searchVal: ''
    };
};

exports.userSignUpModel = () => {
    return {
        endpoint: 'signup',
        loginId: "",
        password: "",
        rePassword: "",
        name: "",
        cell: "",
        hospital: "",
        majors: "",
        signupPath: "",
        CI: "",
        agreeAll: false,
        appPush: false,
        marketing: false,
        agreePolicy: false,
        agreeTerms: false,
        checkSms: false,
        checkedSms: false
    };
};

exports.userModel = () => {
    return {
        endpoint: 'user',
        fromDay: '',
        toDay: '',
        searchGbn: '',
        searchVal: '',
        activated: true,
        majors: '',
        user_id: 0,
        name: "",
        cell: "",
        password: "",
        rePassword: "",
        isHcp: 'W'
    };
};

exports.inquiryModel = () => {
    return {
        endpoint: 'inquiry',
        fromDay: '',
        toDay: '',
        searchGbn: '',
        searchVal: '',
        ipk: 0,
        mainType: "",
        name: "",
        phone: "",
        email: "",
        password: "",
        contents: "",
        comment: "",
        adminComment: "",
        agree: "",
        useYN: "Y",
        isDp: "Y",
        updUpk: 0
    };
};

exports.inquiryCommentModel = () => {
    return {
        endpoint: 'inquiry/comment',
        fromDay: '',
        toDay: '',
        searchGbn: '',
        searchVal: '',
        cipk: 0,
        inquiry_id: 0,
        comment: '',
        useYN: "Y",
        updUpk: 0
    };
};

exports.equipmentModel = () => {
    return {
        endpoint: 'equipment',
        fromDay: '',
        toDay: '',
        searchGbn: '',
        searchVal: '',
        ipk: 0,
        mainType: "",
        name: "",
        phone: "",
        email: "",
        hospital: "",
        productName: "",
        productCode: "",
        productErrorCodeYN: "N",
        productErrorCode: "",
        contents: "",
        fileLocation: "",
        visitDate: "",
        finishDate: "",
        fixYN: 0,
        dept_id: "",
        address: {
            city: "",
            street: "",
            zipcode: "",
            siDo: "",
            guGun: "",
        },
        isAddressSearch: false,
        comment : "",
        adminComment: "",
        agree: "",
        useYN: "Y",
        isDp: "Y",
        updUpk: 0
    };
};

exports.equipmentCommentModel = () => {
    return {
        endpoint: 'equipment/comment',
        fromDay: '',
        toDay: '',
        searchGbn: '',
        searchVal: '',
        cipk: 0,
        equipment_id: 0,
        comment: '',
        useYN: "Y",
        updUpk: 0
    };
};

exports.bbsModel = () => {
    return {
        endpoint: 'bbs',
        fromDay: '',
        toDay: '',
        searchGbn: '',
        searchVal: '',
        xpk: 0,
        bbsGbn: "",
        titleGbn: "",
        category: "",
        subCategory: "",
        title: "",
        contents: "",
        detailTextImg: "",
        detailTextImgPSD: "",
        keyword: "",
        useYN: "Y",
        targetDateStart: "",
        targetDateEnd: "",
        isDp: "Y",
        insUpk: 0,
        updUpk: 0
    };
};

exports.authCodeModel = () => {
    return {
        endpoint: 'productAuth/code',
        fromDay: '',
        toDay: '',
        searchGbn: '',
        searchVal: '',
        makeNum: 0,
        authNum: 0,
        insUpk: 0
    };
};

exports.authUserModel = () => {
    return {
        endpoint: 'productAuth/authUser',
        fromDay: '',
        toDay: '',
        searchGbn: '',
        searchVal: '',
        authType: '',
    };
};

exports.authUserBlockModel = () => {
    return {
        endpoint: 'authBlock',
        fromDay: '',
        toDay: '',
        searchGbn: '',
        searchVal: '',
        apk: 0,
        phone: "",
        blockType: 0,
        useYN: "Y",
        state: "Y"
    };
};

exports.clinicModel = () => {
    return {
        endpoint: 'hospital',
        fromDay: '',
        toDay: '',
        searchGbn: '',
        searchVal: '',
        hpk: 0,
        city: "",
        hospitalName: "",
        hospitalSaleMan: "",
        hospitalAddress: "",
        hospitalChannel: "",
        phone: "",
        latitude: "",
        longitude: "",
        useYN: "Y",
        isDp: "Y"
    };
};

<template>
  <div>
    <input type="file" style="width: 300px" accept="image/*" />
    <!-- <input
      type="file"
      accept="image/*"
      style="width: 300px"
      @change="fileSelect"
    /> -->
  </div>
</template>
<script>
export default {
  methods: {
    fileSelect(file) {
      // alert(file.target.files[0].name);
      // return;
      // console.log('', file.target.files[0]);
      this.$emit("uploadStarted");
      this.selectFile = file.target.files[0];
    },
  },
};
</script>
<style lang="">
</style>
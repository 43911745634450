import Vue from "vue";
import Router from "vue-router";
import HomePage from "@/components/thermage/home/HomePage";
import BrandPage from "@/components/thermage/brand/BrandPage";
import TipsPage from "@/components/thermage/brand/TipsPage";
import PrinciplePage from "@/components/thermage/brand/PrinciplePage";
import TechnologyPage from "@/components/thermage/brand/TechnologyPage";

import FaqPage from "@/components/thermage/faq/FaqPage";
import CertifyPage from "@/components/thermage/certify/CertifyPage";
import AuthenticatedPage from "@/components/thermage/certify/AuthenticatedPage";
import SubmittedPage from "@/components/thermage/certify/SubmittedPage";
import GenuineProductPage from "@/components/thermage/certify/GenuineProductPage";
import FindClinicPage from "@/components/thermage/findclinic/FindClinicPage";
import NoticePage from "@/components/thermage/cs/NoticePage";
import NoticeDetailPage from "@/components/thermage/cs/NoticeDetailPage";
import InquiryBoardPage from "@/components/thermage/cs/InquiryBoardPage";
import InquiryEnterPage from "@/components/thermage/cs/InquiryEnterPage";
import InquiryDetailPage from "@/components/thermage/cs/InquiryDetailPage";
import EquipmentBoardPage from "@/components/thermage/cs/EquipmentBoardPage";
import EquipmentEnterPage from "@/components/thermage/cs/EquipmentEnterPage";
import EquipmentEnterDonePage from "@/components/thermage/cs/EquipmentEnterDonePage";
import EquipmentDetailPage from "@/components/thermage/cs/EquipmentDetailPage";

// import RefLibraryPage from "@/components/thermage/reflibrary/RefLibraryPage";
import RefArchivePage from "@/components/thermage/achive/ArchivePage.vue";
import RefArchivePage2 from "@/components/thermage/achive/ArchivePage.vue";
import RefArchiveDetailPage from "@/components/thermage/achive/ArchiveDetailPage.vue";
import NewsLetter from "@/components/thermage/achive/NewsLetterPage.vue";
import NewsLetterDetailPage from "@/components/thermage/achive/NewsLetterDetailPage.vue";
import MyPage from "@/components/thermage/my/MyPage";
import TestPage from "@/components/test/TestPage";

import Login from "@/components/thermage/home/SignUpPage.vue";
import FindIDPage from "@/components/thermage/home/find/FindIDPage.vue";
import FindPWPage from "@/components/thermage/home/find/FindPWPage.vue";
import OldPolicy from "@/components/thermage/common/OldPolicy.vue";
import KakaoPolicy from "@/components/thermage/common/KakaoPolicy.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  scrollBehavior() {
    // console.log(window.scrollY);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  },
  routes: [
    //홈
    {
      path: "/",
      component: HomePage,
      name: "HomePage",
    },
    //홈
    {
      path: "/login",
      component: Login,
      name: "Login",
      props: true,
    },
    // 써마지®
    {
      path: "/brand",
      component: BrandPage,
      name: "BrandPage",
    },
    // 시술 팁 소개
    {
      path: "/brand/tips",
      component: TipsPage,
      name: "TipsPage",
    },
    // 콜라겐 재생 원리
    {
      path: "/brand/principle",
      component: PrinciplePage,
      name: "PrinciplePage",
    },
    // 기술&허가 정보
    {
      path: "/brand/technology",
      component: TechnologyPage,
      name: "TechnologyPage",
    },
    // 써마지® FAQ
    {
      path: "/faq",
      component: FaqPage,
      name: "FaqPage",
    },
    // 정품인증
    {
      path: "/certify",
      component: CertifyPage,
      name: "CertifyPage",
    },
    // 정품인증 완료
    {
      path: "/certify/authenticated",
      component: AuthenticatedPage,
      name: "AuthenticatedPage",
    },
    // 사은품 정보 입력 완료
    {
      path: "/certify/submitted",
      component: SubmittedPage,
      name: "SubmittedPage",
    },
    // 정품 팁 이란?
    {
      path: "/certify/genuine_product",
      component: GenuineProductPage,
      name: "GenuineProductPage",
    },

    // 병원 찾기
    {
      path: "/find",
      component: FindClinicPage,
      name: "FindClinicPage",
    },
    // 자료실
    // {
    //   path: "/library",
    //   component: RefLibraryPage,
    //   name: "RefLibraryPage",
    // },
    {
      path: "/archive",
      component: RefArchivePage,
      name: "RefArchivePage",
    },
    {
      path: "/archive/:xpk",
      component: RefArchiveDetailPage,
      name: "RefArchiveDetailPage",
    },
    {
      path: "/archive2",
      component: RefArchivePage2,
      name: "RefArchivePage2",
    },
    {
      path: "/archive2/:xpk",
      component: RefArchiveDetailPage,
      name: "RefArchiveDetailPage",
    },
    // 뉴스레터
    {
      path: "/newsLetter",
      component: NewsLetter,
      name: "NewsLetter",
    },
    {
      path: "/newsLetter/:xpk",
      component: NewsLetterDetailPage,
      name: "NewsLetterDetailPage",
    },
    // 고객 지원 - 문의게시판
    {
      path: "/cs/inquiry",
      component: InquiryBoardPage,
      name: "InquiryBoardPage",
    },
    {
      path: "/cs/inquiry_enter",
      component: InquiryEnterPage,
      name: "InquiryEnterPage",
    },
    {
      path: "/cs/inquiry_detail",
      component: InquiryDetailPage,
      name: "InquiryDetailPage",
    },
    {
      path: "/cs/inquiry_detail/:ipk",
      component: InquiryDetailPage,
      name: "InquiryDetailPage",
    },
    // 고객 지원 - 장비 A/S문의
    {
      path: "/cs/equipment",
      component: EquipmentBoardPage,
      name: "EquipmentBoardPage",
    },
    {
      path: "/cs/equipment_enter",
      component: EquipmentEnterPage,
      name: "EquipmentEnterPage",
      props: true,
    },
    {
      path: "/cs/equipment_enter_done",
      component: EquipmentEnterDonePage,
      name: "EquipmentEnterDonePage",
    },
    {
      path: "/cs/equipment_detail",
      component: EquipmentDetailPage,
      name: "EquipmentDetailPage",
    },
    {
      path: "/cs/equipment_detail/:ipk",
      component: EquipmentDetailPage,
      name: "EquipmentDetailPage",
    },
    // 고객 지원 - 공지사항
    {
      path: "/cs/notice",
      component: NoticePage,
      name: "NoticePage",
    },
    {
      path: "/cs/notice_detail",
      component: NoticeDetailPage,
      name: "NoticeDetailPage",
    },
    {
      path: "/cs/notice_detail/:xpk",
      component: NoticeDetailPage,
      name: "NoticeDetailPage",
    },
    //마이페이지
    {
      path: "/mypage",
      component: MyPage,
      name: "MyPage",
    },
    {
      path: "/find_id",
      component: FindIDPage,
      name: "FindIDPage",
    },
    {
      path: "/find_pw",
      component: FindPWPage,
      name: "FindPWPage",
    },
    {
      path: "/test",
      component: TestPage,
      name: "TestPage",
    },
    // 이전 개인정보 처리방침
    {
      path: "/old_policy",
      component: OldPolicy,
      name: "OldPolicy",
    },
    // 카카오 개인정보 처리방침
    {
      path: "/kakao_policy",
      component: KakaoPolicy,
      name: "KakaoPolicy",
    },
  ],

});

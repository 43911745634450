<template>
  <div class="select-category">
    <v-select
      :items="targetCategory"
      label="카테고리 선택"
      solo
      required
      ref="refCategory"
      v-model="bbsModel.category"
      @change="changeCategory"
    ></v-select>
    <v-select
      v-if="
        bbsModel.category === 'SOMA교육프로그램' ||
        bbsModel.category === '광고자료'
      "
      :items="targetSubCategory"
      label="소분류 카테고리 선택"
      solo
      required
      ref="refSubCategory"
      v-model="bbsModel.subCategory"
      @change="changeSubCategory"
    ></v-select>
  </div>
</template>

<script>
export default {
  props: {
    bbsModel: {
      type: Object,
    },
    targetCategory: {
      type: Array,
    },
    targetSubCategory: {
      type: Array,
    },
  },
  data() {
    return {};
  },
  methods: {
    changeCategory(e) {
      this.$emit("emitChangeCategory", e);
    },
    changeSubCategory(e) {
      this.$emit("emitChangeSubCategory", e);
    },
  },
};
</script>

<style lang="scss">
@media (max-width: 768px) {
  div.select-category {
    .v-select {
      width: 100%;
    }
  }
}
.select-category {
  .v-select {
    width: 250px;
    display: inline-block;
    margin-right: 15px;
  }
}
</style>
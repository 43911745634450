<template>
  <div class="page equipment-detail">
    <div class="detail-inner">
      <div class="detail-title">
        <h2>{{ data && $helper.equipmentTypeLbl(data.mainType) }} 문의</h2>
        <div class="equipment-info">
          <span>{{ data && data.wname }} | </span>
          <span v-if="data && data.hospital">{{ data && data.hospital }} | </span>
          <span>{{ data && $moment(data.regDate).format("YYYY-MM-DD") }}</span>
        </div>
      </div>
      <div class="detail-content">
        <p v-html="data && $helper.js_nl2br(data.contents)"></p>
      </div>
      <!-- 관리자 답변글 처리 -->
      <!-- 관리자 댓글 처리 -->
<!--      <div-->
<!--        v-if="data && data.adminComment !== '' && data.adminComment !== null"-->
<!--        :class="`${data.adminComment !== '' ? 'admin' : ''}`"-->
<!--      >-->
<!--        <h4 class="primary&#45;&#45;text">-->
<!--          <v-icon>mdi-arrow-right-bottom</v-icon>-->
<!--          관리자-->
<!--        </h4>-->
<!--        <div class="adminCommentBox">-->
<!--          <p class="left">{{ data.adminComment }}</p>-->
<!--          <p class="date">-->
<!--            {{-->
<!--              $helper.changeDateToForUpdateDp(-->
<!--                data.adminCommentRegDate,-->
<!--                data.adminCommentUpdDate-->
<!--              )-->
<!--            }}-->
<!--          </p>-->
<!--        </div>-->
<!--      </div>-->
      <!-- <div>수정</div> -->

      <!-- 댓글 처리 -->
<!--      <EquipmentCommentList-->
<!--        :data="dataComment"-->
<!--        @clickReplyDelete="clickReplyDelete"-->
<!--      />-->

      <!-- 등록하기 -->
<!--      <EquipmentCommentInput @commentInputComplete="commentInputComplete" />-->
    </div>

    <div class="bottom-button">
      <v-btn
        color="primary"
        x-large
        depressed
        @click="$router.push('/cs/equipment')"
        >목록보기</v-btn
      >
<!--      <v-btn x-large depressed @click="clickEquipmentDelete">삭제하기</v-btn>-->
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import EquipmentCommentList from "./comp/equipment/EquipmentCommentList.vue";
import EquipmentCommentInput from "./comp/equipment/EquipmentCommentInput.vue";

const modelService = require("@/utils/dataModel.js");

export default {
  components: {
    EquipmentCommentList,
    EquipmentCommentInput,
  },
  data() {
    return {
      equipmentModel: modelService.equipmentModel(),
      equipmentCommentModel: modelService.equipmentCommentModel(),
      data: null,
      dataComment: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.equipmentModel.ipk = this.$route.params.ipk;
      this.equipmentModel.endpoint = "equipment/" + this.equipmentModel.ipk;
      this.getDataQuery(this.equipmentModel).then((res) => {
        if (res === undefined) return;
        this.data = res.data;
        this.dataComment = res.dataComment;
        console.log("data", this.data);
        console.log("dataComment", this.dataComment);
      });
    },
    commentInputComplete(e) {
      this.equipmentCommentModel.equipment_id = this.data.ipk;
      this.equipmentCommentModel.comment = e;
      this.saveCommentData();
    },
    clickReplyDelete(item) {
      this.equipmentCommentModel.equipment_id = this.data.ipk;
      this.equipmentCommentModel.useYN = "N";
      this.equipmentCommentModel.cipk = item.cipk;
      this.saveCommentData();
    },
    clickEquipmentDelete() {
      if (!confirm("문의글을 삭제하시겠습니까?")) return;
      this.equipmentModel.useYN = "N";
      this.saveEquipmentData();
    },
    saveCommentData() {
      this.saveDataQuery(this.equipmentCommentModel).then((res) => {
        if (res === undefined) return;
        this.getData();
      });
    },
    saveEquipmentData() {
      this.equipmentModel.endpoint = "equipment";
      this.saveDataQuery(this.equipmentModel).then((res) => {
        if (res === undefined) return;
        this.$router.push("/cs/equipment").catch(() => {});
      });
    },
    ...mapActions(["getDataQuery", "saveDataQuery"]),
  },
};
</script>


<style lang="scss">
@media (max-width: 768px) {
  div.equipment-detail {
    .detail-inner {
      margin: 40px auto 40px;
      padding: 15px;
      .detail-title {
        padding: 30px 10px;
        h2 {
          font-size: 20px;
        }
        .badge {
          padding: 1px 10px;
          margin-right: calc(100% - 60px);
          margin-bottom: 10px;
        }
        .equipment-info {
          position: absolute;
          right: 10px;
          top: 10px;
          font-size: 12px;
        }
      }
      .detail-content {
        padding: 30px 10px;
      }
      .adminCommentBox {
        flex-direction: column;
        .date {
          font-size: 12px;
          text-align: right;
        }
      }
      .reply-list {
        padding: 15px;

        .reply {
          // position: relative;
          // border-bottom: 1px solid $grey-color;
          padding: 10px 0;
          &:last-child {
            // border-bottom: none;
          }
          h4 {
            // margin-bottom: 10px;
          }

          .date {
            font-size: 12px;
          }

          &.mine {
            .btn-delete {
              // position: absolute;
              // top: 20px;
              // right: 0;
            }
            .admin {
              padding-left: 20px;
              .v-icon {
                margin-left: -24px;
              }
              > p {
                padding: 0;
              }
            }
          }
        }
      }
      .input-reply {
        padding: 15px;
      }
      .col.col-3 {
        padding: 12px 12px 12px 0;
        .v-btn {
          padding: 0 5px;
          font-size: 12px;
        }
      }
    }
    .bottom-button {
      text-align: center;
      margin: 40px auto 40px;
      .v-btn {
        width: 90vw;
        &:first-child {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
}
.equipment-detail {
  background: linear-gradient(#ffffff, #ebe5e1);
  padding: 40px 0;
  .detail-inner {
    width: 90vw;
    max-width: 900px;
    margin: 100px auto 40px;
    background: #ffffff;
    padding: 20px 40px 60px;
    > .admin {
      background: #f4f4f4;
      padding: 20px;
    }
    .adminCommentBox {
      display: flex;
      padding: 10px;
      .left {
        margin-right: auto;
      }
    }
    .detail-title {
      position: relative;
      padding: 20px;
      border-bottom: 1px solid $point-color;
      .badge {
        display: inline-block;
        border: 1px solid $point-color;
        padding: 1px 15px;
        margin-right: 20px;
        color: $point-color;
        font-size: 12px;
      }
      .equipment-info {
        position: absolute;
        right: 40px;
        top: 25px;
      }
    }
    .detail-content {
      padding: 40px 20px;
      margin-bottom: 40px;
      img {
        width: 100%;
      }
    }
    .reply-list {
      // margin-top: 40px 20px;
      background: #f4f4f4;
      padding: 20px;

      border-top: 1px solid $grey-color;
      .reply {
        position: relative;
        border-bottom: 1px solid $grey-color;
        padding: 30px 20px 20px;
        &:last-child {
          border-bottom: none;
        }
        h4 {
          margin-bottom: 10px;
        }
        .date {
          text-align: right;
          color: $secondary-color;
        }
        &.mine {
          .btn-delete {
            position: absolute;
            top: 20px;
            right: 0;
          }
        }
        .admin {
          > p {
            padding: 0 0 0 25px;
          }
        }
      }
    }
    .input-reply {
      // margin-top: 40px;
      background: #f4f4f4;
      padding: 40px 20px 15px;
    }
  }
  .bottom-button {
    text-align: center;
    margin: 40px 0;
    .v-btn {
      width: 300px;
      &:first-child {
        margin-right: 15px;
      }
    }
  }
}
</style>

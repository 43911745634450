<template>
  <div class="page equipment-enter">
    <div class="page-inner">
      <div class="page-title">
        <h1 class="main-title primary--text">장비 A/S 접수</h1>
        <p class="sub-title">
          <span class="sub-span1"><b>Contact Us</b> 1544-8330</span><br />
          <span class="sub-span2">월 – 금요일 10시 – 18시 <br />
          (점심시간 12시- 13시, 주말 및 공휴일 휴무)
          </span>
        </p>
      </div>
      <v-form ref="form">
        <v-select
          :items="itemsEquipmentType"
          label="문의 유형 선택"
          solo
          required
          ref="refEquipmentType"
          v-model="equipmentModel.mainType"
        ></v-select>
        <v-text-field
          label="성함"
          ref="refName"
          solo
          height="52"
          required
          v-model="equipmentModel.name"
          :rules="[nameRules.required, nameRules.counter]"
        ></v-text-field>
        <v-text-field
          label="휴대전화번호"
          ref="refCell"
          solo
          height="52"
          required
          v-model="equipmentModel.phone"
          type="text"
          :rules="[cellRules.required, cellRules.counter, cellRules.check]"
        >
        </v-text-field>
        <v-text-field
          label="이메일"
          ref="refEmail"
          solo
          height="52"
          required
          v-model="equipmentModel.email"
          :rules="[emailRules.required, emailRules.email]"
        ></v-text-field>
        <v-text-field
            label="병원명"
            ref="refHospital"
            solo
            height="52"
            required
            v-model="equipmentModel.hospital"
            :rules="[textLessThan30Rules.counter]"
        ></v-text-field>
        <v-select
            :items="itemsEquipmentProductName"
            label="제품명"
            solo
            height="52"
            required
            ref="refProductName"
            v-model="equipmentModel.productName"
        ></v-select>
        <v-text-field
            label="기기 일련번호"
            ref="refProductCode"
            solo
            height="52"
            required
            v-model="equipmentModel.productCode"
            :rules="[textLessThan30Rules.counter]"
        ></v-text-field>
        <p class="input-guide input-top" v-if="equipmentModel.mainType === 'equipmentAfterService'">
          에러코드가 발생했습니까?
        </p>
        <v-radio-group row v-model="equipmentModel.productErrorCodeYN" v-if="equipmentModel.mainType === 'equipmentAfterService'">
          <v-radio key="1" label="예" value="Y"></v-radio>
          <v-radio key="2" label="아니오" value="N"></v-radio>
        </v-radio-group>
        <v-text-field
            label="에러코드"
            ref="refProductErrorCode"
            solo
            height="52"
            required
            v-if="(equipmentModel.productErrorCodeYN === 'Y') && (equipmentModel.mainType === 'equipmentAfterService')"
            v-model="equipmentModel.productErrorCode"
        ></v-text-field>
        <v-textarea
          outlined
          placeholder="문의 사항을 입력해주세요."
          v-model="equipmentModel.contents"
        ></v-textarea>
        <div class="filebox">
          <input class="upload-name" value="첨부파일" placeholder="첨부파일" readonly>
          <label for="file">파일 올리기</label>
          <S3FileUpload
              ref="S3FileUploader"
              uploadDir="test"
              fileHeader="test-image"
              :fileInfo="fileInfo"
              @uploadComplete="uploadComplete"
              @changeDeleteOpt="file_changeDeleteOpt"
          />
        </div>
        <p class="input-guide input-top">
          *정확한 진단과 신속한 처리를 위해 증상을 확인할 수 있는 동영상 및 사진을 업로드해 주시기 바랍니다. (파일용량: 최대 50MB)
        </p>
        <v-list-item-title  v-if="equipmentModel.mainType === 'equipmentAfterService'">
          방문희망일
          <span class="asterisk">*</span>
        </v-list-item-title>
        <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            v-if="equipmentModel.mainType === 'equipmentAfterService'"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="equipmentModel.visitDate"
                label="방문희망일"
                readonly
                solo
                v-bind="attrs"
                v-on="on"
                :rules="[visitCheckDateRules.required]"
            />
          </template>
          <!-- :min="toDate" -->
          <v-date-picker
              v-model="equipmentModel.visitDate"
              @input="menu2 = false"
          />
        </v-menu>
        <Address ref="refAddress" :userData="equipmentModel" @openSearch="openSearch" v-if="equipmentModel.mainType === 'equipmentAfterService'" />
        <AddressSearchDaum ref="refAddressSearch" @searchCompleteUse="searchCompleteUseHandler" v-if="equipmentModel.mainType === 'equipmentAfterService'" />
        <br />
        <div class="terms-box">
          <TermsInquiry />
        </div>
<!--        <p class="input-guide input-top">-->
<!--          ※ 귀하는 개인정보의 수집 및 이용에 대하여 동의하지 않을 수 있습니다.-->
<!--          <br />-->
<!--          다만, 동의하지 않을 경우 고객의견함의 이용이 불가능합니다.-->
<!--        </p>-->
        <v-checkbox
            required
            v-model="equipmentModel.agree"
            true-value="Y"
            false-value="N"
            label="동의합니다."
        >
        </v-checkbox>
        <div class="terms-box">
          <TermsEquipment />
        </div>
        <v-checkbox
            required
            v-model="equipmentModel.agree1"
            true-value="Y"
            false-value="N"
            label="확인했습니다."
        >
        </v-checkbox>
        <div class="bottom-wrap">
          <v-btn color="primary" x-large depressed block @click="clickSubmit"
            >확인</v-btn
          >
          <v-btn x-large depressed block @click="$router.push('/')"
            >취소</v-btn
          >
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
const rulesService = require("@/utils/rules.js");
const formatService = require("@/utils/format.js");
const modelService = require("@/utils/dataModel.js");
import TermsEquipment from "@/components/thermage/common/TermsEquipment.vue";
import Address from "@/components/thermage/cs/comp/equipment/Address.vue";
import AddressSearchDaum from "@/components/common/AddressSearchDaum.vue";
import S3FileUpload from '@/components/common/v2.0/S3_FileUpload_V3.vue';
import Cookies from "js-cookie";
import TermsInquiry from "@/components/thermage/common/TermsInquiry.vue";

export default {
  components: {
    TermsInquiry,
    TermsEquipment,
    Address,
    AddressSearchDaum,
    S3FileUpload,
  },
  data() {
    return {
      nameRules: rulesService.nameRules(),
      cellRules: rulesService.cellRules(),
      emailRules: rulesService.emailRules(),
      passwordRules: rulesService.passwordRules(),
      itemsEquipmentType: formatService.itemsEquipmentType(),
      itemsEquipmentProductName: formatService.itemsEquipmentProductName(),
      isDev: process.env.NODE_ENV === "development",
      equipmentModel: modelService.equipmentModel(),
      visitCheckDateRules: rulesService.visitCheckDateRules(),
      textLessThan30Rules: rulesService.textLessThan30Rules(),
      menu2: false,
      fileInfo: null,
    };
  },
  mounted() {
    if (Cookies.get(process.env.VUE_APP_CookieName) !== undefined) {
      let accessInfo = JSON.parse(Cookies.get(process.env.VUE_APP_CookieName));
      let hospital = accessInfo.hospital;
      this.equipmentModel.hospital = hospital;
    }
    // if (this.isDev) {
    //   // 개발모드에서 처리
    //   this.equipmentModel.mainType = 'equipmentInquiry';
    //   this.equipmentModel.name = '홍길동';
    //   this.equipmentModel.phone = '01012345678';
    //   this.equipmentModel.email = 'hhshin@wizai.co.kr';
    //   // this.equipmentModel.productName = '테스트 제품명';
    //   this.equipmentModel.productCode = '1234567890';
    //   this.equipmentModel.productErrorCodeYN = 'Y';
    //   this.equipmentModel.productErrorCode = '1234567890';
    //   this.equipmentModel.contents = '테스트 내용';
    //   this.equipmentModel.visitDate = '2021-09-01';
    //   this.equipmentModel.address = {
    //     zipcode: '12345',
    //     siDo: '서울특별시',
    //     guGun: '강남구',
    //     city: '서울특별시 강남구',
    //     street: '테헤란로 123',
    //   };
    // }

    if (!this.isAuthorized) {
      // 이동 할 페이지 저장
      this.loginCheck(this.$route.fullPath);
      this.$router.push({
        name: 'Login',
        params: {
          "value" : "EquipmentEnterPage",
        }
      });
      return;
    }
    $("#file").on('change',function(){
      $(".upload-name").val($("#file").val());
    });
  },
  methods: {
    clickSubmit() {
      //필수
      if (!this.$refs.form.validate()) return;

      // 제품문의 유형 처리 필요
      if (this.equipmentModel.mainType === "") {
        this.$helper.showTostMessage(
          "top",
          "문의 유형을 선택해주세요.",
          "error"
        );
        return;
      }

      // 제품명 필수
      if (this.equipmentModel.productName === "") {
        this.$helper.showTostMessage(
            "top",
            "제품명을 선택해주세요.",
            "error"
        );
        return;
      }

      // 에러코드 필수
      if (this.equipmentModel.mainType === 'equipmentAfterService') {
        if (this.equipmentModel.productErrorCodeYN === "Y") {
          if (this.equipmentModel.productErrorCode === "") {
            this.$helper.showTostMessage(
                "top",
                "에러코드를 입력해주세요.",
                "error"
            );
            return;
          }
          // else if (this.equipmentModel.fileLocation === "") {
          //   this.$helper.showTostMessage(
          //       "top",
          //       "파일을 업로드해주세요.",
          //       "error"
          //   );
          //   return;
          // }
        }
      }

      // 제품내용 확인
      if (this.equipmentModel.contents === "") {
        this.$helper.showTostMessage(
          "top",
          "문의 내용을 입력해주세요.",
          "error"
        );
        return;
      }

      if (
        this.equipmentModel.agree === "" ||
        this.equipmentModel.agree === null ||
        this.equipmentModel.agree !== "Y"
      ) {
        this.$helper.showTostMessage(
          "top",
          "개인정보 수집 및 이용에 동의해 주세요",
          "error"
        );
        return;
      }
      if (
          this.equipmentModel.agree1 === "" ||
          this.equipmentModel.agree1 === null ||
          this.equipmentModel.agree1 !== "Y"
      ) {
        this.$helper.showTostMessage(
            "top",
            "안내사항을 확인해 주세요",
            "error"
        );
        return;
      }
      // console.log("", this.equipmentModel);
      // return;

      this.saveDataQuery(this.equipmentModel).then((res) => {
        if (res === undefined) return;
        this.$helper.showTostMessage(
          "top",
          "문의하기가 정상적으로 처리되었습니다",
          "success"
        );

        this.$router.push("/cs/equipment_enter_done");
      });
    },
    async openSearch() {
      this.$refs.refAddressSearch.showModal();
    },
    searchCompleteUseHandler(e) {
      // console.log("e.addr", e.addr);
      if (e.addr !== "" && e.addr !== null) {
        let addrArray = e.addr.split(" ");
        let siDo = addrArray.length > 0 ? addrArray[0] : "";
        let guGun = addrArray.length > 1 ? addrArray[1] : "";
        console.log("e.addr", siDo, guGun);
        this.equipmentModel.address.siDo = siDo;
        this.equipmentModel.address.guGun = guGun;
        this.equipmentModel.isAddressSearch = true;
      }

      this.equipmentModel.address.zipcode = e.zip;
      this.equipmentModel.address.city = e.addr;
      this.equipmentModel.address.street = "";
      setTimeout(() => {
        this.$refs.refAddress.$refs.refStreet.focus();
      }, 300);
    },

    uploadComplete(e) {
      console.log(e);
      if (e === null) {
        console.log('첨부파일이 선택되지 않은경우임 -> 다음단계로 이동 처리');
      } else {
        this.equipmentModel.fileLocation = e.Location;
      }
    },
    file_changeDeleteOpt(e) {
      console.log('파일 삭제요청 옵션 : ', e);
    },
    ...mapActions(["loginCheck", "saveDataQuery", "getMe"]),
  },
  computed: {
    ...mapGetters(["isAuthorized"]),
    ...mapState(["me"]),
  },
};
</script>

<style lang="scss">
@media (max-width: 768px) {
  div.equipment-enter {
    .bottom-wrap {
      .v-btn--block {
        display: flex;
        min-width: 100% !important;
        &:last-child {
          margin-left: 0;
          margin-top: 12px;
        }
      }
    }
  }
}
.equipment-enter {
  background: linear-gradient(#ffffff, #ebe5e1);
  padding-bottom: 100px;
  .page-inner {
    width: 65vw;
    max-width: 800px;
    .sub-title {
      span.sub-span1 {
        font-size: 20px;
      }
      span.sub-span2 {
        font-size: 14px;
      }
    }
  }
  .terms-box {
    font-size: 16px;
    letter-spacing: normal;
    max-width: 100%;
    text-align: left;
    padding: 12px;
    margin-bottom: 12px;
    background: #ffffff;
    min-height: 56px;
    height: 160px;
    overflow: auto;
  }
  .bottom-wrap {
    margin: 5rem 0 1rem;
    .v-btn--block {
      display: inline-flex;
      min-width: initial !important;
      width: calc(50% - 6px);
      &:last-child {
        margin-left: 12px;
      }
    }
  }
}
.filebox .upload-name {
  display: inline-block;
  height: 40px;
  padding: 0 10px;
  vertical-align: middle;
  border: 1px solid #dddddd;
  width: 50%;
  color: #999999;
}
.filebox label {
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  vertical-align: middle;
  background-color: #999999;
  cursor: pointer;
  height: 40px;
  margin-left: 10px;
}
.filebox input[type="file"] {
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
}
</style>

<template>
  <div class="map-wrap">
    <div class="map" id="map"></div>
    <!-- <div class="map">
      <img src="@/assets/images/default/map_sample.png" alt="" />
    </div> -->
  </div>
</template>
<script>
export default {
  mounted() {
    this.initMap();
    this.setMarker(this.mapCenter, "ce");
    this.setMarker(this.bands, "1");
    this.setMarker(this.bands2, "2");
  },
  data() {
    return {
      map: null,
      markers: [],
      mapCenter: { lat: 37.5642135, lng: 127.0016985 },
      bands: {
        lat: 37.566535,
        lng: 10.323448,
      },
      bands2: {
        lat: 35.105556,
        lng: 129.04393,
      },
    };
  },
  methods: {
    initMap() {
      this.map = new google.maps.Map(document.getElementById("map"), {
        //getElementById로 map id 속성의 요소를 가져온다.
        center: this.mapCenter, //center로 할 위도, 경도를 지정한다.
        zoom: 14, //zoom size를 지정.
        maxZoom: 20,
        minZoom: 3,
        streetViewControl: true,
        // mapTypeControl: true,
        // fuulscreenControl: true,
        zoomControl: true,
      });
    },
    setMarker(Points, Label) {
      //지도에 마커를 찍는 함수.
      const marker = new google.maps.Marker({
        position: Points,
        map: this.map,
        label: {
          text: Label,
          color: "#FFF",
        },
      });

      this.markers.push(marker);
    },
    clearMaker() {
      for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null);
      }
      this.markers = [];
    },
    _addressToLocation(address) {
      // 마커 삭제
      this.clearMaker();
      let _self = this;
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: address }, function (result, status) {
        console.log(result);
        console.log(result[0].geometry.location);
        console.log(status);
        if (status === "OK") {
          // 맵의 중심 좌표를 설정한다.
          _self.map.setCenter(result[0].geometry.location); //맵의 확대 정도를 설정한다.
          _self.map.setZoom(13); // 맵 마커
          _self.setMarker(result[0].geometry.location, "H");
          // var marker = new google.maps.Marker({
          //   map: resultMap,
          //   position: result[0].geometry.location,
          // }); // 위도
          // console.log("위도(latitude) : " + marker.position.lat()); // 경도
          // console.log("경도(longitude) : " + marker.position.lng());
        } else {
          alert("지오코드가 다음의 이유로 성공하지 못했습니다 : " + status);
        }
      });
    },
  },
};
</script>
<style lang="">
</style>
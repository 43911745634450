exports.tagRules = () => {
    let reData = {
        required: (value) => !!value || "테그를 입력해주세요.",
        counter: (value) => value.length <= 10 || "최대 10자로 입력해주세요",
    }
    return reData;
};
exports.declarationRules = () => {
    let reData = {
        required: (value) => !!value || "신고사유를 입력해주세요.",
        counter: (value) => value.length > 5 || "최소 5자이상으로 입력해주세요",
    }
    return reData;
};
exports.idRules = () => {
    let reData = {
        required: (value) => !!value || "아이디 입력은 필수 입니다.",
        email: (value) => {
            const pattern =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "이메일 타입을 입력해주세요.";
        },
    }
    return reData;
};
exports.emailRules = () => {
    let reData = {
        required: (value) => !!value || "이메일 입력이 필요합니다.",
        counter: (value) => value.length <= 100 || "Max 100 characters",
        email: (value) => {
            const pattern =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "이메일 타입을 입력해주세요.";
        },
    }
    return reData;
};
exports.passwordRules = () => {
    let reData = {
        required: (value) => !!value || "패스워드 입력이 필요합니다.",
        counter: (value) =>
            (value.length >= 4 && value.length <= 100) ||
            "패스워드는 4글자 이상 100글자 이하 이어야 합니다.",
        check: (value) => {
            let num = value.search(/[0-9]/g);
            let eng = value.search(/[a-z]/ig);
            let spe = value.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);
            const pattern = (num < 0 && eng < 0) || (eng < 0 && spe < 0) || (spe < 0 && num < 0);
            // const pattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/;
            console.log(pattern);
            return !pattern ||
                "영문 대/소문자, 숫자, 특수문자 중 2가지 이상 조합해 8~20자로 입력해주세요.";
        }
    }
    return reData;
};
exports.cellRules = () => {
    let reData = {
        required: (value) => !!value || "휴대폰번호 입력은 필수 입니다.",
        counter: (value) => value.length > 10 && value.length < 12 || "올바른 전화번호 11자리를 입력해 주세요.",
        check: (value) => {
            const pattern =
                /^(01[016789]{1})[0-9]{3,4}[0-9]{4}$/g;
            return pattern.test(value) || "올바른 전화번호 11자리를 입력해 주세요.";
        },
    }
    return reData;
};
exports.hospitalRules = () => {
    let reData = {
        required: (value) => !!value || "병원명 입력은 필수 입니다.",
        counter: (value) => value.length > 8 && value.length < 12 || "병원명는 10자이상 이어야 합니다",
        check: (value) => {

        },
    }
    return reData;
};
exports.addressRules = () => {
    let reData = {
        required: (value) => !!value || "주소 입력은 필수 입니다.",
        counter: (value) => value.length > 8 && value.length < 12 || "주소는 10자이상 이어야 합니다",
        check: (value) => {

        },
    }
    return reData;
};
exports.REPNumRules = () => {
    let reData = {
        required: (value) => !!value || "시술샷 수 선택은 필수 입니다.",
        counter: (value) => value.length > 8 && value.length < 12 || "시술샷 수는 10자이상 이어야 합니다",
        check: (value) => {

        },
    }
    return reData;
};
exports.REPCheckDateRules = () => {
    let reData = {
        required: (value) => !!value || "시술일자 선택은 필수 입니다.",
        counter: (value) => value.length > 8 && value.length < 12 || "시술일자는 10자이상 이어야 합니다",
        check: (value) => {

        },
    }
    return reData;
};
exports.nameRules = () => {
    let reData = {
        required: (value) => !!value || "이름 입력은 필수 입니다.",
        counter: (value) => value.length >= 2 && value.length <= 10 || "이름은 2 글자 이상 10 글자 이하 이어야 합니다",
    }
    return reData;
};
exports.nicknameRules = () => {
    let reData = {
        required: (value) => !!value || "닉네임 입력은 필수 입니다.",
        counter: (value) => value.length >= 2 && value.length <= 10 || "닉네임은 2 글자 이상 10 글자 이하 이어야 합니다",
    }
    return reData;
};
exports.birthdayRules = () => {
    let reData = {
        required: (value) => !!value || "생년월일 입력은 필수 입니다.",
        check: (value) => {
            const pattern =
                /^(19[0-9][0-9]|20\d{2}|2100)(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/g;
            return pattern.test(value) || "생년월일이 정확한지 확인해 주세요.";
        },
    }
    return reData;
};
exports.birthtimeRules = () => {
    let reData = {
        counter: (value) => !value ||
            (!!value && value.length == 4) || "시간은 4자리수입니다.",
        check: (value) => {
            const pattern =
                /^(0[0-9]|1[0-9]|2[0-3])(0[0-9]|[1-5][0-9])$/g;
            return pattern.test(value) || "00 ~ 23시, 00 ~ 59분으로 입력해주세요.";
        },
    }
    return reData;
};
exports.daysOfWeek = () => {
    let reData = ["일", "월", "화", "수", "목", "금", "토"];
    return reData;
};
exports.monthName = () => {
    let reData = [
        "1월",
        "2월",
        "3월",
        "4월",
        "5월",
        "6월",
        "7월",
        "8월",
        "9월",
        "10월",
        "11월",
        "12월",
    ];
    return reData;
};
exports.s3Config = () => {
    let reData = {
        S3_BUCKET: 'zplabpublic',
        S3_REGION: 'ap-northeast-2',
        S3_POOLID: 'ap-northeast-2:cae44641-b6f1-4893-9615-3ef9523f732a'
    }
    return reData;
};

exports.visitCheckDateRules = () => {
    let reData = {
        required: (value) => !!value || "방문희망일자 선택은 필수 입니다.",
        counter: (value) => value.length > 8 && value.length < 12 || "방문희망일자는 10자이상 이어야 합니다",
        check: (value) => {

        },
    }
    return reData;
};

exports.textLessThan30Rules = () => {
    let reData = {
        counter: (value) => value.length <= 30 || "최대 30자로 입력해주세요",
    }
    return reData;
};

exports.addressDetailRules = () => {
    let reData = {
        required: (value) => !!value || "상세주소를 입력해주세요.",
    }
    return reData;
};

<template>
  <div>
    <div v-if="status === 'login'">
      <!-- 로그인 -->
      <LoginForm
        @onClickSignInEmit="onClickSignIn"
        @moveToEmit="moveTo"
        @onKakaoSignUpEmit="onKakaoSignUpEmitHandler"
      />
    </div>

    <!-- 회원가입 -->
    <div class="signup" v-if="status === 'signup'">
      <!-- 약관동의 -->
      <SignUpAgree
        v-if="step === 'agree'"
        :userData="userData"
        @clickNextEmit="nextSignup"
        @clickCancel="status = 'login'"
      />

      <!-- 프로필 입력 -->
      <UserProfile
        pageName="signup"
        v-if="step === 'profile'"
        ref="refSignUpProfile"
        :userData="userData"
        @clickCancel="nextSignup('agree')"
        @clickSummitEmit="onClickSignUp"
      />
    </div>

    <!-- 확인팝업 -->
    <confirmDialog
      ref="ConfirmDialog"
      title="회원가입"
      contentText="회원가입 하겠습니까?"
      :useBtnOK="true"
      :useBtnCancel="true"
      useBtnOKLabel="예"
      useBtnCancelLabel="아니오"
      @clickOk="confirmDialog_clickOKHandler"
    />

    <!-- 중복팝업 -->
    <CommonDialog
      ref="CommonDialog"
      @clickOk="duplicateDialog_clickOKHandler"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";

import LoginForm from "./LoginForm.vue";
import SignUpAgree from "./signUp/SignUpAgree.vue";
import confirmDialog from "@/components/common/dialog/ConfirmDialog.vue";
import CommonDialog from "@/components/common/dialog/CommonDialog.vue";
import UserProfile from "./signUp/UserProfile.vue";

const modelService = require("@/utils/dataModel.js");

export default {
  components: {
    LoginForm,
    SignUpAgree,
    confirmDialog,
    CommonDialog,
    UserProfile,
  },
  name: "SignUpForm",
  data() {
    return {
      // status: "findPW",
      // status: "signup",
      status: "login",
      step: "agree",
      pwShow: false,
      userData: modelService.userSignUpModel(),
      checkedData: null,
    };
  },
  mounted() {
    // this.userData.loginId = "";
    // this.userData.password = "";
  },
  methods: {
    onKakaoSignUpEmitHandler(kakaoAccount) {
      // kakao 회원가입인지 체크
      console.log("kakaoAccount", kakaoAccount);
      // return;
      //1.회원가입(회원아이디: email || 회원비번:kakao_email) -> 회원가입페이지로
      this.userData.loginId = kakaoAccount.email;
      this.userData.password = "kakao_email_" + kakaoAccount.email;
      this.userData.rePassword = "kakao_email_" + kakaoAccount.email;
      this.userData.email = kakaoAccount.email;
      this.userData.signupPath = "kakao";
      this.userData.name =
        kakaoAccount.name === undefined ? "" : kakaoAccount.name;
      // 휴대폰 번호 처리
      this.userData.cell =
        kakaoAccount.phone_number === undefined
          ? ""
          : kakaoAccount.phone_number;
      this.userData.appPush = false;
      this.userData.marketing = false;
      this.userData.agreePolicy = true;
      this.userData.agreeTerms = true;
      this.userData.ci = kakaoAccount.ci;

      // 추가 병원명 / 전공과목 추가로 받기 처리(회원가입페이지로 이동)
      this.status = "signup";
      this.step = "profile";

      // sms 인증 확인 처리
      this.userData.checkSms = true;
      this.userData.checkedSms = true;

      // 회원가입 처리(바로가입)
      // this.$emit("signup", this.userData);
    },
    onClickSignUp() {
      const content = "회원가입하시겠습니까?";
      this.$refs.ConfirmDialog.open(content);
    },
    confirmDialog_clickOKHandler() {
      this.$emit("signup", this.userData);
    },
    onClickSignIn(e) {
      this.$emit("signin", e);
    },
    nextSignup(to) {
      this.step = to;
      this.stepValue(to);
    },
    duplicateDialog_clickOKHandler() {
      window.location.href = "/";
    },
    moveTo(to) {
      this.status = to;
      console.log("checked data : ", to);
    },
    ...mapActions(["stepValue"]),
  },
};
</script>
<style lang="scss">
</style>
<template>
  <v-dialog v-model="dialog" max-width="500px" content-class="common-dialog">
    <v-card>
      <!-- 2개이상 -->
      <v-card-text>
        <v-carousel :show-arrows="false" height="" cycle interval="4000">
          <v-carousel-item v-for="(item, index) in data" :key="index">
            <a :href="item.contents" :target="item.linkType">
              <img :src="item && item.detailTextImg" alt="" />
            </a>
          </v-carousel-item>
        </v-carousel>
      </v-card-text>
      <!-- 1개일때(old) -->
      <!-- <v-card-text v-if="data && data.detailTextImg">
        <a
          v-if="data && data.contents"
          :href="data.contents"
          :target="data.linkType"
          ><img :src="data && data.detailTextImg" alt=""
        /></a>
        <img v-else :src="data && data.detailTextImg" alt="" />
      </v-card-text> -->

      <!-- 오늘 안보이기 -->
      <v-card-actions>
        <v-checkbox
          v-model="blockToday"
          label="오늘 하루 보지 않기"
          @click="checkModalToday"
        >
        </v-checkbox>
        <v-btn color="primary" depressed @click="ok"> 닫기 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import cookie from "js-cookie";
export default {
  data() {
    return {
      dialog: false,
      blockToday: false,
      data: null,
    };
  },
  methods: {
    open(data) {
      this.dialog = true;
      this.data = data;
    },
    ok() {
      this.dialog = false;
    },
    checkModalToday() {
      this.setCookie("homePopup", "Y", 1);
      this.dialog = false;
    },
    setCookie(key, value, expireDays) {
      cookie.set(key, value, {
        expires: expireDays,
        path: "",
      });
    },
  },
};
</script>

<style lang="scss">
.v-dialog.common-dialog {
  .v-card {
    .v-card__text {
      padding: 1.2rem 1rem 1rem;
      img {
        width: 100%;
      }
    }
    .v-card__actions {
      position: relative;
      padding: 1rem 1rem 1.5rem;
      justify-content: flex-end;
      .v-input--checkbox {
        position: absolute;
        left: 20px;
      }
    }
  }
}
</style>
<template>
  <div class="terms">
    <h4>개인정보의 수집 및 이용에 대한 동의</h4>
    <br />
    솔타메디칼코리아 유한회사(이하 “회사”)는 개인정보 보호법 등 관계 법령에 따라 개인정보를 안전하게 처리하고 있습니다. 회사는 귀하께 솔타메디칼코리아 유한회사의 써마지®FLX 브랜드 사이트(이하 “웹사이트”)에
    대한 이용권한을 부여해드리고 그에 따른 서비스를 제공해드리고자, 아래와 같이 귀하의 개인정보를 수집 이용할 예정인바, 이에 귀하의 동의를 요청드립니다.<br />
    <br />
    <ul>
      <li>
        개인정보의 수집·이용 목적: 솔타메디칼코리아 유한회사의 써마지<sup>®</sup>FLX 브랜드 사이트 통합 회원가입 및 관리(솔타메디칼코리아 유한회사 통합 회원으로 가입하시는 경우 솔타메디칼코리아 유한회사의 써마지<sup>®</sup>FLX
        브랜드 사이트 <b>웹사이트, 모바일 앱, 카카오채널 (구 카카오플러스친구) 등(이하 “웹사이트 등”)</b> 솔타메디칼코리아 유한회사에서 제공하는 다양한 서비스를 별도 회원 가입 없이 이용하실 수 있습니다), 회원제
        서비스 이용에 따른 본인 여부 및 가입자격 확인, 중복가입·부정이용·비인가사용 등의 방지, 웹사이트 등을 통한 솔타메디칼코리아 유한회사의 써마지<sup>®</sup>FLX <b>서비스 제공, 웹사이트 등 서비스 이용에 따른 민원사항의
        처리 및 고지사항의 전달, 의학정보 문의 대응 및 의학정보 전달, 학술행사에 대한 안내, 뉴스레터의 발송, 회원의 서비스 이용현황 관련 분석</b>
      </li>
      <li>
        개인정보의 보유 이용기간: <u><b>회원탈퇴 시까지</b></u>. 다만, 관계법령의 규정에 따라 개인정보를 보존하여야 하는 경우에는 그러한 규정에 따라 보관됩니다.
      </li>
      <li>
        귀하는 위와 같은 개인정보의 수집 및 이용에 대한 동의를 거부할 수 있습니다. 다만, 이에 동의하지 않을 경우 위 웹사이트 가입이 제한될 수 있습니다.
      </li>
    </ul>
    <!-- [솔타메디칼코리아 유한회사](이하 ‘솔타메디칼’)는 써마지<sup>®</sup> 정품인증 및 시술 관련 이벤트 진행을 위하여 아래와 같이 개인정보를 수집 및 이용하고자 합니다. <br />
    <br />
    <b>1. 개인정보의 수집 및 이용</b><br />
    <b>
      1) 개인정보 수집 항목: 이름, 휴대폰번호, 시술 병원 명, 주소, 시술 병원 소재지, 시술 샷수, 시술 일자<br />
      2) 수집∙이용 목적: 솔타메디칼 써마지® 정품인증 및 시술 관련 이벤트 진행 <br />
      3) 보유 및 이용기간: 정품인증 확인 후 최대 5년간(정품인증 확인 4년 경과 후, 해당 연도 12월 31일 일괄 파기) <br />
    </b>
    <br />
    <b>2. 개인정보의 위탁 고지</b><br />
    <b>
      1) 수탁 업체 : ㈜대홍기획, 지피랩 주식회사(ZP Lab Co., Ltd)<br />
      2) 위탁 항목 : 이름, 휴대폰번호, 주소<br />
      3) 위탁 업무 : 경품 발송, 고객센터 CS 대응 및 처리<br />
      4) 보유 및 이용기간 : 위탁업무기간 종료 후 파기.<br />
    </b>
    솔타메디칼은 위와 같이 고객의 정보를 수집∙이용 및 위탁하고자 하며, 동의한 업무 이외의 목적으로 고객의 정보를 이용하지 않습니다. -->


  </div>
</template>

<script>
export default {};
</script>

<style></style>
<template>
  <v-dialog v-model="dialog" max-width="500px" content-class="common-dialog">
    <v-card>
      <v-card-title>
        <h5>문의등록 시 입력한 비밀번호를 입력 해주세요.</h5>
      </v-card-title>
      <v-card-text>
        <v-text-field
          solo
          height="52"
          label="비밀번호"
          required
          ref="password"
          type="password"
          v-model="password"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn depressed large @click="cancel"> 취소 </v-btn>
        <v-btn color="primary" depressed large @click="ok"> 확인 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      password: "",
      item: null,
    };
  },
  methods: {
    open(e) {
      this.item = e;
      this.password = "";
      this.dialog = true;
      setTimeout(() => {
        this.$refs.password.focus();
      }, 500);
    },
    cancel() {
      this.dialog = false;
      // this.$emit("clickCancel");
    },
    ok() {
      // 필수
      if (this.password === "") {
        this.$helper.showTostMessage(
          "top",
          "비밀번호를 입력해 주세요.",
          "error"
        );
        this.$refs.password.focus();
        return;
      }
      this.item.ipk;
      this.item.password = this.password;
      this.item.endpoint = "equipment/password";
      this.getDataQuery(this.item).then((res) => {
        console.log("", res);

        if (res === undefined) return;
        if (!res) {
          this.$helper.showTostMessage(
            "top",
            "비밀번호를 확인해 주세요",
            "error"
          );
          this.$refs.password.focus();
          return;
        }

        this.dialog = false;
        this.$emit("clickOk", this.item);
      });
    },
    ...mapActions(["getDataQuery"]),
  },
};
</script>

<style lang="scss">
.v-dialog.common-dialog {
  .v-card {
    .v-card__text {
      padding: 1.2rem 1rem 1rem;
    }
    .v-card__actions {
      padding: 1rem 1rem 1.5rem;
    }
  }
}
</style>
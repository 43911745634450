<template>
  <!-- <Dialog_test /> -->
  <!-- <Script_test /> -->
  <!-- <SnackBar_test /> -->
  <!-- <VAerts_test /> -->
  <!-- <Common_S3Upload
    ref="S3FileUploader"
    uploadType="profile"
    accept="image/png, image/jpeg, image/bmp, image/gif"
    uploadDir="pets"
    fileHeader="profileImg"
    defImage="profile_pet"
    :fileInfo="fileInfo"
    @uploadStarted="uploadStarted"
    @uploadComplete="uploadComplete"
  /> -->
  <!-- <AddressSearchDaum ref="refAddress" /> -->
  <!-- <Dialog_test2 ref="test" @searchCompleteUse="searchCompleteUseHandler" /> -->
  <!-- <Me_danal /> -->
  <!-- <kakaoLogin_test /> -->

  <!-- <fileUpload_test /> -->
  <!-- <tag_test /> -->

  <!-- 구글맵 -->
  <!-- <FCMap /> -->

  <!-- vod test -->
  <vod_test />
  <!-- <SignUpCellCheck /> -->
</template>
<script>
import AddressSearchDaum from "@/components/common/AddressSearchDaum.vue";
import FCMap from "@/components/thermage/findclinic/comp/FCMap.vue";
import Common_S3Upload from "../common/Common_S3Upload.vue";
import Dialog_test from "./testComp/Dialog_test.vue";
import Dialog_test2 from "./testComp/Dialog_test2.vue";
import Script_test from "./testComp/Script_test.vue";
import SnackBar_test from "./testComp/SnackBar_test.vue";
import VAerts_test from "./testComp/VAerts_test.vue";
import Me_danal from "./testComp/Me_danal.vue";
import fileUpload_test from "./testComp/fileUpload_test.vue";
import KakaoLogin_test from "./testComp/kakaoLogin_test.vue";
import Tag_test from "./testComp/tag_test.vue";
import vod_test from "./testComp/vod_test.vue";
import SignUpCellCheck from "@/components/thermage/home/signUp/SignUpCellCheck.vue";

export default {
  components: {
    Dialog_test,
    Dialog_test2,
    Script_test,
    SnackBar_test,
    VAerts_test,
    Common_S3Upload,
    AddressSearchDaum,
    Me_danal,
    KakaoLogin_test,
    fileUpload_test,
    Tag_test,
    FCMap,
    SignUpCellCheck,
    vod_test,
  },
  data() {
    return {
      // dialog: false,
      fileInfo: null,
    };
  },
  mounted() {
    // this.$refs.test.showModal();
  },
  methods: {
    uploadStarted() {
      // this.userData = JSON.parse(getUserDataFromCookie());
      // this.isLoading = true;
      // this.$emit('isLoading', this.isLoading);
    },
    uploadComplete(e) {
      this.fileInfo = null;
      if (e !== null) {
        // console.log('첨부파일이 선택되지 않은경우임 -> 다음단계로 이동 처리', e);
        // this.saveReqData.photo_url = e.Location;
        // const photo_filenameA = e.Key.split('/');
        // this.saveReqData.photo_filename = photo_filenameA[photo_filenameA.length - 1];
        console.log("", e);
        this.fileInfo = {
          fullURL: e.Location,
          filename: e.key,
        };
      }
      // this.saveMeal();
      // this.isLoading = false;
      // this.$emit('isLoading', this.isLoading);
    },
    searchCompleteUseHandler(e) {
      console.log("", e);
    },
  },
};
</script>
<style lang="">
</style>
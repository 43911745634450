<template>
  <div class="terms">
    <h4>개인정보 수집 및 이용에 대한 동의</h4>
    <br />
    솔타메디칼코리아 유한회사 (이하 ‘솔타메디칼’)는 고객의견함을 운영하기 위하여 아래와 같이 개인정보를 수집 및 이용하고자 합니다. <br />
    <br />
    •수집 항목: 성명, 이메일주소, 비밀번호, 문의 내용 및 기타 고객이 직접 입력한 내용 <br />
    •수집 및 이용목적: 고객문의 접수, 처리결과 안내 <br />
    •보유 및 이용기간: 원칙적으로, <strong>개인정보 수집 및 이용목적이 달성될 때까지 해당 정보를 보유</strong>하고, 달성된 후에는 해당 정보를 지체 없이 파기합니다. 단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우
    관계법령에서 정한 일정 기간 동안 개인정보를 보관할 수 있습니다.<br />
  </div>
</template>
  
<script>
export default {};
</script>
  
<style>
.terms li {
  list-style: disc;
}</style>
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-profile"},[_c('div',{staticClass:"section-inner"},[_c('div',{staticClass:"section-header"},[_c('img',{staticClass:"top-logo",attrs:{"src":require("@/assets/images/common/thermage_logo.png"),"alt":"app_logo"}}),_c('p',{staticClass:"sub-title"},[_vm._v(" "+_vm._s(this.$route.path == "/login" ? "가입정보를 입력해주세요." : "")+" ")])]),_c(VForm,{ref:"form"},[_c(VTextField,{ref:"refName",attrs:{"label":"성함","solo":"","height":_vm.formHeight,"required":"","rules":[_vm.nameRules.required, _vm.nameRules.counter],"disabled":_vm.userData.signupPath === 'kakao'},model:{value:(_vm.userData.name),callback:function ($$v) {_vm.$set(_vm.userData, "name", $$v)},expression:"userData.name"}}),(_vm.userData.signupPath !== 'kakao')?_c('SignUpCellCheck',{attrs:{"userData":_vm.userData}}):_vm._e(),_c(VTextField,{attrs:{"solo":"","label":"아이디(이메일)","height":_vm.formHeight,"required":"","rules":[_vm.emailRules.required, _vm.emailRules.email],"disabled":_vm.userData.signupPath === 'kakao'},model:{value:(_vm.userData.loginId),callback:function ($$v) {_vm.$set(_vm.userData, "loginId", $$v)},expression:"userData.loginId"}}),_c(VTextField,{attrs:{"solo":"","height":_vm.formHeight,"label":"비밀번호","required":"","type":"password","rules":[
          _vm.passwordRules.required,
          _vm.passwordRules.counter,
          _vm.passwordRules.check ],"disabled":_vm.userData.signupPath === 'kakao'},model:{value:(_vm.userData.password),callback:function ($$v) {_vm.$set(_vm.userData, "password", $$v)},expression:"userData.password"}}),_c(VTextField,{attrs:{"solo":"","height":_vm.formHeight,"label":"비밀번호 확인","required":"","type":"password","rules":[
          _vm.passwordRules.required,
          _vm.passwordRules.counter,
          _vm.passwordConfirmationRule ],"disabled":_vm.userData.signupPath === 'kakao'},model:{value:(_vm.userData.rePassword),callback:function ($$v) {_vm.$set(_vm.userData, "rePassword", $$v)},expression:"userData.rePassword"}}),_c(VTextField,{ref:"refHospital",attrs:{"solo":"","label":"소속","height":_vm.formHeight,"required":""},model:{value:(_vm.userData.hospital),callback:function ($$v) {_vm.$set(_vm.userData, "hospital", $$v)},expression:"userData.hospital"}}),_c(VSelect,{ref:"refMajors",attrs:{"solo":"","items":_vm.itemsSpecialty,"label":"전공과","height":_vm.formHeight,"required":""},model:{value:(_vm.userData.majors),callback:function ($$v) {_vm.$set(_vm.userData, "majors", $$v)},expression:"userData.majors"}}),_c('div',{staticClass:"bottom-wrap"},[_c(VBtn,{attrs:{"color":"primary","x-large":"","depressed":"","block":""},on:{"click":_vm.clickSummit}},[_vm._v(_vm._s(_vm.pageName === "mypage" ? "수정하기" : "가입하기"))]),_c(VBtn,{attrs:{"color":"","x-large":"","depressed":"","block":""},on:{"click":_vm.clickCancel}},[_vm._v("취소")])],1),(_vm.pageName === 'mypage')?_c(VBtn,{attrs:{"x-large":"","text":"","block":""},on:{"click":_vm.clickWithdrawal}},[_vm._v(" 탈퇴하기 ")]):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="section-inner find-enter">
    <div class="section-title">
      <img
        class="top-logo"
        src="@/assets/images/common/thermage_logo.png"
        alt="thermage_logo"
      />
      <p class="sub-title">
        비밀번호 찾기를 위해 이메일 입력 및 휴대폰번호 인증을 진행해주세요.
      </p>
    </div>

    <v-form ref="form">
      <v-text-field
        label="성함"
        ref="refName"
        solo
        height="52"
        required
        v-model="userData.name"
        :rules="[nameRules.required, nameRules.counter]"
      ></v-text-field>

      <!-- 휴대폰 체크 -->
      <SignUpCellCheck :userData="userData" />

      <div class="bottom-wrap">
        <v-btn depressed color="primary" x-large block @click="findPW"
          >확인</v-btn
        >
        <v-btn depressed x-large block @click="$router.push('/login')"
          >취소</v-btn
        >
      </div>
    </v-form>

    <!-- 중복팝업 -->
    <CommonDialog ref="CommonDialog" @clickOk="CommonDialog_clickOKHandler" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SignUpCellCheck from "@/components/thermage/home/signUp/SignUpCellCheck.vue";
import CommonDialog from "@/components/common/dialog/CommonDialog.vue";
const rulesService = require("@/utils/rules.js");
const modelService = require("@/utils/dataModel.js");

export default {
  components: { SignUpCellCheck, CommonDialog },
  data() {
    return {
      userData: modelService.userSignUpModel(),
      nameRules: rulesService.nameRules(),
      cellRules: rulesService.cellRules(),
    };
  },
  methods: {
    findPW() {
      // 필수
      const DT = this.userData;
      const RF = this.$refs;

      if (!DT.name) {
        this.$helper.showTostMessage("top", "성함을 입력해주세요", "info");
        RF.refName.focus();
        return;
      }

      if (!DT.checkSms) {
        this.$helper.showTostMessage(
          "top",
          "[인증번호 받기]를 선택해주세요",
          "info"
        );
        return;
      }

      if (!DT.checkedSms) {
        this.$helper.showTostMessage(
          "top",
          "[인증완료]를 선택해주세요",
          "info"
        );
        return;
      }

      if (!this.$refs.form.validate()) return;

      // 확인
      this.userData.endpoint = "checkIdPw";
      this.userData.checkType = "id";
      this.saveDataPreLoginQuery(this.userData).then((res) => {
        if (res === undefined) return;
        console.log("", res);

        if (res === null || res === "") {
          this.$helper.showTostMessage(
            "top",
            "가입된 회원정보가 없습니다.(카카오씽크가입 확인바랍니다.)",
            "error"
          );
          return;
        }

        if (!res.isHcp) {
          this.$helper.showTostMessage(
            "top",
            "[" +
              res.loginId +
              "]아이디는 회원가입 승인 대기중입니다\n관리자에게 문의하세요.",
            "info"
          );
          return;
        }

        this.userData.userId = res.userId;
        const content =
          "가입된 이메일[" +
          res.loginId +
          "]로 초기화된 비밀번호를 받으시겠습니까?";
        this.$refs.CommonDialog.open(content);
        // this.$emit("emitFindPW");
      });
    },
    CommonDialog_clickOKHandler() {
      this.userData.endpoint = "resetPwWithEmail";
      this.userData.checkType = "password";
      this.saveDataPreLoginQuery(this.userData).then((res) => {
        if (res === undefined) return;
        console.log("", res);
        this.$emit("emitFindPW");
      });
    },
    ...mapActions(["saveDataPreLoginQuery"]),
  },
};
</script>

<style lang="scss">
.find-enter {
  .row {
    margin: 0;
    .col {
      padding: 0;
      .v-btn {
        min-width: calc(100% - 10px);
        padding: 0;
        margin-left: 10px;
      }
    }
  }
}
@media (max-width: 768px) {
  div.find-enter {
    .row-cell {
      .col-9 {
        flex: 0 0 60%;
        max-width: 60%;
      }
      .col-3 {
        flex: 0 0 40%;
        max-width: 40%;
      }
    }
  }
}
</style>
<template>
  <div class="page genuine-product">
    <div class="page-inner">
      <div class="page-title">
        <h1 class="main-title primary--text">정품팁이란? <sup>1</sup></h1>
        <p class="sub-title">
          써마지<sup>®</sup> 시술 팁은 <b>한 분에게만 제공</b>되는
          <b>1회용 의료기기</b> 로써 시술 전
          <b>식품의약품안전처의 허가를 받은 정품팁</b>을 사용하는지 반드시
          확인하셔야 합니다. <br />
          <br />
          허가받지 않은
          <b>불법 의료기기를 사용한 시술의 효과와 안전성은 보증할 수
            없습니다.</b>
        </p>
      </div>
      <div class="page-content">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/3YdfGLd526s?si=wJ1W6ohqju3T6pDX"
          title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
      </div>
    </div>
    <div class="reference">
      <div class="section-inner">
        <b>Reference</b><br />
        1.식품의약품안전처 허가증(2018) 써마지트리트먼트팁. 수인17-4032.<br />
        •Thermage FLX™ 제품은 "의료기기" 이며, "사용상의 주의사항"과 "사용방법"을 잘 읽고 사용하십시오<br />
        •심의확인번호: 조합 2024-06-028(유효기간 2027.02,20)
        <p class="part-number mt-6">KTMG-20231012-027, KTMG-20240620-088</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.page.genuine-product {
  background: linear-gradient(#ffffff, #ebe5e1);

  .page-content {
    position: relative;
    padding-top: 56.3%;
    width: 100%;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
<template>
  <div class="nav-item menu">
    <div id="brand" class="include-sub">
      <div class="menu-item" @click="expendItem('brand')">
        <div class="group-title" @click="goTo('/brand')">
          써마지<sup>®</sup>
        </div>
        <v-btn icon class="mo btn-expend">
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </div>

      <div class="sub-list">
        <div class="sub-item brand" @click="goTo('/brand')">
          써마지<sup>®</sup>란?
        </div>
        <div class="sub-item tips" @click="goTo('/brand/tips')">
          시술팁 소개
        </div>
        <div class="sub-item principle" @click="goTo('/brand/principle')">
          콜라겐 재생 원리
        </div>
        <div class="sub-item technology" @click="goTo('/brand/technology')">
          기술 & 허가 정보
        </div>
      </div>
    </div>

    <div class="menu-item faq">
      <div @click="goTo('/faq')">써마지<sup>®</sup> FAQ</div>
    </div>

    <div id="certify" class="include-sub">
      <div class="menu-item" @click="expendItem('certify')">
        <div class="group-title" @click="goTo('/certify')">정품 인증</div>
        <v-btn icon class="mo btn-expend">
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </div>
      <div class="sub-list">
        <div class="sub-item certify" @click="goTo('/certify')">정품 인증</div>
        <div
          class="sub-item genuine_product"
          @click="goTo('/certify/genuine_product')"
        >
          정품팁이란?
        </div>
      </div>
    </div>

    <div class="menu-item find" @click="goTo('/find')">병원 찾기</div>

    <div id="library" class="include-sub">
      <div class="menu-item" @click="expendItem('library')">
        <div class="group-title" @click="goTo('/archive')">자료실</div>
        <v-btn icon class="mo btn-expend">
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </div>

      <div class="sub-list">
        <div class="sub-item archive" @click="goTo('/archive')">
          의학 자료실
        </div>
        <div class="sub-item archive2" @click="goTo('/archive2')">
          마케팅 자료실
        </div>
        <div class="sub-item newsLetter" @click="goTo('/newsLetter')">
          뉴스레터
        </div>
      </div>
    </div>
    <div id="cs" class="include-sub">
      <div class="menu-item" @click="expendItem('cs')">
        <div class="group-title" @click="goTo('/cs/inquiry')">고객 지원</div>
        <v-btn icon class="mo btn-expend">
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </div>

      <div class="sub-list">
        <div class="sub-item inquiry" @click="goTo('/cs/inquiry')">
          소비자 문의
        </div>
        <div class="sub-item equipment_enter" @click="goTo('/cs/equipment_enter')">
          장비 A/S문의
        </div>
        <div class="sub-item notice" @click="goTo('/cs/notice')">공지사항</div>
      </div>
    </div>

    <!-- 마이페이지 : 로그인 시에만 노출  -->

    <div id="mypage" class="include-sub">
      <div class="menu-item" @click="expendItem('mypage')">
        <div class="group-title" @click="goTo('/mypage')">마이페이지</div>
        <v-btn icon class="mo btn-expend">
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </div>

      <div class="sub-list">
        <div class="sub-item mypage" @click="goTo('/mypage')">마이페이지</div>
        <div class="sub-item" v-if="isAuthorized" @click="logout">로그아웃</div>
        <div class="sub-item" v-else @click="goTo('/login')">로그인</div>
        <div class="sub-item equipment" @click="goTo('/cs/equipment')">A/S 접수이력</div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  props: {
    isAuthorized: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  watch: {
    $route: "currentMenu",
  },
  mounted() {
    this.currentMenu();
  },
  methods: {
    isMobile() {
      return window.matchMedia("(pointer:coarse)").matches;
    },
    currentMenu() {
      console.log(this.$route.path);
      const path = this.$route.path;
      $(".menu-item").removeClass("current");
      $(".sub-item").removeClass("current");
      switch (path) {
        case "/brand":
          // 써마지®란?
          $("#brand .menu-item").addClass("current");
          $(".sub-item.brand").addClass("current");
          break;

        case "/brand/tips":
          // 시술 팁 소개
          $("#brand .menu-item").addClass("current");
          $(".sub-item.tips").addClass("current");
          break;

        case "/brand/principle":
          //콜라겐 재생 원리
          $("#brand .menu-item").addClass("current");
          $(".sub-item.principle").addClass("current");
          break;

        case "/brand/technology":
          // 기술 & 허가 정보
          $("#brand .menu-item").addClass("current");
          $(".sub-item.technology").addClass("current");
          break;

        case "/faq":
          // 써마지® FAQ
          $(".menu-item.faq").addClass("current");
          break;

        case "/certify":
          // 정품 인증
          $("#certify .menu-item").addClass("current");
          $(".sub-item.certify").addClass("current");
          break;

        case "/certify/genuine_product":
          // 정품 팁이란?
          $("#certify .menu-item").addClass("current");
          $(".sub-item.genuine_product").addClass("current");
          break;

        case "/find":
          // 병원 찾기
          $(".menu-item.find").addClass("current");
          break;

        case "/archive":
          // 의학 자료실
          $("#library .menu-item").addClass("current");
          $(".sub-item.archive").addClass("current");
          break;

        case "/archive2":
          // 마케팅 자료실
          $("#library .menu-item").addClass("current");
          $(".sub-item.archive2").addClass("current");
          break;

        case "/newsLetter":
          // 뉴스레터
          $("#library .menu-item").addClass("current");
          $(".sub-item.newsLetter").addClass("current");
          break;

        case "/cs/inquiry":
          // 소비자 문의
          $("#cs .menu-item").addClass("current");
          $(".sub-item.inquiry").addClass("current");
          break;

        case "/cs/equipment_enter":
          // 장비 A/S문의
          $("#cs .menu-item").addClass("current");
          $(".sub-item.equipment_enter").addClass("current");
          break;

        case "/cs/notice":
          // 공지사항
          $("#cs .menu-item").addClass("current");
          $(".sub-item.notice").addClass("current");
          break;

        case "/mypage":
          // 마이페이지
          $("#mypage .menu-item").addClass("current");
          $(".sub-item.mypage").addClass("current");
          break;
      }
    },

    expendItem(name) {
      // console.log(name);
      const className = $(`#${name}`).attr("class");
      const isActive = className.split(" ")[1] === "active";

      if (isActive) {
        // console.log(isActive);
        $(`#${name}`).removeClass("active");
      } else {
        $(`#${name}`).addClass("active");
      }
    },
    goTo(path) {
      this.$emit("pageMoveEmit", path);
    },
    logout() {
      // console.log("test");
      this.$emit("logoutEmit");
    },
  },
};
</script>

<style lang="scss">
// @media (max-width: 768px) {
//   div.top-nav {
//     padding: 1rem;
//     height: 100vh;
//     left: 100vw;
//     background: #ffffff;
//     &:hover {
//       height: 100vh;
//     }
//     &.on {
//       left: 0;
//       height: 100vh;
//       .mobile-btn {
//         position: absolute;
//         transition-delay: all 1s;
//       }
//     }
//     &.off {
//       left: 100vw;
//       .mobile-btn {
//         position: fixed;
//       }
//     }
//     &.bg {
//       .mobile-btn {
//         .v-btn {
//           background-color: $chrome-background-color;
//           box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
//         }
//       }
//     }
//     .mobile-btn {
//       display: block;
//       position: fixed;
//       top: 0;
//       right: 0;
//       padding: 1rem;
//       .v-btn {
//         background-color: $chrome-background-color;
//         transition: all 0.4s;
//       }
//     }
//     .nav-inner {
//       flex-direction: column;
//       .logo {
//         margin-top: 0;
//         padding: 0 0 1rem;
//         img {
//           width: 65px;
//         }
//       }
//       .right {
//         margin-top: 0;
//         padding: 2rem 0 0;
//         img {
//           width: 120px;
//         }
//       }
//       .menu {
//         flex-direction: column;
//         padding: 1rem 0;
//         border-bottom: 1px solid #ddd;
//         width: 100%;
//         // align-items: flex-start;
//         .menu-item {
//           position: relative;
//           padding: 1rem;
//           width: 100%;
//           margin: 0;
//           &.current {
//             div {
//               position: relative;
//               display: inline-block;
//               &::after {
//                 content: "";
//                 display: block;
//                 position: absolute;
//                 height: 2px;
//                 width: calc(100% + 20px);
//                 background: $point-color;
//                 margin-top: 3px;
//                 margin-left: -8px;
//               }
//             }
//             position: relative;
//             &::after {
//               display: none;
//             }
//           }
//         }
//         .include-sub {
//           display: block;
//           width: 100%;
//           // justify-content: flex-start;
//           &.active {
//             .btn-expend {
//               transform: rotate(180deg);
//             }
//             .sub-list {
//               display: block;
//             }
//           }
//           .menu-item {
//             width: calc(100% - 40px);
//             .btn-expend {
//               display: block;
//               position: absolute;
//               top: 10px;
//               right: -40px;
//             }
//           }

//           .sub-list {
//             display: none;
//             position: relative;
//             text-align: left;
//             margin: 15px 0 40px;
//             // .sub-item {
//             //   font-weight: 400;
//             // }
//           }
//         }
//       }
//     }
//   }
// }
// .top-nav {
//   position: fixed;
//   background: #ffffff;
//   width: 100%;
//   padding: 1rem 3rem;
//   z-index: 100;
//   transition: 0.6s;
//   box-shadow: 0px 0 20px 0 #ffffff;
//   height: 70px;
//   overflow: hidden;
//   &:hover {
//     height: 240px;
//     box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
//   }
//   &.bg {
//     box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
//   }
//   .mobile-btn {
//     display: none;
//   }
//   .nav-inner {
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     align-items: center;
//     .logo {
//       margin-top: -10px;
//       cursor: pointer;
//       img {
//         width: 85px;
//       }
//     }
//     .right {
//       margin-top: -10px;
//       cursor: pointer;
//       img {
//         width: 160px;
//       }
//     }
//     .menu {
//       display: flex;
//       flex-direction: row;
//       justify-content: space-around;
//       align-items: center;
//       width: calc(100% - 265px);
//       max-width: 1000px;
//       .menu-item {
//         margin: 0 1.5rem;
//         font-weight: bold;
//         cursor: pointer;
//         &:hover {
//           color: $primary-color;
//         }
//         &.current {
//           position: relative;
//           &::after {
//             content: "";
//             display: block;
//             position: absolute;
//             height: 2px;
//             width: calc(100% + 20px);
//             background: $point-color;
//             margin-top: 3px;
//             margin-left: -8px;
//           }
//         }
//       }
//       #brand {
//         .menu-item.current::after {
//           margin-left: -11px;
//         }
//       }
//       .include-sub {
//         position: relative;
//         display: flex;
//         justify-content: center;
//         align-items: flex-start;

//         .btn-expend {
//           display: none;
//         }
//         .sub-list {
//           position: absolute;
//           margin: 30px 0 1rem;
//           width: max-content;
//           // left: -47%;
//           text-align: center;
//           .sub-item {
//             padding: 0 1rem;

//             cursor: pointer;
//             margin: 1rem 0;
//             &:hover {
//               color: $primary-color;
//             }
//             &.current {
//               font-weight: bold;
//             }
//           }
//         }
//       }
//     }
//   }
// }
</style>

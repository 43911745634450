<template>
  <div class="list-grid">
    <div class="list-item" v-for="(item, i) in noticeList" :key="i">
      <div class="thumbnail" @click="clickItem(item, true)">
        <div v-if="!item.detailTextImg" class="image no-image">
          <v-icon x-large>mdi-camera</v-icon>
          <h1>NO IMAGE</h1>
        </div>

        <img v-else class="image" :src="item.detailTextImg" alt="" />
      </div>
      <div class="text-wrap">
        <h3 class="title" @click="clickItem(item, false)">
          <!-- 공지 문서일 경우만 표시 -->
          <span class="badge" v-if="item.isTop === 'N'">
            공지
          </span>
          {{ item.title }}
        </h3>
        <h4>
          {{ shortContent(item.contents) }}
        </h4>
        <p @click="clickItem(item, false)">
          <span class="category">{{ item.regDateLabel }}</span>
          <span class="category">{{ item.category }}</span>
        </p>

        <!-- download -->
<!--        <ArchiveDownloadBox :item="item" />-->
      </div>
    </div>

    <!-- 동영상 플레이어 -->
<!--    <VimeoPlayer ref="refVimeoPlayer" />-->
  </div>
</template>
<script>
import VimeoPlayer from "@/components/common/dialog/VimeoPlayer.vue";
import ArchiveDownloadBox from "./ArchiveDownloadBox.vue";
const formatService = require("@/utils/format.js");
export default {
  components: { VimeoPlayer, ArchiveDownloadBox },
  data() {
    return {
      page: 1,
      pageCount: 0,
      headers: formatService.noticeListHeaderColumns(),
      noticeList: [
        // {
        //   no: 6,
        //   title: "써마지 시술 경험 관련 소비자 설문조사",
        //   regDateLabel: "2023-04-20",
        // },
      ],
    };
  },
  methods: {
    _updateData(data) {
      this.noticeList = data;
      console.log(data);
    },
    clickItem(item, goDetailOption) {
      console.log(item);
      // userGrade 비교 A -> 1등급만 / B -> 1,2만 C -> 1,2,3 모두
      if (!this.$helper.checkPermission(item)) {
        const msg = "권한이 없습니다";
        this.$helper.showTostMessage("top", msg, "info");
        return;
      }
      // if (item.dpGrade === "A") {
      //   if (item.userGrade > 1) {
      //     this.$helper.showTostMessage("top", msg, "info");
      //     return;
      //   }
      // }
      // if (item.dpGrade === "B") {
      //   if (item.userGrade > 2) {
      //     this.$helper.showTostMessage("top", msg, "info");
      //     return;
      //   }
      // }
      // vodLink 있으면 동영상
      if (goDetailOption && item.vodLink) {
        console.log("동영상플레이");
        this.$refs.refVimeoPlayer.open(item);
        return;
      }
      let target = "/newsLetter/";
      this.$router.push(target + item.xpk);
    },
    shortContent(content) {
      return content.length > 100 ? content.replace(/<[^>]*>?/g, '').substr(0, 20) + '...' : content.replace(/<[^>]*>?/g, '');
    },
  },
};
</script>
<style lang="scss">
@media (max-width: 768px) {
  div.list-grid {
    margin: 0 auto;
    .list-item {
      width: 45%;
      margin: 15px 2.5%;
    }
  }
}
.downloadBox {
  display: flex;
}
.list-grid {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  .list-item {
    width: calc(25% - 18px);
    background: #fff;
    margin-right: 24px;
    margin-bottom: 24px;
    cursor: pointer;
    &:nth-child(4n + 0) {
      margin-right: 0;
    }
    &:hover {
      box-shadow: 0 0 10px 5px rgba(71, 71, 71, 0.2);
      transition: all 0.4s;
      // .thumbnail {
      //   background: #777574;
      //   transition: all 0.4s;
      //   .image {
      //     transition: all 0.4s;
      //     opacity: 0.8;
      //   }
      // }
    }
    .thumbnail {
      overflow: hidden;
      position: relative;
      padding-top: 100%;
      background: #dfdad6;
      .image {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        &.no-image {
          text-align: center;
          color: #a69e99;
          display: flex;
          align-content: center;
          justify-content: center;
          flex-direction: column;
          .v-icon {
            color: #a69e99;
          }
        }
      }
    }
    .text-wrap {
      padding: 15px 20px;
      h3.title {
        margin-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-inline-box;
        -webkit-line-clamp: 1; /* 라인수 */
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        // line-height: 1.5em;
        height: 1.5em;
        vertical-align: middle;
        color: $point-color;
        font-weight: bold;
        .badge {
          display: inline-block;
          border: 1px solid $point-color;
          padding: 1px 4px;
          margin-right: 5px;
          margin-bottom: 10px;
          font-size: 12px;
          color: $point-color;
        }
      }
      p {
        color: $secondary-color;
        font-size: 12px;
        span.category {
          display: inline-block;
          vertical-align: middle;
          &:first-child {
            &::after {
              content: "";
              display: inline-block;
              width: 1px;
              height: 10px;
              background-color: $page-background-color;
              margin-left: 10px;
            }
          }
          &:last-child {
            float: right;
            display: inline-block;
            border: 1px solid $point-color;
            padding: 1px 4px;
            margin-right: 5px;
            margin-bottom: 10px;
            font-size: 10px;
            background-color: $point-color;
            color: #ffffff;
            border-radius: 10px;
          }
        }
      }
      .btn-wrap {
        text-align: right;
        height: 36px;
      }
    }
  }
}
</style>

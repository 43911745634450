<template>
  <v-row>
    <v-col class="pa-0" cols="12" md="12">
      <v-text-field
        label="병원명"
        ref="refBelong"
        solo
        height="52"
        required
        v-model="customerData.belong"
        :rules="[hospitalRules.required]"
        @keydown.enter.prevent="searchEnter"
      />
    </v-col>
  </v-row>
</template>
<script>
const rulesService = require("@/utils/rules.js");
export default {
  props: {
    customerData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      hospitalRules: rulesService.hospitalRules(),
    };
  },
  methods: {
    searchEnter(e) {
      console.log("e", e.target.value);
      // this.clickSearch();
    },
  },
};
</script>
<style lang="">
</style>
<template>
  <v-dialog v-model="dialog" width="800" @input="close">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        {{ title }}
      </v-card-title>
      <iframe
        id="iframe"
        :src="videoID"
        :width="width"
        :height="height"
        frameborder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
      ></iframe>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      title: "VOD",
      videoID: "https://player.vimeo.com/video/54511177",
      width: 800,
      height: 450,
      iframe: document.querySelector("iframe"),
      // options: {
      //   muted: true,
      //   autoplay: true,
      // },
      // playerReady: false,
    };
  },
  methods: {
    open(data) {
      console.log("data", data);
      this.title = data.title; // data.category + "/" + data.subCategory;
      this.videoID = data.vodLink;
      this.dialog = true;
    },
    close() {
      console.log("afterClose 동영상스탑 처리~");
      let player = new Vimeo.Player(iframe);
      player.pause();
    },
    // onReady() {
    //   this.playerReady = true;
    // },
    // play() {
    //   this.$refs.player.play();
    // },
    // pause() {
    //   this.$refs.player.pause();
    // },
  },
};
</script>
<style lang="">
</style>
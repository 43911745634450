<template>
  <div>
    <div class="page find-clinic">
      <div class="page-inner">
        <div class="page-title">
          <h1 class="main-title primary--text">병원 찾기</h1>
          <p class="sub-title">
            가까운 병의원을 확인해 보세요.<br />
            <span
              >*본 페이지는 써마지FLX를 보유한 병원 정보를 제공하며, 해당 병의원의 권유나 추천이 아닌 정보 전달 목적으로 제작되었습니다.</span
            >
          </p>
        </div>

        <FCCity
          :prosSido="reqData.city"
          :count="count"
          @changeSido="changeSido"
        />

        <FCList ref="refFCList" :data="List" />
      </div>
    </div>
    <div class="reference">
      <div class="section-inner">
        <p class="part-number mt-6">KTMG-20240122-042, KTMG-20240620-088</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import FCCity from "./comp/FCCity.vue";
import FCList from "./comp/FCList.vue";
export default {
  components: {
    FCCity,
    FCList,
  },
  data() {
    return {
      List: null,
      count: 0,
      reqData: {
        endpoint: "hospital",
        city: "서울권",
        searchVal: "",
        isDp: "Y",
        useTip: "FLX",
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    changeSido(e) {
      this.reqData.city = e;
      this.getData();
    },
    getData() {
      this.count = 0;
      this.List = [];
      this.getDataQuery(this.reqData).then((resP) => {
        if (resP === undefined) return;
        this.count = resP.count;
        let res = resP.data;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: res.length - index,
            regDateLabel: this.$helper.changeDateToStringForDp(
              item.regDate,
              false
            ),
            updDateLabel: this.$helper.changeDateToStringForDp(
              item.updDate,
              false
            ),
          }));
        }
        // console.log(res);
        this.List = res;
      });
    },
    moveScroll(tPos) {
      if (tPos === undefined) {
        tPos = "thermageIs";
      }
      setTimeout(() => {
        const pos = document.getElementById(tPos); //this.$refs.thermageIs;
        pos.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 500);
    },
    ...mapActions(["loginCheck", "getDataQuery"]),
  },
  computed: {
    ...mapGetters(["isAuthorized"]),
    ...mapState(["me"]),
  },
};
</script>

<style lang="scss">
@media (max-width: 768px) {
  div.find-clinic {
    // height: auto;
    margin-top: 70px;
    height: calc(100vh - 70px);
    overflow: auto;
    .page-title {
      margin-top: 0;
    }
    .select-sido {
      width: 100%;
    }
    .clinic-list {
      height: auto;
      .list-wrap {
        width: 100%;
        margin-right: 0;
        // height: calc(100vh - 390px);
        // max-height: 600px;
        // overflow: auto;
        .list {
          .list-item {
            position: relative;
            overflow: visible;
            &.active {
              margin-bottom: calc(90vw + 20px);
              .map-wrap {
                height: 90vw;
                padding: 10px;
              }
            }
            .map-wrap {
              position: absolute;
              display: block;
              width: 100%;
              height: 0;
              left: 0;
              top: 150px;
              transition: all 0.4s;
              padding: 0;
              .map {
                overflow: hidden;
                height: 100%;
                img {
                  width: 100%;
                }
              }
            }
          }
        }
      }
      .map-wrap {
        display: none;
      }
    }
  }
}
.find-clinic {
  // height: calc(100vh - 70px);
  background: linear-gradient(#ffffff, #ebe5e1);
  padding-bottom: 100px;
  .page-inner {
    .sub-title {
      span {
        display: inline-block;
        margin-top: 5px;
        font-size: 12px;
        line-height: 16px;
        color: $point-color;
        font-weight: 400;
      }
    }
    .section-inner {
    }
  }
  .select-sido {
    margin: 0 0 20px;
    width: 400px;
  }
  .result-num {
    margin: 0 0 20px;
  }
  .clinic-list {
    display: flex;
    width: 100%;
    height: 600px;

    .list-wrap {
      width: 400px;
      margin-right: 15px;
      overflow: auto;
      height: 100%;
      .list {
        .list-item {
          padding: 15px;
          background: #ffffff;
          margin-bottom: 10px;
          color: $primary-color;
          cursor: pointer;
          transition: all 0.4s;
          min-height: 58px;
          overflow: hidden;
          &.active {
            background: $primary-color;
            color: #ffffff;
            min-height: 140px;
            h3 {
              margin-bottom: 10px;
            }
            .detail {
              display: block;
              .v-icon {
                color: #ffffff;
              }
            }
          }

          .detail {
            display: none;
            padding-top: 10px;
            border-top: 1px solid #6a5767;
            p {
              margin-bottom: 5px;
            }
          }
          .map-wrap {
            display: none;
          }
        }
      }
    }
    .map-wrap {
      background: #ffffff;
      width: calc(100% - 415px);
      height: 100%;
      padding: 10px;
      .map {
        overflow: hidden;
        height: 100%;
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
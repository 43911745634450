<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>우편번호검색</v-toolbar-title>
        </v-toolbar>

        <v-list>
          <v-list-item>
            <h5>우편번호 : {{ zip }}</h5>
          </v-list-item>
          <v-list-item>
            <h5>주소 : {{ addr }}</h5>
          </v-list-item>
          <v-list-item>
            <div id="daumwrap" style="width: 100%"></div>
          </v-list-item>
        </v-list>
        <v-btn
          v-if="searchComplete"
          color="primary"
          x-large
          depressed
          block
          @click="completeUse"
          >사용</v-btn
        >
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      searchComplete: false,
      zip: "",
      addr: "",
    };
  },
  methods: {
    showModal() {
      console.log("test");

      this.dialog = true;
      this.searchComplete = false;
      setTimeout(() => {
        this.initDaumPost();
      }, 500);
    },
    completeUse() {
      const d = {
        zip: this.zip,
        addr: this.addr,
      };
      this.$emit("searchCompleteUse", d);
    },
    initDaumPost() {
      const wrap = document.getElementById("daumwrap");
      new daum.Postcode({
        oncomplete: (data) => {
          // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.
          // 각 주소의 노출 규칙에 따라 주소를 조합한다.
          // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
          let fullAddr = ""; // 최종 주소 변수
          let extraAddr = ""; // 조합형 주소 변수
          // 사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
          if (data.userSelectedType === "R") {
            // 사용자가 도로명 주소를 선택했을 경우
            fullAddr = data.roadAddress;
          } else {
            // 사용자가 지번 주소를 선택했을 경우(J)
            fullAddr = data.jibunAddress;
          }
          // 사용자가 선택한 주소가 도로명 타입일때 조합한다.
          if (data.userSelectedType === "R") {
            // 법정동명이 있을 경우 추가한다.
            if (data.bname !== "") {
              extraAddr += data.bname;
            }
            // 건물명이 있을 경우 추가한다.
            if (data.buildingName !== "") {
              extraAddr +=
                extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
            }
            // 조합형주소의 유무에 따라 양쪽에 괄호를 추가하여 최종 주소를 만든다.
            fullAddr += extraAddr !== "" ? " (" + extraAddr + ")" : "";
          }

          // 우편번호와 주소 정보를 해당 필드에 넣는다.
          // $("#psch_post").val(data.zonecode);
          // $("#psch_address1").val(fullAddr);

          this.searchComplete = true;
          this.zip = data.zonecode;
          this.addr = fullAddr;
        },
        width: "100%",
        height: "450px",
      }).embed(wrap);
    },
  },
};
</script>
<style lang="scss">
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.2s ease-in-out;
}
</style>
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"500px","content-class":"common-dialog"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_c('h5',[_vm._v("문의등록 시 입력한 비밀번호를 입력 해주세요.")])]),_c(VCardText,[_c(VTextField,{ref:"password",attrs:{"solo":"","height":"52","label":"비밀번호","required":"","type":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c(VCardActions,[_c(VBtn,{attrs:{"depressed":"","large":""},on:{"click":_vm.cancel}},[_vm._v(" 취소 ")]),_c(VBtn,{attrs:{"color":"primary","depressed":"","large":""},on:{"click":_vm.ok}},[_vm._v(" 확인 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
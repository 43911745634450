<template>
  <div class="select-category">
<!--    <v-select-->
<!--      :items="targetCategory"-->
<!--      label="카테고리 선택"-->
<!--      solo-->
<!--      required-->
<!--      ref="refCategory"-->
<!--      v-model="bbsModel.category"-->
<!--      @change="changeCategory"-->
<!--    ></v-select>-->
<!--    <v-select-->
<!--      v-if="-->
<!--        bbsModel.category === 'SOMA교육프로그램' ||-->
<!--        bbsModel.category === '광고자료'-->
<!--      "-->
<!--      :items="targetSubCategory"-->
<!--      label="소분류 카테고리 선택"-->
<!--      solo-->
<!--      required-->
<!--      ref="refSubCategory"-->
<!--      v-model="bbsModel.subCategory"-->
<!--      @change="changeSubCategory"-->
<!--    ></v-select>-->
    <div class="detail-inner">
      <div class="detail-title">
        <h2 class="menu-item-recommend">
          <span class="badge all current" @click="currentMenu('recommend')">추천</span>
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  props: {
    bbsModel: {
      type: Object,
    },
    targetCategory: {
      type: Array,
    },
    targetSubCategory: {
      type: Array,
    },
  },
  data() {
    return {};
  },
  watch: {
  },
  mounted() {
    // this.currentMenu('recommend');
  },
  methods: {
    currentMenu(path) {
      console.log(path);
      $(".menu-item-recommend span").removeClass("current");
      switch (path) {
        case "recommend":
          $(".menu-item-recommend .all").addClass("current");
          this.$emit("emitChangeSubCategory", "추천");
          break;
      }
    },
  },
};
</script>

<style lang="scss">
@media (max-width: 768px) {
  div.select-category {
    .v-select {
      width: 100%;
    }
    .detail-inner {
      //margin: 40px auto 40px;
      //padding: 15px;
      .detail-title {
        //padding: 30px 10px;
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        h2.menu-item-recommend {
          margin: 0 0.05em;
          font-size: 20px;
        }
        .badge {
          padding: 1px 0;
          //margin-right: calc(100% - 60px);
          margin-bottom: 10px;
        }
      }
      .detail-content {
        padding: 30px 10px;
      }
    }
  }
}
.select-category {
  .v-select {
    width: 250px;
    display: inline-block;
    margin-right: 15px;
  }
  .detail-inner {
    //width: 90vw;
    //max-width: 900px;
    //margin: 100px auto 40px;
    //background: #ffffff;
    //padding: 20px 0;
    .detail-title {
      //padding: 20px;
      border-bottom: 1px solid $point-color;
      display: flex;
      flex-direction: row;
      h2.menu-item-recommend {
        margin: 0 1.5rem;
        font-weight: bold;
        cursor: pointer;

        &:hover {
          color: $primary-color;
        }

        & .current {
          position: relative;
          color: $primary-color;
          &::after {
            //content: "";
            //display: block;
            //position: absolute;
            //height: 2px;
            //width: calc(100% + 20px);
            //background: $point-color;
            //margin-top: 3px;
            //margin-left: -8px;
          }
        }
      }
      .badge {
        display: inline-block;
        //border: 1px solid $point-color;
        padding: 1px 0;
        margin-right: 20px;
        color: $primary-border-color;
      }
    }
    .detail-content {
      padding: 40px 20px;
      img {
        width: 100%;
      }
    }
  }
}
</style>

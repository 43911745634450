<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="800" height="600">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on">
          Click Me
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> VOD </v-card-title>
        <iframe
          :src="videoID"
          :width="width"
          :height="height"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
        ></iframe>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialog: true,
      videoID: "https://player.vimeo.com/video/54511177",
      width: 800,
      height: 450,
      options: {
        muted: true,
        autoplay: true,
      },
      playerReady: false,
    };
  },
  methods: {
    onReady() {
      this.playerReady = true;
    },
    play() {
      this.$refs.player.play();
    },
    pause() {
      this.$refs.player.pause();
    },
  },
};
</script>
<style lang="">
</style>
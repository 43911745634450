<template>
  <div class="page faq">
    <div class="page-inner">
      <div class="page-title">
        <h1 class="main-title primary--text">FAQ</h1>
        <p class="sub-title">
          써마지<sup>®</sup>에 대해<br />
          <b>자주 묻는 질문</b>을 확인해 보세요.
        </p>
      </div>

      <Search @changeText="changeSearchVale" />
      <FaqList :data="List" />
    </div>

    <Reference />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Search from "@/components/thermage/common/Search.vue";
import FaqList from "./comp/FaqList.vue";
import Reference from "./comp/Reference.vue";
const modelService = require("@/utils/dataModel.js");

export default {
  components: {
    Search,
    FaqList,
    Reference,
  },
  data() {
    return {
      bbsModel: modelService.bbsModel(),
      List: null,
    };
  },
  mounted() {
    if (this.$route.path === "/faq") {
      this.bbsModel.bbsGbn = "faq";
    }
    this.bbsModel.category = "";
    this.bbsModel.subCategory = "";
    this.getData();
  },
  methods: {
    changeSearchVale(e) {
      this.bbsModel.searchVal = e;
      this.getData();
    },
    getData() {
      this.List = [];
      this.bbsModel.endpoint = "bbs/" + this.bbsModel.bbsGbn;
      this.getDataQuery(this.bbsModel).then((resP) => {
        if (resP === undefined) return;
        let res = resP.data;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: res.length - index,
            regDateLabel: this.$helper.changeDateToStringForDp(
              item.regDate,
              true
            ),
            updDateLabel: this.$helper.changeDateToStringForDp(
              item.updDate,
              true
            ),
          }));
        }
        // console.log(res);
        this.List = res;
      });
    },
    ...mapActions(["getDataQuery"]),
  },
};
</script>

<style lang="scss">
@media (max-width: 768px) {
  div.page.faq {
    .accordion {
      margin-bottom: 40px;
    }
  }
}
.page.faq {
  background: linear-gradient(#ffffff, #ebe5e1);
  .accordion {
    margin-bottom: 80px;
    .v-expansion-panels {
      border-radius: 0;
      row-gap: 1rem;
      text-align: left;
      .v-expansion-panel {
        border-radius: 0;
        border: 1px solid var(--gray-light-2);
        background: #f9f6f3;
        &::before {
          box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.05);
        }
        &::after {
          border: none;
        }
      }
      .v-expansion-panel-header {
        padding: 1.5rem;
        h4 {
          color: $primary-color;
          font-weight: bold;
          font-size: 18px;
          span.panel-title {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-inline-box;
            -webkit-line-clamp: 1; /* 라인수 */
            -webkit-box-orient: vertical;
            word-wrap: break-word;
            line-height: 1.4em;
            height: 1.4em;
            vertical-align: middle;
          }
        }
      }
      .v-expansion-panel-content {
        opacity: 0;
        transition: all 0.4s;
        padding: 0;
        border-radius: 0;
        .v-expansion-panel-content__wrap {
          border-top: 1px solid #d4d1cf;
          padding-top: 1rem;
          font-size: 0.9rem;
          span {
            display: inline-block;
            color: $point-color;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
      .v-expansion-panel--active {
        border-radius: 0;
        .v-expansion-panel-content {
          opacity: 1;
          padding: 0 1rem 1rem;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
import {
  SET_GOTO_PAGE,
} from "../mutations-types";
const actions = {
  async loginCheck({ commit }, payload) {
    commit(SET_GOTO_PAGE, payload);
  }
}

const mutations = {
  [SET_GOTO_PAGE](state, value) {
    if (value) {
      state.loginAfterGotoPage = value;
    }
  },
}
const state = {
  loginAfterGotoPage: '',
}
const getters = {}
export default {
  state,
  getters,
  actions,
  mutations,
}

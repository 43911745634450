<template>
  <v-dialog v-model="dialog" max-width="500px" content-class="confirm-dialog">
    <v-card>
      <v-card-text>
        <h4 v-html="contentText"></h4>
      </v-card-text>
      <v-card-actions>
        <v-btn depressed @click="cancel"> 취소 </v-btn>
        <v-btn color="primary" depressed @click="ok"> 확인 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      contentText: "",
    };
  },
  methods: {
    open(_content) {
      this.contentText = _content;

      this.dialog = true;
    },
    cancel() {
      this.dialog = false;
      this.$emit("clickCancel");
    },
    ok() {
      this.dialog = false;
      this.$emit("clickOk");
    },
  },
};
</script>

<style lang="scss">
.v-dialog.confirm-dialog {
  .v-card {
    .v-card__text {
      padding: 1.5rem 1rem 1rem;
      color: #000;
    }
    .v-card__actions {
      padding: 1rem 1rem 1.5rem;
      justify-content: flex-end;
    }
  }
}
</style>
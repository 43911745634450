<template>
  <div class="page notice-detail">
    <div class="detail-inner">
      <div class="detail-title">
        <h2>
          <span class="badge">{{ data && data.titleGbn }}</span
          >{{ data && data.title }}
        </h2>
      </div>
      <div class="detail-content">
        <p v-html="data && $helper.js_nl2br(data.contents)"></p>
        <img
          v-if="data && data.detailTextImg"
          :src="data.detailTextImg"
          alt=""
        />
      </div>
    </div>
    <div class="bottom-button">
      <v-btn
        color="primary"
        x-large
        depressed
        @click="$router.push('/cs/notice')"
        >목록보기</v-btn
      >
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
const modelService = require("@/utils/dataModel.js");

export default {
  data() {
    return {
      data: null,
      bbsModel: modelService.bbsModel(),
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.bbsModel.bbsGbn = "notice";
      this.bbsModel.xpk = this.$route.params.xpk;
      this.bbsModel.endpoint =
        this.bbsModel.endpoint +
        "/" +
        this.bbsModel.bbsGbn +
        "/" +
        this.bbsModel.xpk;
      this.getDataQuery(this.bbsModel).then((resP) => {
        if (resP === undefined) return;
        console.log("resP", resP);
        this.data = resP.data;
      });
    },
    ...mapActions(["getDataQuery"]),
  },
};
</script>

<style lang="scss">
@media (max-width: 768px) {
  div.notice-detail {
    .detail-inner {
      margin: 40px auto 40px;
      padding: 15px;
      .detail-title {
        padding: 30px 10px;
        h2 {
          font-size: 20px;
        }
        .badge {
          padding: 1px 10px;
          margin-right: calc(100% - 60px);
          margin-bottom: 10px;
        }
      }
      .detail-content {
        padding: 30px 10px;
      }
    }
  }
}
.notice-detail {
  background: linear-gradient(#ffffff, #ebe5e1);
  padding: 40px 0;
  .detail-inner {
    width: 90vw;
    max-width: 900px;
    margin: 100px auto 40px;
    background: #ffffff;
    padding: 20px 40px;
    .detail-title {
      padding: 20px;
      border-bottom: 1px solid $point-color;
      .badge {
        display: inline-block;
        border: 1px solid $point-color;
        padding: 1px 15px;
        margin-right: 20px;
        color: $point-color;
      }
    }
    .detail-content {
      padding: 40px 20px;
      img {
        width: 100%;
      }
    }
  }
  .bottom-button {
    text-align: center;
    margin: 40px 0;
    .v-btn {
      width: 300px;
    }
  }
}
</style>
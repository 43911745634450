<template>
  <div class="clinic-list">
    <div class="list-wrap">
      <div class="list">
        <div
          v-for="(item, i) in data"
          :key="i"
          :id="`item` + i"
          class="list-item"
          @click="activeItem(i)"
        >
          <h3>{{ item.hospitalName }}</h3>
          <div class="detail">
            <p>
              <v-icon> mdi-hospital-marker</v-icon>
              {{ item.hospitalAddress }}
            </p>
            <p v-if="item.phone !== '' && item.phone !== null">
              <v-icon> mdi-phone</v-icon>
              {{ item.phone }}
            </p>
          </div>
          <FCMap
            v-if="isMobile && activeItemNum == i"
            :ref="`refMapMobile${i}`"
          />
        </div>

        <!-- 
        <div id="item9" class="list-item" @click="activeItem(9)">
          <h3>강남 OOO 피부과</h3>
          <div class="detail">
            <p>
              <v-icon> mdi-hospital-marker</v-icon>
              서울특별시 강남구 00동 174-2
            </p>
            <p>
              <v-icon> mdi-phone</v-icon>
              02-365-1234
            </p>
          </div>
          <div class="map-wrap">
            <div class="map">
              <img src="@/assets/images/default/map_sample.png" alt="" />
            </div>
          </div>
        </div> 
        -->
      </div>
    </div>

    <FCMap v-if="!isMobile" ref="refMap" />
  </div>
</template>
<script>
import FCMap from "./FCMap.vue";
import $ from "jquery";
export default {
  components: {
    FCMap,
  },
  props: {
    data: {
      type: [Object, Array],
    },
  },
  watch: {
    data(val, oldVal) {
      // 첫번째 지도 표시
      if (val.length > 0) {
        console.log("", val[0]);
      }
    },
  },
  data() {
    return {
      activeItemNum: 0,
      isMobile: false,
    };
  },
  created() {
    this.checkMobile();
  },
  mounted() {
    window.addEventListener("resize", this.checkMobile);
  },
  methods: {
    checkMobile() {
      this.isMobile = window.matchMedia("(pointer:coarse)").matches;
      console.log(this.isMobile);
    },
    activeItem(num) {
      // console.log(num, this.data[num].hospitalAddress);
      // 현재 active 상태인 item num 변수 처리
      this.activeItemNum = num;

      for (let i = 0; i < this.data.length; i++) {
        $("#item" + i).removeClass("active");
      }

      $("#item" + num).addClass("active");

      if (this.isMobile) {
        setTimeout(() => {
          let refMapMobile = `refMapMobile${num}`;
          this.$refs[refMapMobile][0]._addressToLocation(
            this.data[num].hospitalAddress
          );
        }, 100);
      } else {
        this.$refs.refMap._addressToLocation(this.data[num].hospitalAddress);
      }
    },
  },
};
</script>
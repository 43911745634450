<template>
  <div class="page principle">
    <div class="page-inner">
      <div class="page-title">
        <h1 class="main-title primary--text">
          고주파 에너지의 콜라겐 재생 원리
        </h1>
        <p class="sub-title">
          써마지<sup>®</sup>는 <b>콜라겐을 재생</b>시키고 <b>주름을 개선</b>시켜
          줍니다.
        </p>
      </div>
      <div class="principle-list">
        <div class="item-box">
          <img class="icon" src="@/assets/images/brand/icon/icon_check.png" alt="" />
          <h2 class="item-title primary--text">초기 반응<sup>1</sup></h2>
          <p>
            콜라겐 변성(Denaturation)으로 <br />
            피부가 수축됨과 동시에<br />
            섬유성 격막(Fibrous Septae)이 수축됩니다.
          </p>
        </div>
        <div class="item-box">
          <img class="icon" src="@/assets/images/brand/icon/icon_check.png" alt="" />
          <h2 class="item-title primary--text">
            2차 상처 치유 반응<sup>2</sup>
          </h2>
          <p>
            변성된 콜라겐이 재생되면서<br />
            콜라겐 밀도가 증가합니다.
          </p>
        </div>
      </div>
    </div>
    <div class="reference">
      <div class="section-inner">
        <b> Reference</b><br />
        1. Bogle MA, et al. Evaluation of the multiple pass, low fluence algorithm for radiofrequency tightening of the
        lower face. Lasers Surg Med. 2007;39(3):210-7. 2. Yokoyama Y, et al. Histologic study of collagen and stem cells
        after radiofrequency treatment for aging skin. Dermatol Surg. 2014;40(4):390-7.
        <br />
        <br />
        •Thermage FLX™ 제품은 "의료기기" 이며, "사용상의 주의사항"과 "사용방법"을 잘 읽고 사용하십시오<br />
        •심의확인번호: 조합 2023-42-038(유효기간 2026.12.19)
        <p class="part-number mt-6">KTMG-20231012-027, KTMG-20240620-088</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.page.principle {
  background: linear-gradient(#ffffff, #ebe5e1);

  .principle-list {
    margin: 150px 0 30px;
    display: flex;

    .item-box {
      width: calc(50% - 15px);
      border: 1px solid var(--gray-light-2);
      background: #f9f6f3;
      padding: 40px 20px;
      box-shadow: 2px 4px 16px 0px rgba(0, 0, 0, 0.09);
      text-align: center;

      &:first-child {
        margin-right: 30px;
      }

      img.icon {
        width: 34px;
        margin-bottom: 15px;
      }

      .item-title {
        font-size: 35px;
        border-bottom: 1px solid $chrome-color;
        padding-bottom: 15px;
        margin-bottom: 30px;
      }

      p {
        line-height: 40px;
      }
    }
  }
}

@media (max-width: 768px) {
  div.page.principle {
    .principle-list {
      margin: 0 0 30px;
      flex-direction: column;

      .item-box {
        width: 100%;

        &:first-child {
          margin-right: 0;
          margin-bottom: 30px;
        }

        img.icon {
          width: 24px;
          margin-bottom: 10px;
        }

        .item-title {
          font-size: 25px;
        }

        p {
          line-height: 24px;
        }
      }
    }
  }
}</style>
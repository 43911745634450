const moment = require("moment");
exports.requiredFieldAlertMessage = () => " 필수 입력입니다";


exports.noticeHeaderColumns = () => {
    return [
        {
            text: "No",
            align: "center",
            sortable: false,
            value: "no",
        },
        {
            text: "제목",
            align: "start",
            sortable: false,
            value: "title",
        },
    ]
}

exports.noticeListHeaderColumns = () => {
    return [
        {
            text: "No",
            align: "center",
            sortable: false,
            value: "no",
        },
        {
            text: "제목",
            align: "start",
            sortable: false,
            value: "title",
        },
        {
            text: "등록일",
            align: "center",
            sortable: false,
            value: "regDateLabel",
        },
    ]
}

exports.searchHospitalHeaderColumns = () => {
    return [
        { text: "병원명", value: "hname", align: "left", sortable: false, width: 200 },
        { text: "주소", value: "haddress", align: "left", sortable: false, },
    ]
}

exports.inquiryHeaderColumns = () => {
    return [
        {
            text: "No",
            align: "center",
            sortable: false,
            value: "no",
        },
        {
            text: "제목",
            align: "start",
            sortable: false,
            value: "contentsLbl",
        },
        {
            text: "작성자",
            align: "center",
            sortable: false,
            value: "nameDp",
        },
        {
            text: "등록일",
            align: "center",
            sortable: false,
            value: "regDateLabel",
        },
    ]
}

exports.equipmentHeaderColumns = () => {
    return [
        {
            text: "No",
            align: "center",
            sortable: false,
            value: "no",
        },
        {
            text: "제목",
            align: "start",
            sortable: false,
            value: "contentsLbl",
        },
        {
            text: "작성자",
            align: "center",
            sortable: false,
            value: "nameDp",
        },
        {
            text: "등록일",
            align: "center",
            sortable: false,
            value: "regDateLabel",
        },
    ]
}

exports.itemsInquiryType = () => {
    return [
        { value: "productAuth", text: "정품인증관련 문의" },
        { value: "event", text: "사은품 문의" },
        { value: "product", text: "제품관련 문의" },
        { value: "marketing", text: "마케팅자료 문의" },
        { value: "etc", text: "기타 문의사항" },
    ]
}

exports.itemsEquipmentType = () => {
    return [
        { value: "equipmentInquiry", text: "장비관련 문의" },
        { value: "equipmentAfterService", text: "장비 A/S 요청" },
    ]
}

exports.itemsEquipmentProductName = () => {
    return [
        { value: "FLX", text: "FLX" },
        { value: "CPT", text: "CPT" },
        { value: "NXT", text: "NXT" },
    ]
}

exports.itemsSIDO = () => {
    return [
        { value: "", text: "시도/ 선택" },
        { value: '서울권', text: '서울권' },
        { value: '인천권', text: '인천권' },
        { value: '경기권', text: '경기권' },
        { value: '충청권', text: '충청권' },
        { value: '경상권', text: '경상권' },
        { value: '전라권', text: '전라권' },
        { value: '강원권', text: '강원권' },
        { value: '제주권', text: '제주권' },
    ];
};

exports.itemsREPNum = () => {
    return [
        { value: "", text: "시술샷 수 선택" },
        { value: "225", text: "225샷(REP)" },
        { value: "300", text: "300샷(REP)" },
        { value: "400", text: "400샷(REP)" },
        { value: "450", text: "450샷(REP)" },
        { value: "600", text: "600샷(REP)" },
        { value: "900", text: "900샷(REP)" },
        { value: "1200", text: "1200샷(REP)" },
    ];
};

exports.specialty = () => {
    return [
        { value: "", text: "선택" },
        { value: "피부과", text: "피부과" },
        { value: "성형외과", text: "성형외과" },
        { value: "가정의학과", text: "가정의학과" },
        { value: "산부인과", text: "산부인과" },
        { value: "정형외과", text: "정형외과" },
        { value: "내과", text: "내과" },
        { value: "이비인후과", text: "이비인후과" },
        { value: "비뇨기과", text: "비뇨기과" },
        { value: "기타", text: "기타" },
    ];
};
exports.itemsCategory = () => {
    return [
        { value: "", text: "카테고리 선택" },
        { value: "전체", text: "전체 카테고리" },
        { value: "SOMA교육프로그램", text: "SOMA 교육 프로그램" },
        { value: "문헌", text: "문헌" },
        { value: "시술영상", text: "시술영상" },
    ]
}
exports.itemsSubCategory = () => {
    return [
        { value: "", text: "소분류카테고리 선택" },
        { value: "SOMA안내영상", text: "SOMA안내영상" },
        { value: "TTT", text: "TTT(Train The Trainer)" },
        { value: "MTL", text: "MTL(MedicalThought Leader)" },
        { value: "UserMeeting", text: "UserMeeting" },
        { value: "Symposium", text: "Symposium" },
    ]
}

exports.itemsMarketingCategory = () => {
    return [
        { value: "", text: "카테고리 선택" },
        { value: "광고자료", text: "광고 자료" },
        { value: "원내자료", text: "원내 자료" },
        { value: "정품인증관련자료", text: "정품인증 관련 자료" },
        { value: "제품컷", text: "제품컷" },
        { value: "브랜드로고및가이드 라인", text: "브랜드 로고 및 가이드 라인" },
        { value: "기타자료", text: "기타 자료" },
    ]
}
exports.itemsMarketingSubCategory = () => {
    return [
        { value: "", text: "소분류카테고리 선택" },
        { value: "광고영상", text: "광고 영상" },
        { value: "메인이미지", text: "메인 이미지" },
        { value: "모델컷", text: "모델컷" },
    ]
}

exports.itemsNewsLetterCategory = () => {
    return [
        { value: "", text: "카테고리 선택" },
        { value: "뉴스", text: "뉴스" },
        { value: "이벤트", text: "이벤트" },
        { value: "공지", text: "공지" },
        { value: "학술행사", text: "학술행사" },
    ]
}

<template>
  <div class="input-reply">
    <v-row>
      <v-col cols="9">
        <v-text-field
          label="댓글을 입력해주세요."
          ref="refReply"
          solo
          height="52"
          required
          v-model="inquiryReply"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-btn color="primary" x-large depressed block @click="commentInput"
          >등록하기</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>
<script>
const formatService = require("@/utils/format.js");
export default {
  data() {
    return {
      inquiryReply: "",
      requiredMsg: formatService.requiredFieldAlertMessage(),
    };
  },
  methods: {
    commentInput() {
      // 필수
      const D = this;
      const RF = this.$refs;
      if (
        !this.$helper.requiredCheck(
          D.inquiryReply,
          RF.refReply,
          `댓글은 ${this.requiredMsg}`
        )
      )
        return;

      this.$emit("commentInputComplete", this.inquiryReply);
      this.inquiryReply = "";
    },
  },
};
</script>
<style lang="">
</style>
<template>
  <div id="topNav" :class="`top-nav ${moNav ? 'on' : 'off'}`">
    <div class="mobile-top">
      <div class="mobile-logo" @click="pageMoveHandler('/')">
        <img
          src="@/assets/images/common/thermage_logo.png"
          alt="thermage_logo"
        />
      </div>
      <div class="mobile-btn" @click="moNavOnOff">
        <v-btn icon large
          ><v-icon> mdi-{{ moNav ? "close" : "menu" }} </v-icon></v-btn
        >
      </div>
    </div>

    <div class="nav-inner">
      <div class="nav-item logo" @click="pageMoveHandler('/')">
        <img
          src="@/assets/images/common/thermage_logo.png"
          alt="thermage_logo"
        />
      </div>

      <!-- 메뉴 -->
      <TopNavMenu
        :isAuthorized="isAuthorized"
        @pageMoveEmit="pageMoveHandler"
        @logoutEmit="logoutHandler"
      />

      <div class="nav-item right">
        <v-divider vertical></v-divider>
        <img
          src="@/assets/images/common/soltamedical_logo.png"
          alt="soltamedical_logo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import $ from "jquery";
import TopNavMenu from "./TopNav_menu.vue";
export default {
  components: { TopNavMenu },
  data() {
    return {
      moNav: false,
    };
  },
  mounted() {
    document.addEventListener("scroll", this.checkScroll);
  },
  methods: {
    //   console.log(this.$route.path);
    //   const path = this.$route.path;
    //   $(".menu-item").removeClass("current");
    //   $(".sub-item").removeClass("current");
    //   switch (path) {
    //     case "/brand":
    //       // 써마지®란?
    //       $("#brand .menu-item").addClass("current");
    //       $(".sub-item.brand").addClass("current");
    //       break;

    //     case "/brand/tips":
    //       // 시술 팁 소개
    //       $("#brand .menu-item").addClass("current");
    //       $(".sub-item.tips").addClass("current");
    //       break;

    //     case "/brand/principle":
    //       //콜라겐 재생 원리
    //       $("#brand .menu-item").addClass("current");
    //       $(".sub-item.principle").addClass("current");
    //       break;

    //     case "/brand/technology":
    //       // 기술 & 허가 정보
    //       $("#brand .menu-item").addClass("current");
    //       $(".sub-item.technology").addClass("current");
    //       break;

    //     case "/faq":
    //       // 써마지® FAQ
    //       $(".menu-item.faq").addClass("current");
    //       break;

    //     case "/certify":
    //       // 정품 인증
    //       $("#certify .menu-item").addClass("current");
    //       $(".sub-item.certify").addClass("current");
    //       break;

    //     case "/certify/genuine_product":
    //       // 정품 팁이란?
    //       $("#certify .menu-item").addClass("current");
    //       $(".sub-item.genuine_product").addClass("current");
    //       break;

    //     case "/find":
    //       // 병원 찾기
    //       $(".menu-item.find").addClass("current");
    //       break;

    //     case "/archive":
    //       // 의학 자료실
    //       $("#library .menu-item").addClass("current");
    //       $(".sub-item.archive").addClass("current");
    //       break;

    //     case "/archive2":
    //       // 마케팅 자료실
    //       $("#library .menu-item").addClass("current");
    //       $(".sub-item.archive2").addClass("current");
    //       break;

    //     case "/cs/inquiry":
    //       // 소비자 문의
    //       $("#cs .menu-item").addClass("current");
    //       $(".sub-item.inquiry").addClass("current");
    //       break;

    //     case "/cs/notice":
    //       // 공지사항
    //       $("#cs .menu-item").addClass("current");
    //       $(".sub-item.notice").addClass("current");
    //       break;

    //     case "/mypage":
    //       // 공지사항
    //       $("#mypage .menu-item").addClass("current");
    //       $(".sub-item.mypage").addClass("current");
    //       break;
    //   }
    // },
    checkScroll() {
      let isScrollDown = window.scrollY > 80;

      // console.log("isScrollDown", isScrollDown);
      if (isScrollDown) {
        $("#topNav").addClass("bg");
      } else {
        $("#topNav").removeClass("bg");
      }
    },
    moNavOnOff() {
      this.moNav = !this.moNav;
    },

    pageMoveHandler(path) {
      if (this.moNav) this.moNav = false;
      this.$router.push(path).catch(() => {});
    },
    logoutHandler() {
      if (!confirm("로그아웃 하시겠습니까?")) return;
      this.signout().then(() => {
        window.location.href = "/";
      });
    },
    ...mapActions(["signout"]),
  },
  computed: {
    ...mapGetters(["isAuthorized"]),
    ...mapState(["me"]),
  },
};
</script>

<style lang="scss">
@media (max-width: 768px) {
  div.top-nav {
    padding: 1rem;
    height: 100vh;
    left: 100vw;
    background: #ffffff;
    &:hover {
      height: 100vh;
    }
    &.on {
      left: 0;
      height: 100vh;
      .mobile-logo {
        display: none;
      }
      .mobile-btn {
        position: absolute;
        transition-delay: 1s;
      }
    }
    &.off {
      left: 100vw;
      // .mobile-btn {
      //   position: fixed;
      // }
    }
    &.bg {
      .mobile-top {
        background-color: #ffffff;
        box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.1);
      }
      .mobile-btn {
        .v-btn {
          // background-color: $chrome-background-color;
          // box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
        }
      }
    }
    .mobile-top {
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      width: 100%;
      height: 60px;
      justify-content: space-between;
      transition: all 0.4s;
      .mobile-logo {
        padding: 0.5rem 1rem;
        margin-top: -3px;
        cursor: pointer;
        img {
          width: 70px;
        }
      }
      .mobile-btn {
        padding: 0.5rem 1rem;
        .v-btn {
          transition: all 0.4s;
        }
      }
    }

    .nav-inner {
      flex-direction: column;
      height: calc(100vh - 40px);
      overflow: auto;
      .logo {
        margin-top: 0;
        padding: 0 0 1rem;
        img {
          width: 65px;
        }
      }
      .right {
        margin-top: 0;
        padding: 2rem 0 0;
        .v-divider {
          display: none;
        }
        img {
          width: 120px;
        }
      }
      .menu {
        flex-direction: column;
        padding: 1rem 0;
        border-bottom: 1px solid #ddd;
        width: 100%;
        // align-items: flex-start;
        .menu-item {
          position: relative;
          padding: 1rem;
          width: 100%;
          margin: 0;
          &.current {
            div {
              position: relative;
              display: inline-block;
              &::after {
                content: "";
                display: block;
                position: absolute;
                height: 2px;
                width: calc(100% + 20px);
                background: $point-color;
                margin-top: 3px;
                margin-left: -8px;
              }
            }
            position: relative;
            &::after {
              display: none;
            }
          }
        }
        .include-sub {
          display: block;
          width: 100%;
          // justify-content: flex-start;
          &.active {
            .btn-expend {
              transform: rotate(180deg);
            }
            .sub-list {
              display: block;
            }
          }
          .menu-item {
            width: calc(100% - 40px);
            .group-title {
              pointer-events: none;
            }
            .btn-expend {
              display: block;
              position: absolute;
              top: 10px;
              right: -40px;
            }
          }

          .sub-list {
            display: none;
            position: relative;
            text-align: left;
            margin: 15px 0 40px;
            // .sub-item {
            //   font-weight: 400;
            // }
          }
        }
      }
    }
  }
}
.top-nav {
  position: fixed;
  background: #ffffff;
  width: 100%;
  padding: 1rem 3rem;
  z-index: 100;
  transition: 0.6s;
  box-shadow: 0px 0 20px 0 #ffffff;
  height: 70px;
  overflow: hidden;
  &:hover {
    height: 240px;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
  }
  &.bg {
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
  }
  .mobile-top {
    display: none;
  }
  .nav-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .logo {
      margin-top: -10px;
      cursor: pointer;
      img {
        width: 85px;
      }
    }
    .right {
      margin-top: -10px;
      cursor: pointer;
      img {
        width: 160px;
      }
    }
    .menu {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      width: calc(100% - 265px);
      max-width: 1000px;
      .menu-item {
        margin: 0 1.5rem;
        font-weight: bold;
        cursor: pointer;
        &:hover {
          color: $primary-color;
        }
        &.current {
          position: relative;
          &::after {
            content: "";
            display: block;
            position: absolute;
            height: 2px;
            width: calc(100% + 20px);
            background: $point-color;
            margin-top: 3px;
            margin-left: -8px;
          }
        }
      }
      #brand {
        .menu-item.current::after {
          margin-left: -11px;
        }
      }
      .include-sub {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        .btn-expend {
          display: none;
        }
        .sub-list {
          position: absolute;
          margin: 30px 0 1rem;
          width: max-content;
          // left: -47%;
          text-align: center;
          .sub-item {
            padding: 0 1rem;

            cursor: pointer;
            margin: 1rem 0;
            &:hover {
              color: $primary-color;
            }
            &.current {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <div>
    <v-row class="row-cell">
      <v-col cols="9">
        <v-text-field
          label="휴대전화번호"
          ref="refCell"
          solo
          height="52"
          required
          v-model="userData.cell"
          type="text"
          :rules="[cellRules.required, cellRules.counter, cellRules.check]"
          @input="changeCell"
        >
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-btn
          color="primary"
          x-large
          depressed
          :disabled="userData.checkSms"
          @click="clickSendCode"
          >인증번호 받기</v-btn
        >
      </v-col>
    </v-row>
    <!-- 인증번호 받기 버튼 클릭 시 노출 -->
    <v-row class="row-code" v-if="userData.checkSms && isSend">
      <v-col cols="9">
        <v-text-field
          label="인증번호"
          ref="refCode"
          solo
          height="52"
          required
          type="text"
          v-model="reqData.inputCode"
          @input="changeCode"
        >
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-btn
          color="primary"
          x-large
          depressed
          :disabled="userData.checkedSms"
          @click="completeCertify"
          >인증완료</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
const rulesService = require("@/utils/rules.js");

export default {
  props: {
    userData: {
      type: Object,
    },
  },
  data() {
    return {
      cellRules: rulesService.cellRules(),
      isSend: false,
      reqData: {
        endpoint: "sendCertifySms",
        userCell: "",
        inputCode: "",
        code: "",
        isTest: "N", // sms test로 할때 Y
      },
    };
  },
  methods: {
    changeCell() {
      // 로그인시 -> 회원정보 수정시 작동
      if (!this.isAuthorized) return;
      this.userData.checkSms = true;
      if (this.me.originCell !== this.userData.cell) {
        this.userData.checkSms = false;
      }
      // console.log("changeCell", this.userData.cell, this.me);
    },
    changeCode() {
      console.log("changeCode", this.userData.cell, this.me);
      this.userData.checkedSms = true;
      if (this.reqData.inputCode.length > 3) {
        this.userData.checkedSms = false;
      }
    },
    clickSendCode() {
      // 필수
      const DT = this.userData;
      const RF = this.$refs;
      if (
        !this.$helper.requiredCheck(
          DT.cell,
          RF.refCell,
          `휴대폰번호를 입력해주세요`
        )
      )
        return;

      if (DT.cell.length < 10) {
        return;
      }

      this.isSend = false;
      this.userData.checkSms = false;
      this.reqData.userCell = this.userData.cell;
      this.reqData.code = this.$helper.randomNumber(1000, 9999);
      this.saveDataPreLoginQuery(this.reqData).then((res) => {
        if (res === undefined) return;
        // console.log("res", res);
        console.log("userData", this.userData);

        this.isSend = true;
        this.userData.checkedSms = true;
        this.userData.checkSms = true;
        setTimeout(() => {
          this.$refs.refCode.focus();
        }, 1000);
      });
    },
    completeCertify() {
      console.log("", this.reqData);
      // 필수
      this.userData.checkedSms = false;
      const DT = this.reqData;
      const RF = this.$refs;
      if (
        !this.$helper.requiredCheck(
          DT.inputCode,
          RF.refCode,
          `인증번호를 입력해주세요.`
        )
      )
        return;

      if (Number(this.reqData.inputCode) !== Number(this.reqData.code)) {
        this.$helper.showTostMessage(
          "top",
          "전달받은 인증번호를 확인해 주세요.",
          "info"
        );
        this.$refs.refCode.focus();
        return;
      }

      this.userData.checkedSms = true;
    },
    ...mapActions(["saveDataPreLoginQuery"]),
  },
  computed: {
    ...mapGetters(["isAuthorized"]),
    ...mapState(["me"]),
  },
};
</script>
<style lang="scss">
</style>
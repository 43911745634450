<template>
  <div class="page-section header">
    <!-- <div class="gradation"></div> -->
    <div class="section-inner">
      <div class="section-title">
        <img
          class="top-logo"
          src="@/assets/images/common/thermage_logo.png"
          alt="thermage_logo"
        />
      </div>
      <div class="inner-text">
        써마지<sup>®</sup>는 미국 솔타메디칼(SOLTA MEDICAL, INC.)에서 개발되고
        <b>미국 FDA<sup>1</sup>, 한국 식품의약품안전처(MFDS)<sup>2</sup></b>
        허가와<br />
        <b>20여 년간</b> 임상,연구 개발<sup>3</sup>로
        <b>안전성과 유효성이 검증</b>된 의료기기입니다.
      </div>
      <img class="main-img" src="@/assets/images/brand/brand_main.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="">
</style>
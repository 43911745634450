<template>
  <div class="agree">
    <div class="section-inner">
      <div class="signup-header">
        <img
          class="top-logo"
          src="@/assets/images/common/thermage_logo.png"
          alt="app_logo"
        />
        <p class="sub-title">
          본 자료실은 보건의료전문가만<br />
          열람 가능 합니다.
        </p>
      </div>
      <v-form ref="form">
        <div class="check-group">
          <h4>
            <v-checkbox
              v-model="userData.agreeAll"
              label="전체 동의"
              @click="clickAll(userData.agreeAll)"
            ></v-checkbox>
          </h4>
          <div class="checkbox-row">
            <v-checkbox
              required
              v-model="userData.marketing"
              label="개인정보 수집 동의 [필수]"
              :rules="[
                () => !!userData.marketing || '이용약관 동의는 필수 입니다.',
              ]"
            >
            </v-checkbox>
            <v-btn text @click="clickAgreeDetail('term')"> 보기 </v-btn>
          </div>
          <div class="checkbox-row">
            <v-checkbox
              required
              v-model="userData.agreeTerms"
              label="개인정보 처리방침 항목 동의 [필수]"
              :rules="[
                () =>
                  !!userData.agreeTerms ||
                  '개인정보처리방침 동의는 필수 입니다.',
              ]"
            ></v-checkbox>
            <v-btn text @click="clickAgreeDetail('policy')"> 보기 </v-btn>
          </div>
          <div class="checkbox-row">
            <!-- 필수 동의를 선택 동의로 전환, 아래 주석코드는 필수동의 코드 백업본 -->
            <v-checkbox
              v-model="userData.agreePolicy"
              label="의,약학적 정보 전달 및 제품 홍보 등 마케팅 안내 관련 동의 [선택]"
            ></v-checkbox>

            <!-- <v-checkbox
              required
              v-model="userData.agreePolicy"
              label="의,약학적 정보 전달 및 제품 홍보 등 마케팅 안내 관련 동의 [선택]"
              :rules="[
                () => !!userData.agreePolicy || '의학 정보 동의는 필수 입니다.',
              ]"
            ></v-checkbox> -->
            <v-btn text @click="clickAgreeDetail('medical')"> 보기 </v-btn>
          </div>
        </div>
        <v-btn color="primary" x-large depressed block @click="clickNext"
          >다음</v-btn
        >
        <div class="hGap" />
        <v-btn color="" x-large depressed block @click="clickCancel"
          >취소</v-btn
        >
      </v-form>
    </div>

    <!-- 약관 상세보기 팝업 -->
    <SignUpAgreeDetailDialog ref="SignUpAgreeDetailDialog" />
  </div>
</template>

<script>
import SignUpAgreeDetailDialog from "./SignUpAgreeDetailDialog.vue";
export default {
  components: {
    SignUpAgreeDetailDialog,
  },
  props: {
    userData: {
      type: Object,
    },
  },

  methods: {
    clickNext() {
      if (this.$refs.form.validate() == false) {
        return;
      }
      this.$emit("clickNextEmit", "profile");
    },
    clickAll(e) {
      this.userData.agreeTerms = true;
      this.userData.agreePolicy = true;
      this.userData.marketing = true;
      if (!e) {
        this.userData.marketing = false;
        this.userData.agreeTerms = false;
        this.userData.agreePolicy = false;
      }
    },
    clickAgreeDetail(tab) {
      this.$refs.SignUpAgreeDetailDialog.open(tab);
    },
    clickCancel() {
      this.$emit("clickCancel");
    },
  },
};
</script>

<style lang="scss">
.hGap {
  height: 10px;
}
.agree {
  padding: 8rem 0 12rem;
  .section-inner {
    max-width: 800px;
  }
  .signup-header {
    padding: 0 1rem 2.5rem;
    text-align: center;
    img {
      width: 170px;
    }
    .input-guide {
      margin-top: 1.5rem;
      color: $primary-color;
    }
  }
  .check-group {
    padding: 2rem 0;
    h4 {
      border-bottom: 1px solid #ddd;
    }
  }
  .checkbox-row {
    position: relative;
    .v-btn {
      position: absolute;
      right: 0;
      top: -3px;
      .v-btn__content {
        text-decoration: underline;
      }
    }
  }
}
@media (max-width: 768px) {
  div.agree {
    padding: 4rem 0 8rem;
  }
}
</style>
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c(VDialog,{attrs:{"width":"800","height":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"red lighten-2","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Click Me ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 grey lighten-2"},[_vm._v(" VOD ")]),_c('iframe',{attrs:{"src":_vm.videoID,"width":_vm.width,"height":_vm.height,"frameborder":"0","webkitallowfullscreen":"","mozallowfullscreen":"","allowfullscreen":""}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
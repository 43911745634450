import {
} from "../mutations-types";
import axios from "axios";
import api from "@/api";
import Cookies from "js-cookie";
const CookieName = process.env.VUE_APP_CookieName;
let accessInfo;
let user_id = 0;

const actions = {
  // **** 공통사용모듈 **** //
  async getApiDataQuery({ commit }, endpoint) {
    try {
      const res = await axios.get(`${endpoint}`);
      if (res) {
        return res;
      }
    } catch (err) {
      return alert(`${endpoint} error`);
    }
  },

  async getDataQuery({ commit }, payload) {
    console.log('accessInfo', accessInfo);
    if (Cookies.get(CookieName) !== undefined) {
      accessInfo = JSON.parse(Cookies.get(CookieName));
      user_id = accessInfo.user_id;
    }
    payload.user_id = user_id;
    try {
      let res = await api.post(`/user/${payload.endpoint}`, payload);
      if (res) {
        return res.data;
      }
    } catch (err) {
      return alert(`${payload.endpoint} error`);
    }
  },

  async saveDataQuery({ commit }, payload) {
    console.log('accessInfo', accessInfo);
    if (Cookies.get(CookieName) !== undefined) {
      accessInfo = JSON.parse(Cookies.get(CookieName));
      user_id = accessInfo.user_id;
    }
    payload.user_id = user_id;
    try {
      // const res = await api.put(`/user/${payload.endpoint}`, payload);
      let res;
      if (payload.queryMethod === 'POST') {
        res = await api.post(`/user/${payload.endpoint}`, payload);
      } else {
        res = await api.put(`/user/${payload.endpoint}`, payload);
      }
      if (res) {
        return res.data;
      }
    } catch (err) {
      return alert(`${payload.endpoint} error`);
    }
  },

  // 로그인 전 처리(회원가입 / checkSms / find pw)
  async saveDataPreLoginQuery({ commit }, payload) {
    try {
      console.log('payload', payload);
      let res;
      if (payload.queryMethod === 'POST') {
        res = await api.post(`/${payload.endpoint}`, payload);
      } else {
        res = await api.put(`/${payload.endpoint}`, payload);
      }
      if (res) {
        return res.data;
      }
    } catch (err) {
      if (err.response.status === 409) {
        return alert(err.response.data.message);
      } else if (err.response.status === 500) {
        return alert(err.response.data.message);
      } else {
        return alert(`${payload.endpoint} error`);
      }
    }
  },
}

const mutations = {
}
const state = {
}
const getters = {}
export default {
  state,
  getters,
  actions,
  mutations,
}

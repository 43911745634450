<template>
  <div class="reference">
    <div class="section-inner">
      <b> Reference</b><br />
      1.Kilmer SL, et al. Consensus Recommendations for 4th Generation Non-Microneedling Monopolar Radiofrequency for Skin
      Tightening: A Delphi Consensus Panel. J Drugs Dermatol. 2020;19(1):20-26 2.Edwards AF, et al. Clinical efficacy and
      safety evaluation of a monopolar radiofrequency device with a new vibration handpiece for the treatment of facial
      skin laxity: a 10-month experience with 64 patients. Dermatol Surg. 2013;39(1 Pt 1):104-10. 3.FDA (2017) Thermage
      FLX System. 510(k)Summary. (K170758)
      4. 식품의약품안전처 허가증 Thermage FLX System 수허 18-245 호_Cover
      5. 식품의약품안전처 허가증 써마지트리트먼트팁 수인 17-4032 호_Cover
      <br />

      <br />
      •Thermage FLX™ 제품은 "의료기기" 이며, "사용상의 주의사항"과 "사용방법"을 잘 읽고 사용하십시오<br />
      •심의확인번호: 조합 2024-22-073 (유효기간 2027.06.23)
      <p class="part-number mt-6">KTMG-20231012-027, KTMG-20240620-088</p>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="">
</style>
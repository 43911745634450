<template>
  <div>
    <div class="select-sido">
      <v-select
        :items="itemsSIDO"
        label="시/도 선택"
        solo
        required
        ref="refSido"
        v-model="sido"
        @change="changeSido"
      ></v-select>
    </div>
    <div class="result-num">총 {{ count }} 개 병원</div>
  </div>
</template>
<script>
const formatService = require("@/utils/format.js");
export default {
  props: {
    count: {
      type: Number,
      default: 0,
    },
    prosSido: {
      type: String,
      default: "서울권",
    },
  },
  data() {
    return {
      sido: this.prosSido,
      itemsSIDO: formatService.itemsSIDO(),
    };
  },
  methods: {
    changeSido(e) {
      this.$emit("changeSido", e);
    },
  },
};
</script>
<template>
  <div class="page technology">
    <div class="page-title">
      <h1 class="main-title primary--text">
        AccuREP™ Technology <br />
        아큐렙™ 기술<sup>1</sup>
      </h1>
      <p class="sub-title">
        <b>샷(REP)마다 튜닝 펄스</b>가 적용되면서 자동 튜닝을 통해 시술
        부위별로<br />
        <b>일관된 에너지</b>를 전달하는 <b>자동 조절 알고리즘 기술</b>입니다.
      </p>
    </div>
    <div class="page-section step">
      <div class="section-inner">
        <div class="step-list">
          <div class="list-item">
            <div class="step-info">
              <h2 class="item-title">STEP 1</h2>
              <p><b>시술팁을 피부 위</b>에 안착시킵니다.</p>
            </div>
            <div class="step-img">
              <img src="@/assets/images/brand/technology/step1_img.png" alt="" />
            </div>
          </div>
          <div class="list-item">
            <div class="step-info">
              <h2 class="item-title">STEP 2</h2>
              <p>
                시술이 시작되면서 <b>쿨링 시스템이 가동</b>되고, <br />자동
                튜닝이 시작됩니다.<sup>1</sup>
              </p>
            </div>
            <div class="step-img">
              <img src="@/assets/images/brand/technology/step2_img.png" alt="" />
            </div>
          </div>
          <div class="list-item">
            <div class="step-info">
              <h2 class="item-title">STEP 3</h2>
              <p>
                고주파 열에너지가 깊숙이 침투하고<br />
                <b>진동</b>이 계속됩니다.
              </p>
            </div>
            <div class="step-img">
              <img src="@/assets/images/brand/technology/step3_img.png" alt="" />
            </div>
          </div>
          <div class="list-item">
            <div class="step-info">
              <h2 class="item-title">STEP 4</h2>
              <p>
                고주파 열에너지가 침투하는 동안 <br />
                <b>쿨링 시스템이 유지</b>됩니다.
              </p>
            </div>
            <div class="step-img">
              <img src="@/assets/images/brand/technology/step4_img.png" alt="" />
            </div>
          </div>
          <div class="list-item">
            <div class="step-info">
              <h2 class="item-title">STEP 5</h2>
              <p>
                마지막으로 <b>피부를 보호하기 위해</b><br />
                쿨링 시스템이 가동됩니다.
              </p>
            </div>
            <div class="step-img">
              <img src="@/assets/images/brand/technology/step5_img.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-section approval">
      <div class="section-inner">
        <h1 class="section-title">
          써마지<sup>®</sup>FLX는 한국식약처로부터 아래 <br />
          <span class="primary--text">사용목적과 작용 원리</span>에 대해
          승인받았습니다.<sup>2,3</sup>
        </h1>
        <div class="approval-item">
          <div class="icon-wrap">
            <img src="@/assets/images/brand/technology/icon_purpose.png" alt="" />
          </div>
          <div class="text-wrap">
            <h2 class="item-title primary--text">사용목적</h2>
            <p>
              써마지<sup>®</sup>FLX 시스템은 고주파 전류를 이용하여<br />
              조직의 응고로 눈가 및 안면상 주름의 비침습적 치료에 사용하는
              기구입니다.
            </p>
          </div>
        </div>
        <div class="approval-item">
          <div class="icon-wrap">
            <img src="@/assets/images/brand/technology/icon_principle.png" alt="" />
          </div>
          <div class="text-wrap">
            <h2 class="item-title primary--text">작용 원리</h2>
            <p>
              표피를 냉각시키고 진피 조직에 열을 발생시키는 역열증감률 (REVERSE
              THERMAL GRANDIENT) 원리를 사용하여 고주파 에너지를 전달함으로써
              콜라겐 섬유의 변성 및 수축을 일으켜 콜라겐 재생 및 주름 개선
              효과를 나타냅니다.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="reference">
      <div class="section-inner">
        <b> Reference</b><br />

        1.REF-TRM-0031-Support for Thermage FLX claims_wcw
        2. 식품의약품안전처 허가증 Thermage FLX System 수허 18-245 호_Cover
        3. 식품의약품안전처 허가증 Thermage FLX System 수허 18-245 호_작용원리

        <br />
        <br />
        •Thermage FLX™ 제품은 "의료기기" 이며, "사용상의 주의사항"과 "사용방법"을 잘 읽고 사용하십시오
        <br />
        •심의확인번호: 조합 2024-22-072 (유효기간 2027.06.23)
        <p class="part-number mt-6">KTMG-20231012-027, KTMG-20240620-088
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.page.technology {
  padding: 2rem 0 0;

  .page-title {
    width: 90vw;
    margin: 70px auto 40px;
  }

  .page-section.step {
    background: linear-gradient(#ffffff, #ebe5e1);

    .step-list {

      // margin-top: 150px;
      .list-item {
        display: flex;
        align-items: center;
        padding: 0 20px;
        margin-bottom: 40px;
        justify-content: space-between;

        .step-img {
          text-align: center;

          img {
            width: 250px;
          }
        }

        .step-info {
          width: 65%;
          padding: 20px;
          display: flex;
          align-items: center;

          .item-title {
            font-size: 60px;
            margin-right: 40px;
            color: #b0a8a2;

            &::after {
              content: "";
              display: inline-block;
              height: 60px;
              background: $chrome-color;
              margin-left: 40px;
              vertical-align: middle;
            }
          }

          p {
            margin: 0;

            b {
              color: $point-color;
            }
          }
        }
      }
    }
  }

  .page-section.approval {
    background: #ffffff;
    padding-bottom: 80px;

    .section-title {
      border-bottom: 1px solid $point-color;
      padding-bottom: 40px;
    }

    .approval-item {
      display: flex;
      align-items: center;
      margin-bottom: 40px;

      .icon-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid var(--gray-light-2);
        background: #ffffff;
        width: 220px;
        height: 220px;
        box-shadow: 2px 4px 16px 0px rgba(0, 0, 0, 0.09);

        img {
          width: 140px;
          margin-left: 15px;
        }
      }

      .text-wrap {
        margin-left: 40px;
        width: calc(100% - 260px);

        .item-title {
          margin-bottom: 20px;
          font-size: 30px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  div.page.technology {
    .page-section.step {
      .step-list {
        .list-item {
          flex-direction: column;

          .step-img {
            img {
              width: 100%;
            }
          }

          .step-info {
            flex-direction: column;
            width: 100%;
            padding: 0;
            align-items: start;

            .item-title {
              font-size: 40px;
              margin-right: 0;

              &::after {
                display: none;
              }
            }
          }
        }
      }
    }

    .page-section.approval {
      background: #ffffff;
      padding-bottom: 40px;

      .section-title {
        font-size: 20px;
      }

      .approval-item {
        flex-direction: column;

        &:last-child {
          margin-bottom: 0;
        }

        .icon-wrap {
          width: 170px;
          height: 170px;

          img {
            width: 100px;
          }
        }

        .text-wrap {
          margin-left: 0;
          margin-top: 20px;
          width: 100%;
          text-align: center;

          .item-title {
            margin-bottom: 20px;
            font-size: 30px;
          }
        }
      }
    }
  }
}</style>
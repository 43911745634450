<template>
  <v-dialog v-model="dialog" max-width="800px" content-class="common-dialog">
    <v-card>
      <v-card-title>
        <h5>검색 결과 중에서 선택해 주세요</h5>
      </v-card-title>

      <v-data-table
        item-key="num"
        :headers="headers"
        :items="items"
        hide-default-footer
        @click:row="clickRow"
      />

      <v-card-actions>
        <v-btn depressed large @click="cancel"> 닫기 </v-btn>
        <!-- <v-btn color="primary" depressed large @click="ok"> 확인 </v-btn> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const formatService = require("@/utils/format.js");
import { mapActions } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      password: "",
      items: [],
      headers: formatService.searchHospitalHeaderColumns(),
    };
  },
  methods: {
    open(e) {
      this.dialog = true;
      this.items = e;
      console.log("e", e);
    },
    cancel() {
      this.dialog = false;
      // this.$emit("clickCancel");
    },
    clickRow(e) {
      console.log("e", e);
      this.dialog = false;
      this.$emit("clickRow", e);
    },
    ok() {
      // 필수
      if (this.password === "") {
        this.$helper.showTostMessage(
          "top",
          "비밀번호를 입력해 주세요.",
          "error"
        );
        this.$refs.password.focus();
        return;
      }
      this.item.ipk;
      this.item.password = this.password;
      this.item.endpoint = "inquiry/password";
      this.getDataQuery(this.item).then((res) => {
        console.log("", res);

        if (res === undefined) return;
        if (!res) {
          this.$helper.showTostMessage(
            "top",
            "비밀번호를 확인해 주세요",
            "error"
          );
          this.$refs.password.focus();
          return;
        }

        this.dialog = false;
        this.$emit("clickOk", this.item);
      });
    },
    ...mapActions(["getDataQuery"]),
  },
};
</script>

<style lang="scss">
.v-dialog.common-dialog {
  .v-card {
    .v-data-table__mobile-row__header {
      min-width: 80px;
    }
    .v-card__actions {
      padding: 1rem 1rem 1.5rem;
    }
  }
}
</style>
<template>
  <div>
    <div class="profile-img">
      <div class="img-wrap">
        <div class="img-mask">
          <img
            v-if="fileInfo !== null"
            :src="fileInfo.fullURL"
            :alt="fileInfo.filename"
          />
          <img
            v-else
            :src="require(`@/assets/images/default/${defImage}.png`)"
          />
        </div>
        <div class="image-upload">
          <!-- v-btn쓰면 안됨 -->
          <label
            class="v-btn"
            for="file-input"
            outlined
            fab
            x-small
            color="primary"
          >
            <v-icon> mdi-camera</v-icon>
          </label>

          <input
            id="file-input"
            :accept="accept"
            type="file"
            @change="fileSelect"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const rulesService = require("@/utils/rules.js");
import AWS from "aws-sdk";

export default {
  props: {
    uploadType: {
      default: "",
      type: String,
    },
    // 초기 표시 이미지
    defImage: {
      default: "",
      type: String,
    },
    // 초기 표시 라벨
    defLabel: {
      default: "",
      type: String,
    },
    // 업로드 할 디렉토리 * 없으면 빈값~
    uploadDir: {
      default: "",
      type: String,
    },
    // 파일해더 * 업로드 된 파일의 헤더(*필수로 넣어주세요) 예: RPM-File_YYYYMMDDHHmmss.jpg
    fileHeader: {
      default: "",
      type: String,
    },
    // 업로드 된 파일 정보를 받은경우
    fileInfo: {
      default: null,
      type: Object,
    },
    disabled: {
      type: Boolean,
    },
    accept: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      albumBucketName: rulesService.s3Config().S3_BUCKET,
      bucketRegion: rulesService.s3Config().S3_REGION,
      IdentityPoolId: rulesService.s3Config().S3_POOLID,
      selectFile: null,
      s3: null,
    };
  },
  created() {
    AWS.config.update({
      region: this.bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: this.IdentityPoolId,
      }),
    });
    this.s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      params: { Bucket: this.albumBucketName },
    });
  },
  methods: {
    // 첨부파일 선택
    fileSelect(file) {
      // alert(file.target.files[0].name);
      // return;
      // console.log('', file.target.files[0]);
      this.$emit("uploadStarted");
      this.selectFile = file.target.files[0];
      this.uploadFileToS3();
    },
    // S3 Upload Action
    uploadFileToS3() {
      const _self = this;
      if (this.selectFile === null) {
        this.$emit("uploadComplete", null);
        return;
      }
      const photoKey = this.selectFile.name; // 파일명
      const getTime = this.$moment().format("YYYYMMDDHHmmss");
      const _lastDot = photoKey.lastIndexOf(".");
      const _fileExt = photoKey
        .substring(_lastDot, photoKey.length)
        .toLowerCase();

      let upload_root = "biztalk/";
      if (this.uploadDir !== "") {
        upload_root = upload_root + this.uploadDir + "/";
      }
      let FILE_HEADER = "BIZTALK-File";
      if (this.fileHeader !== "") {
        FILE_HEADER = this.fileHeader;
      }
      const renameFile = `${FILE_HEADER}_${getTime}${_fileExt}`; // 리네임 처리
      let upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: this.albumBucketName,
          Key: upload_root + renameFile,
          Body: this.selectFile,
        },
      });

      let promise = upload.promise();

      promise.then(
        (data) => {
          // alert("Successfully uploaded photo.");
          // console.log(data);
          _self.$emit("uploadComplete", data);
          // let filename = data.Key.split(upload_root).join("");
          // this.reqData.img_name = filename;
          // this.reqData.URL = data.Location;
          // this.saveData();
        },
        (err) => {
          return alert(
            "There was an error uploading your photo: ",
            err.message
          );
        }
      );
    },
  },
};
</script>

<style lang="scss">
#file-input {
  display: none;
}
.profile-img {
  text-align: center;
  padding: 1rem 0 2rem;
  .img-wrap {
    position: relative;
    width: 5rem;
    height: 5rem;
    display: inline-block;
    .img-mask {
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .v-btn {
      position: absolute;
      right: -7px;
      bottom: -5px;
      background: #ffffff;
    }
  }
}
</style>
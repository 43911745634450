<template>
  <div class="accordion">
    <v-expansion-panels>
      <v-expansion-panel v-for="(item, i) in data" :key="i">
        <v-expansion-panel-header>
          <h4 v-html="$helper.js_nl2br(item.title)"></h4>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p v-html="$helper.js_nl2br(item.contents)"></p>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: [Object, Array],
    },
  },
};
</script>
<style lang="">
</style>
<template>
  <div class="page signup-page">
    <sign-up-form @signup="onClickSignUp" @signin="onClickSignIn" />
    <div class="reference">
      <div class="section-inner">
        <p class="part-number mt-6">KTMG-20240122-042, KTMG-20240620-088</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import SignUpForm from "./SignUpForm";

export default {
  name: "SignUpPage",
  components: {
    SignUpForm,
  },
  methods: {
    onClickSignUp(userData) {
      userData.endpoint = "signup";
      this.saveDataPreLoginQuery(userData).then((res) => {
        if (res === undefined) return;
        this.$helper.showTostMessage(
          "top",
          "회원가입 요청이 완료되었으며 승인 시 메일로 안내해 드립니다",
          "success"
        );
        // 자동로그인 처리(아이디/비번전달)
        // this.loginAction(userData);
        this.$router.push("/").catch(() => {});
      });
    },
    onClickSignIn(e) {
      this.loginAction(e);
    },
    loginAction(e) {
      e.userAgent = navigator.userAgent.toLowerCase();
      this.signin(e).then((res) => {
        if (res === undefined) return;
        // console.log("res", res);
        // return;
        if (res === 401) {
          this.$helper.showTostMessage(
            "top",
            "아이디 또는 비밀번호를 확인해 주세요",
            "error"
          );
          return;
        }
        let loginAfterGotoPage = this.loginCheck.loginAfterGotoPage;
        if (loginAfterGotoPage === null || loginAfterGotoPage === "") {
          loginAfterGotoPage = "/";
        }
        this.$router.push({ path: loginAfterGotoPage }).catch(() => {});
      });
    },
    ...mapActions(["signin", "saveDataPreLoginQuery"]),
  },
  computed: {
    ...mapState(["loginCheck"]),
  },
};
</script>

<style lang="scss">
.signup-page {
  /* margin: 10px; */
  background: linear-gradient(#ffffff, #ebe5e1);
}
</style>

<template>
  <div class="section-inner find-enter">
    <div class="section-title">
      <img
        class="top-logo"
        src="@/assets/images/common/thermage_logo.png"
        alt="thermage_logo"
      />
      <p class="sub-title">
        아이디 찾기를 위해 성함과 휴대폰번호를 입력해주세요.
      </p>
    </div>

    <v-form ref="form">
      <v-text-field
        label="성함"
        ref="refName"
        solo
        height="52"
        required
        v-model="userData.name"
        :rules="[nameRules.required, nameRules.counter]"
      ></v-text-field>

      <v-text-field
        label="휴대전화번호"
        ref="refCell"
        solo
        height="52"
        required
        v-model="userData.cell"
        type="text"
        :rules="[cellRules.required, cellRules.counter, cellRules.check]"
        @change="changeCell"
      >
      </v-text-field>
      <div class="bottom-wrap">
        <v-btn depressed color="primary" x-large block @click="findID"
          >확인</v-btn
        >
        <v-btn depressed x-large block @click="$router.push('/login')"
          >취소</v-btn
        >
      </div>
    </v-form>

    <!-- 중복팝업 -->
    <CommonDialog
      ref="CommonDialog"
      :useBtnCancel="false"
      @clickOk="duplicateDialog_clickOKHandler"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CommonDialog from "@/components/common/dialog/CommonDialog.vue";
const rulesService = require("@/utils/rules.js");
const modelService = require("@/utils/dataModel.js");
export default {
  components: {
    CommonDialog,
  },
  data() {
    return {
      isDev: process.env.NODE_ENV === "development",
      userData: modelService.userSignUpModel(),
      nameRules: rulesService.nameRules(),
      cellRules: rulesService.cellRules(),
    };
  },
  mounted() {
    if (this.isDev) {
      // this.userData.name = "test";
      // this.userData.cell = "01067403230";
    }
  },
  methods: {
    findID() {
      // 필수
      if (!this.$refs.form.validate()) return;

      this.userData.endpoint = "checkIdPw";
      this.userData.checkType = "id";
      this.saveDataPreLoginQuery(this.userData).then((res) => {
        if (res === undefined) return;
        console.log("", res);

        if (res === null || res === "") {
          this.$helper.showTostMessage(
            "top",
            "가입된 회원정보가 없습니다.",
            "error"
          );
          return;
        }

        if (!res.isHcp) {
          this.$helper.showTostMessage(
            "top",
            "[" +
              res.loginId +
              "]아이디는 회원가입 승인 대기중입니다\n관리자에게 문의하세요.",
            "info"
          );
          return;
        }

        const content = "가입된 회원아이디는 [" + res.loginId + "]입니다.";
        this.$refs.CommonDialog.open(content);

        this.$emit("emitFindID");
      });
    },
    duplicateDialog_clickOKHandler() {
      // this.status = "signin";
      // this.$emit("signup", this.userData);
    },
    changeCell() {
      console.log(this.$refs.refCell.validate());
    },
    ...mapActions(["saveDataPreLoginQuery"]),
  },
};
</script>

<style lang="scss">
</style>
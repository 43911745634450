<template>
  <div class="reply-list">
    <!-- <div class="reply">
      <h4 class="primary--text">담당자</h4>
      <p>정품인증은 1회만 가능합니다.</p>
      <p class="date">2023-04-21 오후 3:48</p>
    </div> -->
    <div class="reply mine" v-for="(item, i) in data" :key="i">
      <h4 class="primary--text">
        <span class="newLabel" v-if="checkNewDate(item.regDate)">New</span>
        {{ item.userName }}
      </h4>
      <v-btn text class="btn-delete" @click="clickReplyDelete(item)">
        댓글삭제
      </v-btn>
      <p>{{ item.comment }}</p>
      <p class="date">
        {{ $moment(item.regDate).format("YYYY-MM-DD a hh:mm") }}
      </p>
      <!-- 관리자 댓글 처리 -->
      <div
        v-if="item.adminComment !== ''"
        :class="`${item.adminComment !== '' ? 'admin' : ''}`"
      >
        <h4 class="primary--text">
          <v-icon>mdi-arrow-right-bottom</v-icon>
          관리자
        </h4>
        <p>{{ item.adminComment }}</p>
        <p class="date">
          {{
            $helper.changeDateToForUpdateDp(
              item.adminCommentRegDate,
              item.adminCommentUpdDate
            )
          }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: [Object, Array],
    },
  },
  methods: {
    // 3일확인(오늘날짜)
    checkNewDate(_regDate) {
      let today = this.$moment(); //.format("YYYY-MM-DD hh:mm:ss");
      let regDate = this.$moment(_regDate).format("YYYY-MM-DD hh:mm:ss");
      console.log("today", today);
      console.log("regDate", regDate);
      let gap = this.$moment
        .duration(today.diff(this.$moment(regDate)))
        .asDays(); // 1
      console.log("gap", gap);
      return gap < 3;
    },
    clickReplyDelete(item) {
      if (!confirm("댓글을 삭제하시겠습니까?")) return;
      this.$emit("clickReplyDelete", item);
    },
  },
};
</script>
<style lang="scss">
.newLabel {
  color: #40073c;
}
</style>
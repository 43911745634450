<template>
  <div class="detail-header">
    <v-btn icon>
      <v-icon @click="closeClick">
        mdi-{{ btnType == "close" ? "close" : "chevron-left" }}
      </v-icon>
    </v-btn>
    <h4>{{ title }}</h4>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    btnType: {
      type: String,
      default: "back",
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      clickCount: 0,
    };
  },
  methods: {
    closeClick() {
      // this.clickCount++;
      // // if (this.clickCount === 0) {
      // // } else if (this.clickCount === 3) {
      // //   this.clickCount--;
      // // }
      // console.log("click", this.clickCount);
      // this.stepValue("close");
      this.$emit("headerCloseClick");
    },
    ...mapActions(["stepValue"]),
  },
};
</script>

<style>
</style>
import {
  SET_PRODUCT_CODE,
  SET_AUTH_TYPE
} from "../mutations-types";

const actions = {
  saveCertify({ commit }, payload) {
    let { productCode } = payload;
    commit(SET_PRODUCT_CODE, productCode);
  },
  saveAuthType({ commit }, payload) {
    let { authType } = payload;
    commit(SET_AUTH_TYPE, authType);
  },
}

const mutations = {
  [SET_PRODUCT_CODE](state, value) {
    // if (value) {
    state.productCode = value;
    // }
  },
  [SET_AUTH_TYPE](state, value) {
    // if (value) {
    state.authType = value;
    // }
  },
}
const state = {
  productCode: '',
  authType: ''
}
const getters = {}
export default {
  state,
  getters,
  actions,
  mutations,
}

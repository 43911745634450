import {
  SET_ACCESS_TOKEN,
  SET_MY_INFO,
  DESTROY_ACCESS_TOKEN,
  DESTROY_MY_INFO,
  SET_STEP_VALUE,
} from "./mutations-types";
import api from "@/api";
import Cookies from "js-cookie";

export default {
  [SET_ACCESS_TOKEN](state, accessToken) {
    if (accessToken) {
      state.accessToken = accessToken;
      api.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

      Cookies.set("accessToken", accessToken, { expires: 100000 });
    }
  },
  [SET_MY_INFO](state, me) {
    if (me) {
      state.me = me;
    }
  },
  [DESTROY_ACCESS_TOKEN](state) {
    state.accessToken = "";
    delete api.defaults.headers.common["Authorization"];
    Cookies.remove("accessToken");
    const CookieName = process.env.VUE_APP_CookieName;
    Cookies.remove(CookieName);
  },
  [DESTROY_MY_INFO](state) {
    state.me = null;
  },
  [SET_STEP_VALUE](state, value) {
    if (value) {
      state.stepValue = value;
    }
  },
};

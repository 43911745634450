<template>
  <div class="list-table">
    <v-data-table
      class="fixed-notice"
      :headers="fixedHeaders"
      :items="fixedNoticeList"
      item-key="num"
      :page.sync="page"
      hide-default-footer
      @click:row="clickRow"
    >
      <template v-slot:item.no="{ item }">
        <span class="badge">공지</span>
      </template>
    </v-data-table>
  </div>
</template>
<script>
const formatService = require("@/utils/format.js");
export default {
  data() {
    return {
      page: 1,
      pageCount: 0,
      fixedHeaders: formatService.noticeHeaderColumns(),
      fixedNoticeList: [
        // {
        //   num: 3,
        //   title: "써마지 시술 경험 관련 소비자 설문조사",
        // },
      ],
    };
  },
  methods: {
    _updateData(data) {
      this.fixedNoticeList = data;
    },
    clickRow(item) {
      console.log(item);
      this.$router.push("/cs/notice_detail/" + item.xpk);
    },
  },
};
</script>
<template>
  <div class="list-table">
    <v-data-table
      :headers="headers"
      :items="equipmentList"
      item-key="num"
      :page.sync="page"
      hide-default-footer
      @page-count="pageCount = $event"
      @click:row="clickRow"
    >
      <template v-slot:item.title="{ item }">
        {{ item.title }}

        <!-- 게시글 답변 수  표시 -->
        <span v-if="item.replyNum > 0"> &#91;{{ item.replyNum }}&#93; </span>
      </template>
    </v-data-table>
    <v-pagination v-model="page" :length="pageCount"></v-pagination>
  </div>
</template>
<script>
const formatService = require("@/utils/format.js");
export default {
  data() {
    return {
      page: 1,
      pageCount: 0,
      headers: formatService.equipmentHeaderColumns(),
      equipmentList: [],
    };
  },
  methods: {
    _updateData(data) {
      this.equipmentList = data;
    },
    clickRow(item) {
      console.log(item);
      this.$emit("clickRow", item);
    },
  },
};
</script>
<style lang="">
</style>
<template>
	<div class="old-policy">
		<div>
			<strong>개인정보의 수집 및 이용에 대한 동의</strong><br />
            솔타메디칼코리아 유한회사(이하 “회사”)는 개인정보 보호법 등 관계 법령에 따라 개인정보를 안전하게 처리하고 있습니다. 회사는 귀하께 솔타메디칼코리아 유한회사의 써마지®FLX 브랜드 사이트 (이하 “웹사이트”)에 대한 이용권한을 부여해드리고 그에 따른 서비스를 제공해드리고자, 아래와 같이 귀하의 개인정보를 수집 이용할 예정인바, 이에 귀하의 동의를 요청드립니다. 
		<br /><br />

		<strong>- 개인정보의 수집 및 이용에 대한 동의 - </strong><br />
		•	개인정보의 수집·이용 목적: 솔타메디칼코리아 유한회사의 써마지®FLX 브랜드 사이트 통합 회원가입 및 관리(솔타메디칼코리아 유한회사 통합 회원으로 가입하시는 경우 솔타메디칼코리아 유한회사의 써마지®FLX 브랜드 사이트 웹사이트, 모바일 앱, 카카오채널 (구 카카오플러스친구) 등(이하 “웹사이트 등”) 솔타메디칼코리아 유한회사에서 제공하는 다양한 서비스를 별도 회원 가입 없이 이용하실 수 있습니다), 회원제 서비스 이용에 따른 본인 여부 및 가입자격 확인, 중복가입·부정이용·비인가사용 등의 방지, 웹사이트 등을 통한 솔타메디칼코리아 유한회사의 써마지®FLX 서비스 제공, 웹사이트 등 서비스 이용에 따른 민원사항의 처리 및 고지사항의 전달, 의학정보 문의 대응 및 의학정보 전달, 학술행사에 대한 안내, 뉴스레터의 발송, 회원의 서비스 이용현황 관련 분석<br />
        •	개인정보의 보유 이용기간: 회원탈퇴 시까지. 다만, 관계법령의 규정에 따라 개인정보를 보존하여야 하는 경우에는 그러한 규정에 따라 보관됩니다.<br />
        •	귀하는 위와 같은 개인정보의 수집 및 이용에 대한 동의를 거부할 수 있습니다. 다만, 이에 동의하지 않을 경우 위 웹사이트 가입이 제한될 수 있습니다.
		<br /><br /><br />

        <strong>의·약학적 정보 전달 및 제품 홍보 등 마케팅 안내 관련 동의 </strong><br />
		회사가 위와 같이 수집한 개인정보는 상기 보유 및 이용기간 동안(즉, 회원탈퇴 시까지) 우편(전자우편 포함) 발송, SMS 발송, 카카오채널 (구 카카오플러스친구) 등 SNS, 방문, 전화 등을 통한 제품설명회 등 보건의료전문가를 대상으로 하는 마케팅, 시장조사 및 회사 제품의 안내를 포함한 의·약학적 정보 전달 및 취득 활동의 수행 등에 이용될 수 있습니다. 귀하는 이에 동의하지 않으실 수 있으나, 동의하지 않는 경우 향후 회사의 신제품의 안내, 의·약학적 정보 전달이 이루어지지 않을 수 있습니다
		<br /><br /><br />

        <strong>개인정보 처리 위탁 관련 고지  </strong><br />
		회사는 보다 나은 서비스를 제공해드리고자 아래와 같이 개인정보 처리업무를 위탁하고 있습니다. 수탁자는 위탁받은 업무 이외의 목적으로는 개인정보를 이용하지 않습니다. 
        <br /> <br />
        1) 수탁자 : 지피랩 주식회사(ZP Lab Co., Ltd) <br />
        2) 위탁하는 업무의 내역 : 이메일/SMS/카카오채널을 통한 정보 전송 등
        <br />

		
     </div>
	</div>
</template>
            
<script>
import $ from "jquery";
export default {
	created() {
		if (this.$route.path === "/old_policy") {
			console.log(this.$route.path);
			setTimeout(() => {
				$('#topNav').css('display', 'none');
				$('.floating-buttons').css('display', 'none');
				$('footer').css('display', 'none');

			}, 200);


		};
	}
};
</script>

<style lang="scss">
.old-policy {
	padding: 40px;
	// border: 10px solid #691c32;
	font-size: 14px;
	color: #333;
	background-color: #ffffff;
	position: relative;
	z-index: 2000;
	>div {
		margin: 80px auto;
		width: 90vw;
	}
}

.old-policy strong {
	font-size: 18px;
	// color: #691c32;
	font-weight: bold;
}


@media (max-width:780px) {
	.old-policy {
		margin: 40px 40px;
		padding: 20px;
		font-size: 13px;
	}

	.old-policy strong {
		font-size: 15px;
		// color: #691c32;
	}
}

@media (max-width:640px) {
	.old-policy {
		margin: 0 0;
		padding: 10px;
	}
}
</style>
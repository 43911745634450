<template>
  <v-dialog v-model="dialog" fullscreen content-class="fullscreen-dialog">
    <div class="detail-page">
      <DetailHeader btnType="close" :title="title" @headerCloseClick="cancel" />
      <v-tabs v-model="tab" fixed-tabs @change="changeTab">
        <v-tab key="terms"> 개인정보 수집동의 </v-tab>
        <v-tab key="policy"> 개인정보처리방침 </v-tab>
        <v-tab key="medical"> 의학 정보 관련 동의 </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <!-- 이용약관 -->
        <v-tab-item key="terms">
          <Terms />
        </v-tab-item>

        <!-- 개인정보처리방침  -->
        <v-tab-item key="policy">
          <Policy />
        </v-tab-item>

        <!-- 의학 정보 관련 동의 -->
        <v-tab-item key="medical">
          <Medical />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-dialog>
</template>

<script>
import DetailHeader from "@/components/common/DetailHeader.vue";
import Medical from "@/components/thermage/common/Medical.vue";
import Policy from "@/components/thermage/common/Policy.vue";
import Terms from "@/components/thermage/common/Terms.vue";
export default {
  components: {
    DetailHeader,
    Terms,
    Policy,
    Medical,
  },
  props: {},
  data() {
    return {
      dialog: false,
      tab: null,
      title: "",
    };
  },
  methods: {
    open(_tab) {
      switch (_tab) {
        case "term":
          this.tab = 0;
          break;
        case "policy":
          this.tab = 1;
          break;
        case "medical":
          this.tab = 2;
          break;
      }

      this.dialog = true;
    },
    cancel() {
      this.dialog = false;
      this.$emit("clickCancel");
    },
    changeTab(e) {
      console.log(e);
      switch (e) {
        case 0:
          this.title = "개인정보 수집동의";
          break;
        case 1:
          this.title = "개인정보처리방침";
          break;
        case 2:
          this.title = "의학 정보 관련 동의";
          break;
      }
    },
  },
};
</script>

<style lang="scss">
@media (max-width: 768px) {
  .v-dialog.fullscreen-dialog {
    .v-tabs {
      .v-slide-group__content {
        width: 100%;
      }
      .v-tab {
        width: 33%;
      }
    }
  }
}
.v-dialog.fullscreen-dialog {
  background: #ffffff;
  .v-tabs {
    position: relative;
    left: -1rem;
    width: 100vw;
    border-bottom: 1px solid var(--gray-light-2);

    .v-slide-group__prev {
      display: none !important;
    }
    .v-tab.v-tab--active {
      background: var(--gray-light-2);
    }
  }
  .v-tabs-items {
    margin-top: 1rem;
    font-size: 14px;
  }
}
</style>
<template>
  <div>
    <v-text-field
      solo
      v-model="searchWord"
      :height="prosHeight"
      :placeholder="propsPlaceholder"
      @keypress.enter="changeWord"
    ></v-text-field>
    <v-btn
      v-if="searchWord.length > 0"
      class="mb-3"
      color="primary"
      x-large
      depressed
      block
      @click="clickReset"
      >검색 초기화</v-btn
    >
  </div>
</template>
<script>
export default {
  props: {
    prosHeight: {
      type: Number,
      default: 52,
    },
    propsPlaceholder: {
      type: String,
      default: "자주묻는 질문을 검색해보세요.",
    },
  },
  data() {
    return {
      searchWord: "",
    };
  },
  methods: {
    clickReset() {
      this.searchWord = "";
      this.$emit("changeText", this.searchWord);
    },
    changeWord(e) {
      this.searchWord = e.target.value;
      this.$emit("changeText", e.target.value);
    },
  },
};
</script>
<template>
  <v-alert type="warning"> I'm a success alert. </v-alert>
</template>
<script>
export default {
  data() {
    return {
      alert: true,
      title: "Alert title",
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi, ratione debitis quis est labore voluptatibus! Eaque cupiditate minima, at placeat totam, magni doloremque veniam neque porro libero rerum unde voluptatem!",
    };
  },
};
</script>
<style lang="">
</style>
<template>
  <v-app>
    <div>
      <TopNav />

      <v-main app>
        <router-view />
      </v-main>

      <FloatingButtons />
      <Footer />
    </div>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Footer from "@/components/thermage/app/Footer.vue";
import TopNav from "@/components/thermage/app/TopNav.vue";
import FloatingButtons from "@/components/thermage/app/FloatingButtons.vue";

export default {
  name: "App",
  components: {
    Footer,
    TopNav,
    FloatingButtons,
  },
  data() {
    return {
      isShow: false,
      updateDialog: false,
      mainTitle: "써마지",
    };
  },
  computed: {
    ...mapGetters(["isAuthorized"]),
    ...mapState(["me"]),
  },
  created() {
    document.title = "Thermage FLX";
  },
  methods: {
    ...mapActions(["signout"]),
  },
};
</script>

<style lang="scss">
@import "assets/scss/style";
// .v-btn {
//   margin-left: 5px;
// }
.intro {
  margin: 10px;
  font-size: 15px;
}
</style>

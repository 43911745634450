<template>
  <div class="page archive-detail">
    <div class="detail-inner">
      <div class="detail-title">
        <h2>
          <!-- 공지 문서일 경우만 표시 -->
          <span class="badge" v-if="data && data.isTop === 'N'">
            공지
          </span>
          {{ data && data.title }}
          {{ data && data.vodLink !== "" ? "VOD" : "" }}
          <span class="vodImgInfo">
            {{
              data && data.vodLink !== ""
                ? "* 이미지를 클릭하시면 동영상이 플레이 됩니다."
                : ""
            }}
          </span>
        </h2>
      </div>
      <div class="detail-content">
        <p v-html="data && $helper.js_nl2br(data.contents)"></p>
        <img
          v-if="data && data.detailTextImg"
          :src="data.detailTextImg"
          alt=""
          @click="palyVod"
        />

        <ArchiveDownloadBox :item="data" />
        <!-- <div class="video-wrap">
          <iframe
            v-if="data && data.vodLink"
            :src="data.vodLink"
            width="100%"
            height="300"
            frameborder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
          ></iframe>
        </div> -->
      </div>
    </div>
    <div class="bottom-button">
      <v-btn color="primary" x-large depressed @click="gotoList">
        목록보기
      </v-btn>
    </div>

    <!-- 동영상 플레이어 -->
    <VimeoPlayer ref="refVimeoPlayer" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import VimeoPlayer from "@/components/common/dialog/VimeoPlayer.vue";
import ArchiveDownloadBox from "./comp/ArchiveDownloadBox.vue";
const modelService = require("@/utils/dataModel.js");
export default {
  components: { VimeoPlayer, ArchiveDownloadBox },
  data() {
    return {
      data: null,
      bbsModel: modelService.bbsModel(),
      targetList: "/archive",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      console.log("this.$route.path", this.$route.path);

      this.bbsModel.bbsGbn = "archive";
      this.bbsModel.xpk = this.$route.params.xpk;
      this.bbsModel.titleGbn = "의학";
      if (this.$route.path.indexOf("/archive2") > -1) {
        this.targetList = "/archive2";
        this.bbsModel.titleGbn = "마케팅";
      }
      this.bbsModel.endpoint = "bbs/archive/" + this.bbsModel.xpk;
      this.getDataQuery(this.bbsModel).then((res) => {
        if (res === undefined) return;
        console.log("res", res);
        this.data = res.data;
      });
    },
    gotoList() {
      this.$router.push(this.targetList);
    },
    palyVod() {
      // 동영상이 있으면 플레이 처리
      if (this.data.vodLink === null || this.data.vodLink === "") return;
      console.log("동영상플레이");
      this.$refs.refVimeoPlayer.open(this.data);
    },
    ...mapActions(["getDataQuery"]),
  },
};
</script>

<style lang="scss">
@media (max-width: 768px) {
  div.archive-detail {
    .detail-inner {
      margin: 40px auto 40px;
      padding: 15px;
      .detail-title {
        padding: 30px 10px;
        h2 {
          font-size: 20px;
        }
        .badge {
          padding: 1px 10px;
          margin-right: calc(100% - 60px);
          margin-bottom: 10px;
          font-size: 12px;
        }
      }
      .detail-content {
        padding: 30px 10px;
      }
    }
  }
}
.vodImgInfo {
  font-size: 12px;
}
.archive-detail {
  background: linear-gradient(#ffffff, #ebe5e1);
  padding: 40px 0;
  .detail-inner {
    width: 90vw;
    max-width: 900px;
    margin: 100px auto 40px;
    background: #ffffff;
    padding: 20px 40px;
    .detail-title {
      padding: 20px;
      border-bottom: 1px solid $point-color;
      .badge {
        display: inline-block;
        border: 1px solid $point-color;
        padding: 1px 15px;
        margin-right: 20px;
        color: $point-color;
      }
    }
    .detail-content {
      padding: 40px 20px;
      img {
        width: 100%;
      }
    }
  }
  .bottom-button {
    text-align: center;
    margin: 40px 0;
    .v-btn {
      width: 300px;
    }
  }
  .video-wrap {
    position: relative;
    padding-top: 56%;
    width: 100%;
    height: 0;
    /* iframe */
    & > iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background: #292929;
    }
  }
}
</style>
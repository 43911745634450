<template>
  <div class="page tips">
    <div class="page-inner">
      <div class="page-title">
        <h1 class="main-title primary--text">
          써마지<sup>®</sup>FLX 팁 종류 / 샷(REP)<sup>1,2</sup>
        </h1>
        <!-- <p class="sub-title">
          써마지<sup>®</sup> 시술 팁은 의료기기법에 따라 재사용이 불가한 1회용
          의료기기로써 한 환자에게만 사용 가능합니다.<br />
          하나의 팁으로 여러 환자에게 사용할 경우 감염, 화상 등의 문제가 발생할
          수 있어 시술 전 식품의약품안전처 허가 받은 정품팁을 사용하는지 반드시
          확인해야 합니다.
        </p> -->
      </div>
      <div class="tip-list">
        <div class="item-box">
          <div class="tip-img">
            <img src="@/assets/images/brand/total_tip.png" alt="" />
          </div>
          <div class="tip-info">
            <h2 class="item-title primary--text">토탈팁 4.0 cm<sup>2</sup></h2>
            <div class="info-list">
              <div class="info-item">
                <div>샷(REP)</div>
                <div><b>900샷(REP), 600샷(REP), 300샷(REP)</b></div>
              </div>
              <div class="info-item">
                <div>시술 부위</div>
                <div>얼굴 전체</div>
              </div>
              <div class="info-item">
                <div>시술 효과</div>
                <div>얼굴 주름 및 눈가 주름 완화, 콜라겐 재생</div>
              </div>
            </div>
          </div>
        </div>
        <div class="item-box">
          <div class="tip-img">
            <img src="@/assets/images/brand/eye_tip.png" alt="" />
          </div>
          <div class="tip-info">
            <h2 class="item-title primary--text">아이팁 0.25 cm<sup>2</sup></h2>
            <div class="info-list">
              <div class="info-item">
                <div>샷(REP)</div>
                <div><b>450샷(REP), 225샷(REP)</b></div>
              </div>
              <div class="info-item">
                <div>시술 부위</div>
                <div>눈가</div>
              </div>
              <div class="info-item">
                <div>시술 효과</div>
                <div>눈가 주름 완화, 콜라겐 재생</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="reference">
      <div class="section-inner">
        <b> Reference</b><br />

        1.식품의약품안전처 허가증(2018) Thermage FLX System. 수허 18-245 2. 식품의약품안전처 허가증(2018) 써마지트리트먼트팁. 수인 17-4032.<br />
        <br />

        •Thermage FLX™ 제품은 "의료기기" 이며, "사용상의 주의사항"과 "사용방법"을 잘 읽고 사용하십시오.<br />
        •심의확인번호: 조합2023-42-038 (유효기간 2026.12.19)
        <p class="part-number mt-6">KTMG-20231012-027, KTMG-20240620-088</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.page.tips {
  background: linear-gradient(#ffffff, #ebe5e1);
  .tip-list {
    margin-top: 150px;
    .item-box {
      display: flex;
      align-items: center;
      border: 1px solid var(--gray-light-2);
      background: #f9f6f3;
      padding: 40px 20px;
      box-shadow: 2px 4px 16px 0px rgba(0, 0, 0, 0.09);
      margin-bottom: 30px;
      .tip-img {
        width: 35%;
        padding: 20px;
        text-align: center;
        img {
          width: 250px;
        }
      }
      .tip-info {
        width: 65%;
        padding: 20px;
        .item-title {
          font-size: 35px;
          border-bottom: 1px solid $primary-color;
          padding-bottom: 15px;
          margin-bottom: 20px;
        }
        .info-item {
          display: flex;
          > div {
            margin: 15px 15px 15px 0;
            &:first-child {
              font-weight: bold;
              width: 80px;
            }
            &:last-child {
              &::before {
                content: "";
                display: inline-block;
                width: 1px;
                height: 20px;
                background: $chrome-color;
                margin-right: 20px;
                vertical-align: middle;
              }
            }
          }

          b {
            color: $point-color;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  div.page.tips {
    .tip-list {
      margin-top: 0;
      .item-box {
        flex-direction: column;
        .tip-img {
          width: 100%;
          img {
            width: 200px;
          }
        }
        .tip-info {
          width: 100%;
          .item-title {
            font-size: 25px;
          }
          .info-item {
            flex-direction: column;
            margin-bottom: 20px;
            > div {
              margin: 0;
              &:first-child {
                font-weight: bold;
                width: 80px;
              }
              &:last-child {
                &::before {
                  content: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
<template>
  <div>
    <!-- 첨부파일 처리 -->
    <div v-if="fileInfo === null">
      <input type="file" :accept="accept" id="file" ref="file" @change="fileUploadHandler" />
    </div>
    <!-- 첨부파일 정보 받은경우 :label="`Checkbox 1: ${checkbox.toString()}`"-->
    <div v-else>
      <a :href="fileInfo.fullURL" target="_blank">
        <img :src="fileInfo.fullURL" width="100" :alt="fileInfo.filename" v-if="fileInfo.isImageFile" />
        <span v-else>다운로드</span>
      </a>
      <span class="_gap"></span>
      <a-checkbox v-model="checkbox" @change="checkbox_change">
        {{ fileInfo.filename }}
        <span class="text-delete">{{ checkbox_label }}</span>
      </a-checkbox>
    </div>
  </div>
</template>
<script>
import AWS from 'aws-sdk';
import $ from 'jquery';

export default {
  props: {
    // 초기 표시 라벨
    defLabel: {
      default: '',
      type: String,
    },
    // 업로드 할 디렉토리 * 없으면 빈값~
    uploadDir: {
      default: '',
      type: String,
    },
    // 파일해더 * 업로드 된 파일의 헤더(*필수로 넣어주세요) 예: RPM-File_YYYYMMDDHHmmss.jpg
    fileHeader: {
      default: '',
      type: String,
    },
    // 업로드 된 파일 정보를 받은경우
    fileInfo: {
      default: null,
      type: Object,
    },
    // 업로드 된 파일 정보를 받은경우
    fileDeleteOpt: {
      default: false,
      type: Boolean,
    },
    // 업로드 가능 파일 규칙
    accept: {
      default: '',
      type: String,
    },
  },
  // 초기화
  watch: {
    fileInfo() {
      this.file = null;
    },
  },
  data() {
    return {
      file: null,
      s3: null,
      albumBucketName: process.env.VUE_APP_S3_BUCKET,
      bucketRegion: process.env.VUE_APP_S3_REGION,
      IdentityPoolId: process.env.VUE_APP_S3_POOLID,
      checkbox: '',
      checkbox_label: '',
    };
  },
  created() {
    AWS.config.update({
      region: this.bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: this.IdentityPoolId,
      }),
    });

    this.s3 = new AWS.S3({
      apiVersion: '2006-03-01',
      params: { Bucket: this.albumBucketName },
    });
  },
  methods: {
    _reset() {
      $('input[type=file]').val('');
      this.checkbox = false;
      this.checkbox_label = '';
    },
    // 삭제처리
    checkbox_change(e) {
      this.checkbox = e.target.checked;
      this.checkbox_label = this.checkbox ? '삭제' : '';
      this.$emit('changeDeleteOpt', this.checkbox);
    },
    // 첨부파일
    fileUploadHandler(file) {
      this.file = this.$refs.file.files[0];
      this.uploadFileToS3();
    },
    uploadFileToS3() {
      const _self = this;
      if (this.file === null) {
        this.$emit('uploadComplete', null);
        return;
      }
      const photoKey = this.file.name; // 파일명
      const getTime = this.$moment().format('YYYYMMDDHHmmss');
      const _lastDot = photoKey.lastIndexOf('.');
      const _fileExt = photoKey.substring(_lastDot, photoKey.length).toLowerCase();

      let upload_root = 'thermage/';
      if (this.uploadDir !== '') {
        upload_root = upload_root + this.uploadDir + '/';
      }
      let FILE_HEADER = 'THERMAGE-File';
      if (this.fileHeader !== '') {
        FILE_HEADER = this.fileHeader;
      }

      // 리네임 처리
      const renameFile = `${FILE_HEADER}_${getTime}${_fileExt}`;
      let upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: this.albumBucketName,
          Key: upload_root + renameFile,
          Body: this.file,
        },
      });

      let promise = upload.promise();

      promise.then(
          (data) => {
            // alert('Successfully uploaded photo.');
            // console.log(data);
            _self._reset();
            _self.$emit('uploadComplete', data);
          },
          (err) => {
            return alert('There was an error uploading your photo: ', err.message);
          }
      );
    },
  },
};
</script>

<style lang="scss">
._gap {
  display: inline-block;
  width: 10px;
}
.text-delete {
  color: #ff000a;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}
</style>

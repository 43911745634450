<template>
  <div class="page-section feature">
    <div class="section-inner">
      <div class="section-title">
        <h1 class="main-title">써마지<sup>®</sup>란?</h1>
      </div>

      <div class="inner-text">
        써마지<sup>®</sup>의 <b>고주파 에너지</b>가 피부에 도달하면
        <b>피부 속 콜라겐을 재생</b>하여 <b>주름을 개선</b>합니다.
      </div>
      <div class="feature-list">
        <div class="list-row">
          <div class="list-item">
            <div class="icon">
              <img class="img-icon" src="@/assets/images/brand/icon/icon_research.png" alt="" />
              <img class="img-badge" src="@/assets/images/brand/icon/badge_research.png" alt="" />
            </div>
            <p>
              연구 개발 및 임상<sup>3</sup><br />
              <b>20년</b> 이상
            </p>
          </div>
          <div class="list-item">
            <div class="icon">
              <img class="img-icon" src="@/assets/images/brand/icon/icon_collagen.png" alt="" />
              <img class="img-badge circle" src="@/assets/images/brand/icon/badge_collagen.png" alt="" />
            </div>
            <p>
              진피층<sup>2</sup><br />
              <b>콜라겐 재생</b>
            </p>
          </div>

          <div class="list-item">
            <div class="icon">
              <img class="img-icon" src="@/assets/images/brand/icon/icon_evenly.png" alt="" />
              <!-- <img
                class="img-badge"
                src="@/assets/images/brand/icon/badge_research.png"
                alt=""
              /> -->
            </div>
            <p>
              <b>균일한<sup>4</sup></b><br />
              에너지 전달
            </p>
          </div>
        </div>
        <div class="list-row">

         
          <div class="list-item">
            <div class="icon">
              <img class="img-icon" src="@/assets/images/brand/icon/icon_home.png" alt="" />
              <img class="img-badge circle" src="@/assets/images/brand/icon/badge_home.png" alt="" />
            </div>
            <p>
              시술 후 바로<br />
              <b>일상 생활 가능(no down time)<sup>5</sup></b>
            </p>
          </div>
          <div class="list-item">
            <div class="icon">
              <img class="img-icon" src="@/assets/images/brand/icon/icon_needle.png" alt="" />
              <img class="img-badge circle" src="@/assets/images/brand/icon/badge_needle.png" alt="" />
            </div>
            <p>
              <b>비침습적<sup>2</sup></b><br />
              시술
            </p>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="">
</style>
<template>
  <div class="page find-pw">
    <div class="page-section">
      <FindPW_enter @emitFindPW="findPWHandler" />
    </div>
  </div>
</template>

<script>
import FindPW_enter from "./FindPW_enter.vue";

export default {
  components: { FindPW_enter },
  data() {
    return {};
  },
  methods: {
    findPWHandler() {
      this.$emit("moveToEmit", "login");
      window.location.href = "/login";
    },
  },
};
</script>

<style lang="scss">
.find-pw {
  background: linear-gradient(#ffffff, #ebe5e1);
  .page-section {
    text-align: center;
    height: calc(100vh - 70px);
    display: flex;
    align-items: center;
  }
}
@media (max-width: 768px) {
  div.find-pw {
    .page-section {
      height: auto;
    }
  }
}
</style>
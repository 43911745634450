<template>
  <div class="page find-id">
    <div class="page-section">
      <FindID_enter />
    </div>
  </div>
</template>

<script>
import FindID_enter from "./FindID_enter.vue";
export default {
  components: { FindID_enter },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">
.find-id {
  background: linear-gradient(#ffffff, #ebe5e1);
  .page-section {
    text-align: center;
    height: calc(100vh - 70px);
    display: flex;
    align-items: center;
  }
}
@media (max-width: 768px) {
  div.find-id {
    .page-section {
      height: auto;
    }
  }
}
</style>
<template>
  <div class="terms">
    <h4>안내사항</h4>
    <br />
    1. A/S는 제품의 시리얼넘버와 구매 이력을 확인 후 담당자가 순차적으로 안내해드립니다.<br/>
    2. 국내수리: A/S에 필요한 부품 재고가 없어 부품 수급이 필요한 경우 수리기간은 2개월 이상 소요될 수 있습니다.<br/>
<span class="leftTab">미국수리: 국내 A/S가 불가능하여 미국에서 수리를 진행해야 하는 경우 수리기간은 3개월 이상 소요될 수 있습니다.<br/></span>
    3. 수리할 제품에 명판이 없거나 당사 취급 이외의 제품(병행 수입 제품 및 불법 개조물 등)의 경우 제품 A/S 대상에서 제외됩니다.<br/>
  </div>
</template>
  
<script>
export default {};
</script>
  
<style>
.terms li {
  list-style: disc;
}
.leftTab {
  margin-left: 20px;
}
</style>

<template>
  <div>
    <v-chip-group v-model="selected" column multiple>
      <v-chip
        filter
        small
        outlined
        v-for="(tag, i) in thisTags"
        :key="i"
        @click="selectTagAction(tag)"
      >
        {{ tag.tag }}
      </v-chip>
    </v-chip-group>
    <v-chip-group v-model="amenities" column multiple>
      <v-chip filter variant="outlined"> Elevator </v-chip>
      <v-chip filter variant="outlined"> Washer / Dryer </v-chip>
      <v-chip filter variant="outlined"> Fireplace </v-chip>
      <v-chip filter variant="outlined"> Wheelchair access </v-chip>
      <v-chip filter variant="outlined"> Dogs ok </v-chip>
      <v-chip filter variant="outlined"> Cats ok </v-chip>
    </v-chip-group>
  </div>
</template>
<script>
export default {
  data() {
    return {
      amenities: [1, 4],
      selected: [0, 1],
      thisTags: [{ tag: "1234" }, { tag: "4567" }],
    };
  },
  methods: {
    selectTagAction(e) {},
  },
};
</script>
<style lang="">
</style>
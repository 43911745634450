<template>
	<div class="old-policy">
		<div>
			<strong>개인정보처리방침</strong><br />
		본 사이트는 ㈜바슈헬스코리아(이하 "회사")의 소유입니다. 개인정보 처리방침은 이용자들이 본 사이트에 제공한 정보의 수집, 사용 및 공개에 관한 방침을 전달하기 위해 마련되었습니다. 이용자들께서는 본
		사이트를 이용하기 전에 반드시 이에 대한 전체 내용을 읽어보십시오.
		<br /><br />
		이 사이트는 대한민국 거주자들의 사용을 목적으로 제작되었음을 알려드립니다.
		<br /><br />
		회사가 소유 및 운영하는 본 웹사이트는 이용자의 개인정보를 보호하기 위하여 정보통신망 이용촉진 및 정보보호 등에 관한 법률」 및 「개인정보 보호법」 등 관련 법령에 따라 다음과 같은 개인정보 처리방침을
		가지고 있습니다.<br />
		회사는 본 개인정보 처리방침을 통하여 이용자의 개인정보가 어떠한 목적과 방식으로 수집ㆍ이용되며, 이용자의 개인정보를 보호하기 위해 회사가 어떠한 조치를 취하고 있는지 알려드립니다.<br />
		회사는 만 14세 미만 아동의 개인정보를 수집하는 경우에는 그 법정대리인의 동의를 받습니다. 법정대리인의 동의를 얻기 위하여 회사는 아동으로부터 법정대리인의 성명, 연락처 등 최소한의 정보를
		수집합니다.
		<br /><br /><br />


		<strong>1. 수집하는 개인정보 항목</strong><br />
		회사가 제공하는 서비스들은 회원가입을 하지 않고 이용이 가능합니다. 홈페이지 등에서 이용자로부터 직접 입력받는 방식과 자동으로 수집되는 방식으로 개인 정보를 수집합니다.
		<br /><br />
		가. 직접 입력하는 정보<br />
		- 고객의견함 이용 시 : 성명, 이메일주소, 비밀번호, 문의 내용 및 기타 고객이 직접 입력한 내용<br />
		- 정품인증이벤트 참여 시 : 성명, 연락처, 시술 병원명, 시술 병원 소재지, 시술 샷수, 시술 일자, 주소<br />
		- 기타 이벤트 참여 시 : 성명, 연락처 및 고객의 동의를 받아 수집하는 기타 개인정보
		<br />
		나. 자동 수집 : 서비스 이용기록, 접속 로그, 쿠키, 접속 IP정보 등
		<br /><br /><br />


		<strong>2. 수집 및 이용하는 목적</strong><br />
		- 회사 제품의 안내를 포함한 정보 전달 및 취득 활동의 수행<br />
		- 안내 및 알림 서비스 제공, 이벤트 진행 등의 마케팅 활동 수행<br />
		- 문의사항 및 웹사이트 이용에 대한 통계 산출
		<br /><br /><br />


		<strong>3. 개인정보 제3자 제공에 관한 사항</strong><br />
		회사는 이용자로부터 별도의 동의를 받거나 법령상 근거가 있는 경우를 제외하고는 이용자의 개인정보를 제3자에게 제공하지 않습니다.
		<br /><br /><br />


		<strong>4. 개인정보 자동 수집 장치의 설치, 운영 및 그 거부에 관한 사항</strong><br />
		회사는 이용자의 정보를 수시로 저장하고 찾아내는 '쿠키(Cookie)'를 사용하여 개인정보를 자동으로 수집하는 장치를 설치 및 운용합니다.<br />
		쿠키란 본 사이트를 운영하는 데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터 하드디스크에 저장됩니다. 쿠키는 이용자의 컴퓨터는 식별하지만 이용자를 개인적으로
		식별하지는 않습니다.<br />
		회사는 접속 빈도나 방문 시간 분석, 자취 추적, 각종 이벤트 참여 정도 파악 등을 통한 본 사이트의 사용성 개선 및 마케팅 활용을 위하여 쿠키를 사용합니다.<br />
		이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹 브라우저에서 옵션을 선택함으로써 모든 쿠키의 저장을 허용 또는 거부하거나, 쿠키를 저장할 때마다 확인을 거치도록 할 수
		있습니다.<br />
		설정방법의 예시(인터넷 익스플로러의 경우) : 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보에서 설정. 단, 이용자가 쿠키 설치를 거부한 경우에는 서비스 제공에 어려움이 있을 수 있습니다.
		<br /><br /><br />


		<strong>5. 개인정보의 취급위탁</strong><br />
		회사는 이용자에게 서비스를 제공하기 위해 다음과 같이 개인정보 취급업무를 위탁하고 있습니다.<br />
		위탁업체 : (주)비엑스엠<br />
		주요업무 : 웹사이트의 구축 및 등록, 고객 DB관리, 사이트 유지보수, 웹디자인 개발, 시스템 운영 및 이벤트 프로그램 개발 및 관리, 웹사이트 이용 및 문의사항에 대한 기록 및 통계 산출
		<br /><br /><br />


		<strong>6. 개인정보의 보유 및 이용 기간과 파기절차</strong><br />
		회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
		<br /><br />
		- 파기절차<br />
		이용자가 정품인증 등을 위해 제공한 정보는 목적이 달성된 후 회사 내부방침 및 관련 법령에 따라(개인정보의 보유 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다.<br />
		- 파기방법<br />
		전자적 파일형태로 저장된 개인정보는 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
		<br /><br /><br />


		<strong>7. 이용자 및 법정대리인의 권리와 그 행사방법</strong><br />
		이용자 및 법정대리인은 언제든지 회사가 수집ㆍ보관 중인 자신 또는 만 14세 미만 아동의 개인정보에 대한 열람ㆍ정정을 요구할 수 있으며, 개인정보의 수집, 이용 등에 대한 동의를 철회할 수
		있습니다.<br />
		개인정보의 수집, 처리에 대하여 동의를 거부하실 권리가 있습니다. 다만, 귀하의 동의 거부 시 확인이이벤트 참여나 고객의견함 등의 이용이 어려움을 알려드립니다.<br />
		또한 서면, 전화, E-mail을 이용하여 개인정보의 열람ㆍ정정 또는 동의철회를 요청할 수 있습니다. 이 경우 회사는 본인여부 확인 후 필요한 조치를 취합니다.<br />
		<br /><br /><br />


		<strong>8. 개인정보 보호를 위한 기술적, 관리적 보호 장치</strong><br />
		회사는 이용자의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적ㆍ관리적 대책을 강구하고 있습니다.<br />
		개인정보의 훼손에 대비하여 자료를 수시로 백업하고, 개인정보가 유출되거나 손상되지 않도록 최신 백신프로그램을 설치ㆍ운용하고 있으며, 암호화기술 등의 이용을 통하여 개인정보가 네트워크상에서 안전하게
		전송될 수 있도록 노력하고 있습니다.<br />
		침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템 보안을 확보하기 위한 기술적 장치를 갖추려 노력하고 있습니다.<br />
		회사의 개인정보관련 취급은 담당자에 한정시키고, 담당자에게 별도의 비밀번호를 부여하고 이를 정기적으로 갱신하고 있으며, 담당자에 대한 교육을 통하여 본 개인정보처리방침의 준수를 강조하고
		있습니다.<br />
		회사가 개인정보처리자로서 의무를 다하였음에도 불구하고, 이용자 본인의 부주의나 회사가 관리하지 않는 영역에서의 개인정보 유출 사고 등 회사의 귀책에 기인하지 않은 손해에 대해서는 회사는 일체의 책임을
		지지 않습니다.
		<br /><br /><br />


		<strong>9. 링크사이트</strong><br />
		회사는 이용자에게 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다.<br />
		이 경우 회사는 외부 웹사이트 및 자료에 대하여 아무런 통제권이 없으므로 그로부터 제공되는 서비스나 자료의 유용성에 대해 책임질 수 없으며 보증할 수 없습니다.<br />
		본 웹사이트에 있는 링크를 클릭하여 외부 웹사이트로 옮겨갈 경우 해당 사이트에는 본 개인정보처리방침이 적용되지 아니하므로, 새로 방문한 사이트의 개인정보처리방침을 검토하시기 바랍니다.<br />
		<br /><br /><br />


		<strong>10. 개인정보에 대한 민원 및 보호책임자</strong><br />
		이용자는 회사의 서비스를 이용하며 발생하는 모든 개인정보보호 관련 민원을 고객센터로 신고하실 수 있습니다. 회사는 이용자의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
		<br /><br />
		[개인정보 보호 책임자]<br />
		담당부서 : Solta Medical Korea<br />
		성명 : 한상진<br />
		전화 : 1544-8330<br />
		이메일 : james.han@solta.com
		<br /><br />
		[개인정보 관리 담당자]<br />
		성명 : 정정아 대리, 최지원<br />
		전화 : 02-3453-7653<br />
		이메일 : JeongAh.Jeong@bausch.com, jiwon.choi@solta.com
		<br /><br />
		기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.<br />
		- 개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)<br />
		- 대검찰청 사이버수사과 (www.spo.go.kr / 국번없이 1301)<br />
		- 경찰청 사이버안전국 (www.cyber.go.kr / 국번없이 182)
		<br /><br /><br />


		<strong>11. 고지 의무</strong><br />
		본 개인정보처리방침은 관련 법령의 개정, 회사의 정책, 보안기술의 변경 등에 의해 변경될 수 있으므로 회원가입 시 또는 본 웹사이트 방문ㆍ이용 시 수시로 확인하여 주시기 바랍니다.<br />
		본 개인정보처리방침의 변경이 있을 경우에는 본 웹사이트 첫 화면의 공지사항 란 또는 별도의 창이나, E-mail을 통하여 변경내용을 사전에 공지하도록 하겠습니다.<br />
		이 개인정보 처리방침의 내용은 2019년 11월 10일 부터 효력이 발생됩니다.
		</div>
		
	</div>
</template>
            
<script>
import $ from "jquery";
export default {
	created() {
		if (this.$route.path === "/old_policy") {
			console.log(this.$route.path);
			setTimeout(() => {
				$('#topNav').css('display', 'none');
				$('.floating-buttons').css('display', 'none');
				$('footer').css('display', 'none');

			}, 200);


		};
	}
};
</script>

<style lang="scss">
.old-policy {
	padding: 40px;
	// border: 10px solid #691c32;
	font-size: 14px;
	color: #333;
	background-color: #ffffff;
	position: relative;
	z-index: 2000;
	>div {
		margin: 80px auto;
		width: 90vw;
	}
}

.old-policy strong {
	font-size: 18px;
	// color: #691c32;
	font-weight: bold;
}


@media (max-width:780px) {
	.old-policy {
		margin: 40px 40px;
		padding: 20px;
		font-size: 13px;
	}

	.old-policy strong {
		font-size: 15px;
		// color: #691c32;
	}
}

@media (max-width:640px) {
	.old-policy {
		margin: 0 0;
		padding: 10px;
	}
}
</style>
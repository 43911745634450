<template>
  <div class="page inquiry-enter">
    <div class="page-inner">
      <div class="page-title">
        <h1 class="main-title primary--text">문의하기</h1>
      </div>
      <v-form ref="form">
        <v-select
          :items="itemsInquiryType"
          label="문의 유형 선택"
          solo
          required
          ref="refInquiryType"
          v-model="inquiryModel.mainType"
        ></v-select>
        <v-text-field
          label="성함"
          ref="refName"
          solo
          height="52"
          required
          v-model="inquiryModel.name"
          :rules="[nameRules.required, nameRules.counter]"
        ></v-text-field>
        <v-text-field
          label="휴대전화번호"
          ref="refCell"
          solo
          height="52"
          required
          v-model="inquiryModel.phone"
          type="text"
          :rules="[cellRules.required, cellRules.counter, cellRules.check]"
        >
        </v-text-field>
        <v-text-field
          label="이메일"
          ref="refEmail"
          solo
          height="52"
          required
          v-model="inquiryModel.email"
          :rules="[emailRules.required, emailRules.email]"
        ></v-text-field>
        <v-text-field
          solo
          height="52"
          label="비밀번호"
          required
          :rules="[passwordRules.required]"
          type="password"
          v-model="inquiryModel.password"
        ></v-text-field>
        <v-textarea
          outlined
          placeholder="문의 내용을 입력해주세요."
          v-model="inquiryModel.contents"
        ></v-textarea>
        <div class="terms-box">
          <TermsInquiry />
        </div>
        <p class="input-guide input-top">
          ※ 귀하는 개인정보의 수집 및 이용에 대하여 동의하지 않을 수 있습니다.
          <br />
          다만, 동의하지 않을 경우 고객의견함의 이용이 불가능합니다.
        </p>
        <v-radio-group row v-model="inquiryModel.agree">
          <v-radio
            key="1"
            label="위 개인정보 수집 및 이용에 동의합니다."
            value="Y"
          ></v-radio>
          <v-radio key="2" label="동의하지 않습니다." value="N"></v-radio>
        </v-radio-group>
        <div class="bottom-wrap">
          <v-btn color="primary" x-large depressed block @click="clickSubmit"
            >확인</v-btn
          >
          <v-btn x-large depressed block @click="$router.push('/cs/inquiry')"
            >취소</v-btn
          >
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
const rulesService = require("@/utils/rules.js");
const formatService = require("@/utils/format.js");
const modelService = require("@/utils/dataModel.js");
import TermsInquiry from "@/components/thermage/common/TermsInquiry.vue";

export default {
  components: {
    TermsInquiry,
  },
  data() {
    return {
      nameRules: rulesService.nameRules(),
      cellRules: rulesService.cellRules(),
      emailRules: rulesService.emailRules(),
      passwordRules: rulesService.passwordRules(),
      itemsInquiryType: formatService.itemsInquiryType(),
      isDev: process.env.NODE_ENV === "development",
      inquiryModel: modelService.inquiryModel(),
    };
  },
  mounted() {
    // this.getMe().then(() => {
    //   setTimeout(() => {
    //     console.log("", this.me);
    //     this.inquiryModel.name = this.me.name;
    //     this.inquiryModel.phone = this.me.cell;
    //     this.inquiryModel.email = this.me.email;
    //     this.inquiryModel.user_id = this.me.user_id;
    //     if (this.isDev) {
    //       this.inquiryModel.password = "1234";
    //       this.inquiryModel.contents = "contents";
    //     }
    //   }, 500);
    // });
  },
  methods: {
    clickSubmit() {
      //필수
      if (!this.$refs.form.validate()) return;

      // 제품문의 유형 처리 필요
      if (this.inquiryModel.mainType === "") {
        this.$helper.showTostMessage(
          "top",
          "문의 유형을 선택해주세요.",
          "error"
        );
        return;
      }

      // 제품내용 확인
      if (this.inquiryModel.contents === "") {
        this.$helper.showTostMessage(
          "top",
          "문의 내용을 입력해주세요.",
          "error"
        );
        return;
      }

      if (
        this.inquiryModel.agree === "" ||
        this.inquiryModel.agree === null ||
        this.inquiryModel.agree !== "Y"
      ) {
        this.$helper.showTostMessage(
          "top",
          "개인정보 수집 및 이용에 동의해 주세요",
          "error"
        );
        return;
      }
      // console.log("", this.inquiryModel);
      // return;

      this.saveDataQuery(this.inquiryModel).then((res) => {
        if (res === undefined) return;
        this.$helper.showTostMessage(
          "top",
          "문의하기가 정상적으로 처리되었습니다",
          "success"
        );

        this.$router.push("/cs/inquiry");
      });
    },
    ...mapActions(["saveDataQuery", "getMe"]),
  },
  computed: {
    ...mapGetters(["isAuthorized"]),
    ...mapState(["me"]),
  },
};
</script>

<style lang="scss">
@media (max-width: 768px) {
  div.inquiry-enter {
    .bottom-wrap {
      .v-btn--block {
        display: flex;
        min-width: 100% !important;
        &:last-child {
          margin-left: 0;
          margin-top: 12px;
        }
      }
    }
  }
}
.inquiry-enter {
  background: linear-gradient(#ffffff, #ebe5e1);
  padding-bottom: 100px;
  .page-inner {
    width: 65vw;
    max-width: 800px;
  }
  .terms-box {
    font-size: 16px;
    letter-spacing: normal;
    max-width: 100%;
    text-align: left;
    padding: 12px;
    margin-bottom: 12px;
    background: #ffffff;
    min-height: 56px;
    height: 160px;
    overflow: auto;
  }
  .bottom-wrap {
    margin: 5rem 0 1rem;
    .v-btn--block {
      display: inline-flex;
      min-width: initial !important;
      width: calc(50% - 6px);
      &:last-child {
        margin-left: 12px;
      }
    }
  }
}
</style>
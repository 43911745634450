import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"500px","content-class":"common-dialog"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardText,[_c(VCarousel,{attrs:{"show-arrows":false,"height":"","cycle":"","interval":"4000"}},_vm._l((_vm.data),function(item,index){return _c(VCarouselItem,{key:index},[_c('a',{attrs:{"href":item.contents,"target":item.linkType}},[_c('img',{attrs:{"src":item && item.detailTextImg,"alt":""}})])])}),1)],1),_c(VCardActions,[_c(VCheckbox,{attrs:{"label":"오늘 하루 보지 않기"},on:{"click":_vm.checkModalToday},model:{value:(_vm.blockToday),callback:function ($$v) {_vm.blockToday=$$v},expression:"blockToday"}}),_c(VBtn,{attrs:{"color":"primary","depressed":""},on:{"click":_vm.ok}},[_vm._v(" 닫기 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from "vue";
import Vuex from "vuex";

import state from "./states.js";
import getters from "./getters.js";
import mutations from "./mutations.js";
import actions from "./actions.js";

import mCommon from "./module/CommonInfo.js";
import certify from "./module/certify.js";
import loginCheck from "./module/loginCheck.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    mCommon,
    loginCheck,
    certify
  },
  state,
  getters,
  mutations,
  actions,
});

<template>
  <div class="user-profile">
    <div class="section-inner">
      <div class="section-header">
        <img
          class="top-logo"
          src="@/assets/images/common/thermage_logo.png"
          alt="app_logo"
        />
        <p class="sub-title">
          {{ this.$route.path == "/login" ? "가입정보를 입력해주세요." : "" }}
        </p>
      </div>

      <!-- 회원가입페이지 -->
      <v-form ref="form">
        <v-text-field
          label="성함"
          ref="refName"
          solo
          :height="formHeight"
          required
          v-model="userData.name"
          :rules="[nameRules.required, nameRules.counter]"
          :disabled="userData.signupPath === 'kakao'"
        />

        <!-- 휴대폰 체크 -->
        <SignUpCellCheck
          v-if="userData.signupPath !== 'kakao'"
          :userData="userData"
        />

        <v-text-field
          solo
          label="아이디(이메일)"
          :height="formHeight"
          required
          v-model="userData.loginId"
          :rules="[emailRules.required, emailRules.email]"
          :disabled="userData.signupPath === 'kakao'"
        />
        <v-text-field
          solo
          :height="formHeight"
          label="비밀번호"
          required
          type="password"
          v-model="userData.password"
          :rules="[
            passwordRules.required,
            passwordRules.counter,
            passwordRules.check,
          ]"
          :disabled="userData.signupPath === 'kakao'"
        />
        <v-text-field
          solo
          :height="formHeight"
          label="비밀번호 확인"
          required
          type="password"
          v-model="userData.rePassword"
          :rules="[
            passwordRules.required,
            passwordRules.counter,
            passwordConfirmationRule,
          ]"
          :disabled="userData.signupPath === 'kakao'"
        />

        <v-text-field
          solo
          label="소속"
          ref="refHospital"
          :height="formHeight"
          required
          v-model="userData.hospital"
        />
        <v-select
          solo
          :items="itemsSpecialty"
          label="전공과"
          :height="formHeight"
          required
          ref="refMajors"
          v-model="userData.majors"
        />

        <!-- 버튼 처리 -->
        <div class="bottom-wrap">
          <v-btn color="primary" x-large depressed block @click="clickSummit">{{
            pageName === "mypage" ? "수정하기" : "가입하기"
          }}</v-btn>
          <v-btn color="" x-large depressed block @click="clickCancel"
            >취소</v-btn
          >
        </div>
        <v-btn
          v-if="pageName === 'mypage'"
          x-large
          text
          block
          @click="clickWithdrawal"
        >
          탈퇴하기
        </v-btn>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SignUpCellCheck from "./SignUpCellCheck.vue";
const rulesService = require("@/utils/rules.js");
const formatService = require("@/utils/format.js");

export default {
  components: { SignUpCellCheck },
  props: {
    userData: {
      type: Object,
    },
    pageName: {
      type: String,
      default: "signup",
    },
  },
  data() {
    return {
      formHeight: 52,
      nameRules: rulesService.nameRules(),
      cellRules: rulesService.cellRules(),
      emailRules: rulesService.emailRules(),
      passwordRules: rulesService.passwordRules(),
      itemsSpecialty: formatService.specialty(),
    };
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.userData.password === this.userData.rePassword ||
        "패스워드가 일치해야 합니다.";
    },
    ...mapGetters(["isAuthorized"]),
  },
  methods: {
    clickSummit() {
      // 필수체크(이름 / 생년월
      const DT = this.userData;
      const RF = this.$refs;
      // console.log(DT);

      // 로그인
      if (!this.isAuthorized && !DT.checkSms) {
        this.$helper.showTostMessage(
          "top",
          "[인증번호 받기]를 선택해주세요",
          "info"
        );
        return;
      }

      if (DT.checkSms && !DT.checkedSms) {
        this.$helper.showTostMessage(
          "top",
          "[인증완료]를 선택해주세요",
          "info"
        );
        return;
      }

      if (
        !this.$helper.requiredCheck(
          DT.hospital,
          RF.refHospital,
          `소속을 입력해주세요`
        )
      )
        return;

      if (
        !this.$helper.requiredCheck(
          DT.majors,
          RF.refMajors,
          `전공를 입력해주세요`
        )
      )
        return;

      // 생년월일 미래날짜 안되게 처리
      // if (this.$moment().diff(this.userData.birthday, "days") < 0) {
      //   Vue.$toast.open({
      //     position: "top",
      //     message: `생년월일을 확인해주세요.`,
      //     type: "error",
      //     duration: 1000,
      //     dismissible: true,
      //   });
      //   return;
      // }

      if (!this.$refs.form.validate()) return;

      this.$emit("clickSummitEmit");
    },
    clickWithdrawal() {
      this.$emit("clickWithdrawal");
    },
    clickCancel() {
      this.$emit("clickCancel");
    },
  },
};
</script>

<style lang="scss">
.user-profile {
  padding: 8rem 0 12rem;
  .section-inner {
    max-width: 800px;
    width: 65vw;
  }
  .section-header {
    padding: 0 1rem 2.5rem;
    text-align: center;
    .input-guide {
      margin-top: 1.5rem;
      color: $primary-color;
    }
  }
  .row {
    margin: 0;
    .col {
      padding: 0;
      .v-btn {
        min-width: calc(100% - 10px);
        padding: 0;
        margin-left: 10px;
      }
    }
  }
  .v-btn-toggle {
    width: 100%;
    margin-bottom: 30px;
    .v-btn {
      width: 50%;
    }
  }
  .bottom-wrap {
    margin: 5rem 0 1rem;
    .v-btn--block {
      display: inline-flex;
      min-width: initial !important;
      width: calc(50% - 6px);
      &:last-child {
        margin-left: 12px;
      }
    }
  }
}
@media (max-width: 768px) {
  div.user-profile {
    padding: 4rem 0 8rem;
    .row-cell {
      .col-9 {
        flex: 0 0 60%;
        max-width: 60%;
      }
      .col-3 {
        flex: 0 0 40%;
        max-width: 40%;
      }
    }
    .row-code {
      flex-direction: column;
      margin-bottom: 24px;
      .col {
        max-width: 100%;
        .v-btn {
          min-width: 100%;
          margin-left: 0;
        }
      }
    }
    .bottom-wrap {
      .v-btn--block {
        display: flex;
        min-width: 100% !important;
        &:last-child {
          margin-left: 0;
          margin-top: 12px;
        }
      }
    }
  }
}
</style>
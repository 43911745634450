<template>
  <div class="page inquiry">
    <div class="page-inner">
      <div class="page-title">
        <h1 class="main-title primary--text">문의 목록</h1>
      </div>

      <InquiryList ref="refList" @clickRow="clickRow" />
    </div>

    <div class="bottom-button">
      <v-btn
        color="primary"
        x-large
        depressed
        @click="$router.push('/cs/inquiry_enter')"
        >문의하기</v-btn
      >
    </div>

    <!-- 비번확인 -->
    <InquiryBoard_PW_Dialog
      ref="InquiryBoard_PW_Dialog"
      @clickOk="clickDialogOkHandler"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import InquiryList from "./comp/inquiry/InquiryList.vue";
import InquiryBoard_PW_Dialog from "./InquiryBoard_PW_Dialog.vue";

const modelService = require("@/utils/dataModel.js");

export default {
  components: {
    InquiryList,
    InquiryBoard_PW_Dialog,
  },
  data() {
    return {
      inquiryModel: modelService.inquiryModel(),
    };
  },
  mounted() {
    // if (!this.isAuthorized) {
    //   // 이동 할 페이지 저장
    //   this.loginCheck(this.$route.fullPath);
    //   this.$router.push("/login").catch(() => {});
    //   return;
    // }
    this.getData();
  },
  methods: {
    changeSearchVale(e) {
      this.inquiryModel.searchVal = e;
      this.getData();
    },
    getData() {
      this.getDataQuery(this.inquiryModel).then((resP) => {
        if (resP === undefined) return;
        let res = resP.data;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: res.length - index,
            contentsLbl: `${this.$helper.mainTypeLbl(
              item.mainType
            )} [${this.replyNum(
              item.inquiryCommentList.length,
              item.adminComment
            )}]`,
            regDateLabel: this.$helper.changeDateToStringForDp(
              item.regDate,
              true
            ),
            updDateLabel: this.$helper.changeDateToStringForDp(
              item.updDate,
              true
            ),
            nameDp: this.nameDp(item.wname),
          }));
        }
        // console.log(res);
        this.$refs.refList._updateData(res);
      });
    },
    replyNum(inquiryCommentLength, adminComment) {
      let returnVal = inquiryCommentLength;
      if (adminComment !== "" && adminComment !== null) returnVal++;
      return returnVal;
    },
    nameDp(name) {
      if (name === null) return "";
      // console.log('name', name.length, name.substring(0, 1));
      const first = name.charAt(0);
      let last = name.charAt(name.length - 1); //name.substring(-1);
      // const middleNum = name.length - 1;
      let middle = "*";
      if (name.length === 2) return first + "*";
      console.log("name", first, last);
      return first + middle + last;
    },
    clickRow(e) {
      this.$refs.InquiryBoard_PW_Dialog.open(e);
    },
    clickDialogOkHandler(e) {
      console.log("", e);
      this.$router.push("/cs/inquiry_detail/" + e.ipk);
    },
    ...mapActions(["loginCheck", "getDataQuery"]),
  },
  computed: {
    ...mapGetters(["isAuthorized"]),
    ...mapState(["me"]),
  },
};
</script>

<style lang="scss">
@media (max-width: 768px) {
  div.page.inquiry {
    .list-table {
      .v-data-table {
        .v-data-table__wrapper > table {
          > tbody > tr {
            border-bottom: thin solid rgba(0, 0, 0, 0.12);
            > td {
              padding: 5px 0;
              min-height: 30px;
              &:nth-child(1) {
                display: none;
              }
              &:nth-child(2) {
                width: auto;
                padding-top: 20px;
                font-size: 18px;
                font-weight: 600;
              }
              &:nth-child(3) {
                width: auto;
                color: $secondary-color;
                font-size: 14px;
                padding-bottom: 20px;
                display: table-cell;
              }
              &:nth-child(4) {
                position: relative;
                width: auto;
                color: $secondary-color;
                font-size: 14px;
                padding-bottom: 20px;
                display: table-cell;
                border-bottom: none;

                &::before {
                  content: "";
                  display: inline-block;
                  position: absolute;
                  top: 12px;
                  left: 0;
                  height: 10px;
                  width: 1px;
                  background-color: rgba(0, 0, 0, 0.12);
                }
              }

              &.v-data-table__mobile-row {
                .v-data-table__mobile-row__cell {
                  text-align: left;
                }
              }
              .v-data-table__mobile-row__header {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
.page.inquiry {
  background: linear-gradient(#ffffff, #ebe5e1);
  padding-bottom: 100px;
  .list-table {
    .v-data-table {
      background-color: transparent;
      border-radius: 0;
      .v-data-table__wrapper > table {
        > thead > tr > th {
          color: $primary-color;
        }
        > tbody > tr > td {
          padding: 25px 20px;
          font-size: 1rem;
          &:nth-child(1) {
            width: 80px;
          }
          &:nth-child(3) {
            width: 200px;
          }
          &:nth-child(4) {
            width: 150px;
          }
        }
        > thead > tr > th {
          padding: 25px 16px;
          font-size: 1rem;
        }
      }
    }
  }
  .bottom-button {
    text-align: center;
    margin: 40px 0;
    .v-btn {
      width: 300px;
    }
  }
}
</style>
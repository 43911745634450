<template>
  <div class="page notice">
    <div class="page-inner">
      <div class="page-title">
        <h1 class="main-title primary--text">공지사항</h1>
        <!-- <p class="sub-title">최신 이벤트를 확인해 보세요.</p> -->
      </div>

      <Search
        propsPlaceholder="공지사항을 검색해 보세요."
        @changeText="changeSearchVale"
      />

      <NoticeTopList ref="refTopList" />
      <NoticeList ref="refList" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Search from "@/components/thermage/common/Search.vue";
import NoticeTopList from "./comp/notice/NoticeTopList.vue";
import NoticeList from "./comp/notice/NoticeList.vue";

const modelService = require("@/utils/dataModel.js");

export default {
  components: {
    Search,
    NoticeTopList,
    NoticeList,
  },
  data() {
    return {
      bbsModel: modelService.bbsModel(),
      topList: [],
      List: [],
    };
  },
  mounted() {
    if (this.$route.path === "/cs/notice") {
      this.bbsModel.bbsGbn = "notice";
    }
    this.getData();
  },
  methods: {
    changeSearchVale(e) {
      console.log("검색", e);
      this.bbsModel.searchVal = e;
      this.getData();
    },
    getData() {
      this.topList = [];
      this.List = [];
      this.bbsModel.endpoint = "bbs/" + this.bbsModel.bbsGbn;
      this.getDataQuery(this.bbsModel).then((resP) => {
        if (resP === undefined) return;
        let res = resP.data;
        res.forEach((element) => {
          if (element.titleGbn === "공지") {
            this.topList.push(element);
          } else {
            this.List.push(element);
          }
        });

        let topList = this.topList;
        if (0 < topList.length) {
          topList = topList.map((item, index) => ({
            ...item,
            no: topList.length - index,
            regDateLabel: this.$helper.changeDateToStringForDp(
              item.regDate,
              false
            ),
            updDateLabel: this.$helper.changeDateToStringForDp(
              item.updDate,
              false
            ),
          }));
        }

        let list = this.List;
        if (0 < list.length) {
          list = list.map((item, index) => ({
            ...item,
            no: list.length - index,
            regDateLabel: this.$helper.changeDateToStringForDp(
              item.regDate,
              false
            ),
            updDateLabel: this.$helper.changeDateToStringForDp(
              item.updDate,
              false
            ),
          }));
        }
        // console.log("topRes", topList);
        this.$refs.refTopList._updateData(topList);
        this.$refs.refList._updateData(list);
      });
    },
    ...mapActions(["getDataQuery"]),
  },
};
</script>

<style lang="scss">
@media (max-width: 768px) {
  div.page.notice {
    .list-table {
      .v-data-table {
        &.fixed-notice {
          border-bottom: 1px solid $point-color;
          .v-data-table__wrapper > table {
            > tbody > tr > td {
              &:nth-child(1) {
                display: flex;
                width: auto;
                padding-top: 15px;
              }
              &:last-child {
                padding-top: 0;
                padding-bottom: 20px;
                font-weight: bold;
              }
              .badge {
                width: 40px;
                padding: 1px;
                font-size: 12px;
              }
            }
          }
        }
        .v-data-table__wrapper > table {
          > tbody > tr {
            > td {
              padding: 5px 15px;
              min-height: 30px;
              &:nth-child(1) {
                display: none;
              }
              &:nth-child(2) {
                width: auto;
                padding-top: 20px;
              }
              &:nth-child(3) {
                width: auto;
                color: $secondary-color;
                font-size: 14px;
                padding-bottom: 20px;
              }
              &.v-data-table__mobile-row {
                // display: table-cell;
                .v-data-table__mobile-row__cell {
                  text-align: left;
                }
              }
              .v-data-table__mobile-row__header {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
.page.notice {
  background: linear-gradient(#ffffff, #ebe5e1);
  padding-bottom: 100px;
  .list-table {
    .v-data-table {
      background-color: transparent;
      border-radius: 0;
      &.fixed-notice {
        border-bottom: 1px solid $point-color;
      }
      .v-data-table__wrapper > table {
        > tbody {
          // > tr:not(:last-child) {
          //   > td {
          //     border-bottom: thin solid rgba(0, 0, 0, 0.12);
          //   }
          // }
          > tr > td {
            padding: 25px 20px;
            font-size: 1rem;
            &:nth-child(1) {
              width: 100px;
            }
            &:nth-child(3) {
              width: 150px;
            }
            .badge {
              display: inline-block;
              border: 1px solid $point-color;
              padding: 1px 5px;
              text-align: center;
              color: $point-color;
            }
          }
        }

        > thead {
          display: none;
          > tr > th {
            padding: 25px 16px;
            font-size: 1rem;
          }
        }
      }
    }
  }
}
</style>
import Vue from 'vue';
const moment = require("moment");



export const requiredCheck = (itemId, itemRef, itemMsg, focusOption) => {
  if (itemId === '') {
    Vue.$toast.open({
      position: "top",
      message: `${itemMsg}`,
      type: 'error',
      duration: 2000,
      dismissible: true,
    });

    // combo일경우 제외
    if (focusOption === undefined) {
      itemRef.focus();
    }
    return false;
  }
  return true;
}

export const showTostMessage = (position, message, type) => {
  if (position === '') return;
  if (message === '') return;
  if (type === '') return;
  Vue.$toast.open({
    position: position,
    message: message,
    type: type,
    duration: 4000,
    dismissible: true,
  });
}

export const random_number = (min, max) => {
  return Math.floor(Math.random() * (max + 1 - min) + min);
}

export const generateRandomString = (num) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < num; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

/** 입력값이 NULL인지 체크 */
export const C_isNull = asValue => {
  if (asValue === null || asValue === undefined || asValue.toString().replace(/ /g, '') === '') {
    return true;
  }

  return false;
};

/** 숫자검증 */
export const C_isNum = asValue => {
  if (C_isNull(asValue)) {
    return false;
  }

  for (let i = 0; i < asValue.length; i++) {
    if (asValue.charAt(i) < '0' || asValue.charAt(i) > '9') {
      return false;
    }
  }

  return true;
};

/** nl2br 처리 */
export const js_nl2br = str => {
  if (str === '' || str == null) {
    return '';
  }
  return str.replace(/(\r\n|\n\r|\r|\n)/g, '<br />');
};

export const brBlank = str => {
  if (str === '' || str == null) {
    return '';
  }
  return str.replace(/(\r\n|\n\r|\r|\n)/g, '');
};

export const zero_plus = str => {
  let result;
  if (str.toString().length === 1) {
    result = '0' + str;
  } else {
    result = str;
  }
  return result;
};

/** 1,000 */
export const commaNum = num => {
  if (num == null) {
    return '';
  }
  const str = num.toString();
  return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
};

/** 콤마 지우기
 * toLocaleString 콤마 지우기
 */
export const Uncomma = str => {
  str = String(str);
  return str.replace(/[^\d]+/g, '');
};

// 공백(스페이스 바) 체크 
export const checkSpace = str => {
  if (str.search(/\s/) !== -1) {
    return true;
    // 스페이스가 있는 경우
  } else {
    return false;
    // 스페이스 없는 경우
  }
};

// "https://icaregc.s3.ap-northeast-2.amazonaws.com/CMS/work/work-image_20211008164324.jpg" -> 파일명 추출
export const findFileName = fullURL => {
  if (fullURL === null || fullURL === '') return false;
  const strArr = fullURL.split('/');
  // console.log('', strArr[strArr.length - 1]);
  return strArr[strArr.length - 1];
};

// 파일확장자 확인~
export const checkFileExt = filename => {
  if (filename === '' || filename === null) return;
  const _lastDot = filename.lastIndexOf('.');
  const _fileExt = filename.substring(_lastDot, filename.length).toLowerCase();
  return _fileExt;
};

// 이미지파일인지 확인~
export const checkImageFile = filename => {
  if (filename === '' || filename === null) return;

  // Image 파일인 경우 미리보기 UI 제공
  const _lastDot = filename.lastIndexOf('.');
  const _fileExt = filename.substring(_lastDot, filename.length).toLowerCase();
  // console.log(_fileExt);
  let imgPreview = false;
  if (_fileExt === '.png' || _fileExt === '.jpg' || _fileExt === '.jpeg' || _fileExt === '.gif') {
    imgPreview = true;
  }
  return imgPreview;
};

// 파일존재여부 확인
export const isFileLabel = fileName => {
  if (fileName === '' || fileName === null) {
    return 'N';
  } else {
    return 'Y'
  }
};

export const mainTypeLbl = type => {
  let result = '';
  switch (type) {
    case 'productAuth':
      result = '정품인증관련';
      break;
    case 'event':
      result = '사은품 문의';
      break;
    case 'product':
      result = '제품관련';
      break;
    case 'marketing':
      result = '마케팅자료 관련';
      break;
    case 'etc':
      result = '기타';
      break;
  }
  return result;
};

export const equipmentTypeLbl = type => {
  let result = '';
  switch (type) {
    case 'equipmentInquiry':
      result = '장비관련 문의';
      break;
    case 'equipmentAfterService':
      result = '장비 A/S 요청';
      break;
  }
  return result;
};

// 공통 메세지 처리
export const commonMessage = type => {
  let result = '';
  switch (type) {
    case 'E':
      result = '에러가 발생하였습니다';
      break;
    case 'S':
      result = '저장되었습니다';
      break;
    case 'U':
      result = '수정되었습니다';
      break;
    case 'D':
      result = '삭제되었습니다';
      break;
  }
  return result;
};

export const checkPermission = item => {
  if (item === null) return false;
  // const msg = "권한이 없습니다";
  if (item.dpGrade === "A") {
    if (item.userGrade > 1) {
      //this.$helper.showTostMessage("top", msg, "info");
      return false;
    }
  }
  if (item.dpGrade === "B") {
    if (item.userGrade > 2) {
      //this.$helper.showTostMessage("top", msg, "info");
      return false;
    }
  }
  return true;
};

export const changeDateToForUpdateDp = (regDate, updDate) => {
  const returnDate = updDate === '' || updDate === null
    ? moment(regDate).format('YYYY-MM-DD a hh:mm')
    : moment(updDate).format('YYYY-MM-DD a hh:mm')
  return returnDate;
}

// Date -> String 변환
export const changeDateToStringForDp = (date, includeTime) => {
  const format = includeTime ? 'YYYY-MM-DD a h:mm' : 'YYYY/MM/DD';
  return date === null || date === '' ? '' : moment(date).format(format);
}

// Date -> String 변환
export const changeGenderShortForDp = (gender) => {
  return gender === null || gender === '' ? '' : gender.substring(0, 1);
}

// Date -> String 변환
export const changeDateToString = (date, includeTime) => {
  const format = includeTime ? 'YYYY-MM-DD hh:mm:ss' : 'YYYY-MM-DD';
  return date === null || date === '' ? '' : moment(date).format(format);
}

// 요일처리
export const weekLbl = type => {
  let result = '';
  switch (type) {
    case 'Mon':
      result = '월';
      break;
    case 'Thu':
      result = '화';
      break;
    case 'Wen':
      result = '수';
      break;
    case 'Tue':
      result = '목';
      break;
    case 'Fri':
      result = '금';
      break;
    case 'Sat':
      result = '토';
      break;
    case 'Sun':
      result = '일';
      break;
  }
  return result;
};

// 회원등급 정의
export const memberGrade = totalPoint => {
  let memberGrade = '';
  let memberGradeHan = '';
  if (totalPoint < 100) {
    memberGrade = "bronze";
    memberGradeHan = "브론즈";
  } else if (totalPoint >= 100 && totalPoint <= 300) {
    memberGrade = "silver";
    memberGradeHan = "실버";
  } else if (totalPoint > 300) {
    memberGrade = "gold";
    memberGradeHan = "골드";
  }
  return {
    memberGrade: memberGrade,
    memberGradeHan: memberGradeHan,
  }
};

// 다음 등급까지
export const nextMemberGrade = (grade, point) => {
  let nextGrade = '';
  let nextGradeHan = '';
  let nextPoint = 0;
  if (grade === '브론즈') {
    nextGrade = "silver";
    nextGradeHan = "실버";
    nextPoint = 100 - point;
  } else if (grade === '실버') {
    nextGrade = "gold";
    nextGradeHan = "골드";
    nextPoint = 301 - point;
  }
  return {
    nextGrade: nextGrade,
    nextGradeHan: nextGradeHan,
    nextPoint: nextPoint,
  }
};

// 중복배열 처리
export const dupArrayCheck = (wantArray) => {
  const set = new Set(wantArray);
  return [...set];
};

export const randomNumber = (min, max) => {
  return Math.floor(Math.random() * (max + 1 - min) + min);
};

// 성별 표시 변환
export const genderLbl = gender => {
  if (gender === 'MALE') {
    return '남';
  } else {
    return '여'
  }
};

// 양력/음력 표시 변환
export const birthdayTypeLbl = gender => {
  if (gender === 'SOLAR') {
    return '양력';
  } else {
    return '음력'
  }
};

// 태어난 시간 변환
export const birthTimeLbl = type => {
  let result = '';
  switch (type) {
    default:
      result = '모름';
      break;
    case '23:30':
      result = '자';
      break;
    case '01:30':
      result = '축';
      break;
    case '03:30':
      result = '인';
      break;
    case '05:30':
      result = '묘';
      break;
    case '07:30':
      result = '진';
      break;
    case '09:30':
      result = '사';
      break;
    case '11:30':
      result = '오';
      break;
    case '13:30':
      result = '미';
      break;
    case '15:30':
      result = '신';
      break;
    case '17:30':
      result = '유';
      break;
    case '19:30':
      result = '술';
      break;
    case '21:30':
      result = '해';
      break;
  }
  return result;
};

/** gruop array : [{name:'사과',...},{name:'사과',...}]
   * 같은 value값으로 배열을 묶어준다.
   */
export const groupBy = (array, f) => {
  const groups = {};
  array.forEach(function (o) {
    const group = JSON.stringify(f(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return Object.keys(groups).map(function (group) {
    return groups[group];
  });
}

export const makeFileInfo = (img) => {
  return {
    filename: findFileName(img),
    fullURL: img,
    isImageFile: checkImageFile(findFileName(img)),
  }
}

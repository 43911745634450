<template>
  <div></div>
</template>
<script>
export default {
  mounted() {
    this.test();
  },
  methods: {
    test() {
      let arr = ["a", "b", "c", "d"];

      // index 1, index 2 삭제
      arr.splice(2, 1);

      console.log("", arr); // ['a', 'd']
    },
    dupArrayCheck(wantArray) {
      const set = new Set(wantArray);
      return [...set];
    },
  },
};
</script>
<style lang="">
</style>
<template>
  <div class="text-center ma-2">
    <v-btn @click="snackbar = true"> Open Snackbar </v-btn>
    <v-snackbar :timeout="500" v-model="snackbar">
      {{ text }}

      <template v-slot:actions>
        <v-btn color="pink" variant="text" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  data: () => ({
    snackbar: false,
    text: `Hello, I'm a snackbar`,
  }),
};
</script>
<style lang="">
</style>
<template>
  <div class="medical">
    <h4>의·약학적 정보 전달 및 제품 홍보 등 마케팅 안내 관련 동의</h4>
    <br />
    회사가 위와 같이 수집한 개인정보는 상기 보유 및 이용기간 동안(즉, 회원탈퇴 시까지) <b>우편(전자우편 포함) 발송, SMS 발송, 카카오채널 (구 카카오플러스친구) 등 SNS, 방문, 전화 등을 통한 제품설명회
    등 보건의료전문가를 대상으로 하는 마케팅, 시장조사 및 회사 제품의 안내를 포함한 의·약학적 정보 전달 및 취득 활동</b>의 수행 등에 이용될 수 있습니다. 귀하는 이에 동의하지 않으실 수 있으나, 동의하지 않는 경우
    향후 회사의 신제품의 안내, 의·약학적 정보 전달이 이루어지지 않을 수 있습니다. 
    </br><br>
    <b>개인정보 처리 위탁 관련 고지</b><br/></br>
    회사는 보다 나은 서비스를 제공해드리고자 아래와 같이 개인정보 처리업무를 위탁하고 있습니다. 수탁자는 위탁받은 업무 이외의 목적으로는 개인정보를 이용하지 않습니다.

    
    <br /><br />
    1) 수탁자 : 지피랩 주식회사(ZP Lab Co., Ltd)
    <br />
    2) 위탁하는 업무의 내역 : 이메일/SMS/카카오채널을 통한 정보 전송 등
  </div>
</template>

<script>
export default {};
</script>

<style></style>
<template>
  <div class="page my-page">
    <UserProfile
      pageName="mypage"
      :userData="userData"
      @clickSummitEmit="onClickSave"
      @clickCancel="clickCancel"
      @clickWithdrawal="clickWithdrawal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import UserProfile from "../home/signUp/UserProfile.vue";

const modelService = require("@/utils/dataModel.js");

export default {
  name: "MyPage",
  components: {
    UserProfile,
  },
  data() {
    return {
      userData: modelService.userModel(),
    };
  },
  mounted() {
    if (!this.isAuthorized) {
      // 이동 할 페이지 저장
      this.loginCheck(this.$route.fullPath);
      this.$router.push("/login").catch(() => {});
      return;
    }
    if (this.me === null) {
      this.getMe().then(() => {
        this.setMeInfo();
      });
    }
    this.setMeInfo();
  },
  methods: {
    setMeInfo() {
      setTimeout(() => {
        console.log("", this.me);
        this.userData = this.me;
        this.userData.password = "";
        this.userData.rePassword = "";
        if (this.me.signupPath === "kakao") {
          this.userData.password = "kakao_email_" + this.userData.loginId;
          this.userData.rePassword = "kakao_email_" + this.userData.loginId;
        }
      }, 500);
    },
    clickWithdrawal() {
      if (!confirm("회원탈퇴 하시겠습니까?")) return;
      // 탈퇴처리
      this.userData.queryMethod = "POST";
      this.userData.endpoint = "withdrawal";
      this.saveDataQuery(this.userData).then((res) => {
        if (res === undefined) return;
        this.$helper.showTostMessage(
          "top",
          "정상적으로 처리되었습니다",
          "success"
        );
        this.signout();
        window.location.href = "/";
        // this.$router.push("/login");
      });
    },
    onClickSave() {
      if (!confirm("수정 하시겠습니까?")) return;
      this.userData.isSetup = false;
      this.userData.endpoint = "meUpdate";
      this.saveDataQuery(this.userData).then((res) => {
        if (res === undefined) return;
        this.$helper.showTostMessage(
          "top",
          "정상적으로 처리되었습니다",
          "success"
        );
        this.signout();
        window.location.href = "/";
        // this.$router.push("/login");
      });
    },
    clickCancel() {
      this.$router.push("/brand");
    },
    ...mapActions(["loginCheck", "saveDataQuery", "signout", "getMe"]),
  },
  computed: {
    ...mapGetters(["isAuthorized"]),
    ...mapState(["me"]),
  },
};
</script>

<style lang="scss">
.my-page {
  background: linear-gradient(#ffffff, #ebe5e1);
  padding-bottom: 100px;
}
</style>
